import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import { downloadSubtitles } from 'utils/queries'
import { azure } from 'constants.js'
import DownloadVideoWithSubtitles from './DownloadVideoWithSubtitles'

export const DownloadMaterialsModal = ({
  show,
  handleClose,
  project,
  video,
}) => {
  const [ext, setExt] = useState('srt')
  const [loading, setLoading] = useState(false)

  const handleDownload = async () => {
    setLoading(true)

    await downloadSubtitles(
      ext,
      project.id,
      `${project.name
        .split(' ')
        .join('_')
        .split('.')
        .join('')}.${ext}`
    )

    setLoading(false)
  }

  return (
    <Modal size="md" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Pobieranie materiałów</Modal.Title>
      </Modal.Header>
      {project.transcription?.length > 0 && (
        <Modal.Body>
          <div className="form-group">
            <div className="row mb-6">
              <div className="col-lg-12">
                <h3 class="card-title font-weight-bolder">Napisy</h3>
                <div class="row">
                  <div className="col-lg-6">
                    <label for="langSubtitles">Wybierz format</label>
                    <select
                      onChange={e => setExt(e.target.value)}
                      value={ext}
                      id="subExt"
                      className="form-control"
                    >
                      <option value="srt">SRT</option>
                      <option value="vtt">VTT</option>
                      <option value="txt">
                        TXT (bez znaczników czasowych)
                      </option>
                    </select>
                    {!project.finished && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          Transkrypcja nie jest jeszcze gotowa
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <button
                      onClick={handleDownload}
                      className="btn show-notify btn-primary mt-8"
                    >
                      Pobierz
                      {loading && (
                        <span className="ml-3 mr-3 spinner spinner-white"></span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      )}
      {project.material && (
        <Modal.Body style={{ borderTop: '1px solid #EBEDF3' }}>
          <div className="row">
            <div className="col-lg-12">
              <h3 class="card-title font-weight-bolder">Wideo</h3>
              <div className="d-flex flex-row justify-content-between align-items-center pb-4">
                <span>Pobierz oryginalny materiał wideo</span>
                <a
                  href="#"
                  href={`${azure}${project.material}`}
                  target="_blank"
                  className={`btn btn-sm btn-success font-weight-bolder text-uppercase`}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexFlow: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    Pobierz
                    <i className="fas fa-download icon-sm ml-2"></i>
                  </div>
                </a>
              </div>

              <DownloadVideoWithSubtitles
                burningStatus={project.burningStatus}
                projectId={project._id}
                video={video}
                projectName={project.name}
              />
            </div>
          </div>
        </Modal.Body>
      )}
      <Modal.Footer>
        <button
          onClick={handleClose}
          className="btn show-notify btn-light mr-2"
        >
          Anuluj
        </button>
      </Modal.Footer>
    </Modal>
  )
}
