import { toast } from 'react-toastify'

export const notify = (type, message) => {
  switch (type) {
    case 'success':
      return toast.success(message, {
        style: {
          backgroundColor: 'var(--teal)',
          fontFamily: 'Poppins, Helvetica, "sans-serif"',
        },
      })
    case 'warning':
      return toast.warning(message, {
        style: {
          backgroundColor: 'var(--warning)',
          fontFamily: 'Poppins, Helvetica, "sans-serif"',
        },
      })
    case 'info':
      return toast.info(message, {
        style: {
          backgroundColor: 'var(--info)',
          fontFamily: 'Poppins, Helvetica, "sans-serif"',
        },
      })
    case 'error':
      return toast.error(message, {
        style: {
          backgroundColor: 'var(--danger)',
          fontFamily: 'Poppins, Helvetica, "sans-serif"',
        },
      })
  }
}
