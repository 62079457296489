import * as Yup from 'yup'

import {
  passRegExp,
  phoneRegExp,
  onlyLetterRegExp,
  urlRegExp,
} from '../constants'

const emailValidator = intl =>
  Yup.string()
    .email(
      intl.formatMessage({
        id: 'VALIDATION.EMAIL',
      })
    )
    .required(
      intl.formatMessage({
        id: 'VALIDATION.REQUIRED',
      })
    )

const requiredValidator = intl =>
  Yup.string().required(
    intl.formatMessage({
      id: 'VALIDATION.REQUIRED',
    })
  )

const onlyLettersValidator = (intl, min, max) =>
  Yup.string()
    .min(
      min,
      intl.formatMessage(
        {
          id: 'VALIDATION.MIN',
        },
        {
          min: min,
        }
      )
    )
    .max(
      max,
      intl.formatMessage(
        {
          id: 'VALIDATION.MAX',
        },
        {
          max: max,
        }
      )
    )
    .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED' }))
    .matches(
      onlyLetterRegExp,
      intl.formatMessage({ id: 'VALIDATION.ONLY_LETTERS' })
    )

const minMaxValidator = (intl, min, max) =>
  Yup.string()
    .min(
      min,
      intl.formatMessage(
        {
          id: 'VALIDATION.MIN',
        },
        {
          min: min,
        }
      )
    )
    .max(
      max,
      intl.formatMessage(
        {
          id: 'VALIDATION.MAX',
        },
        {
          max: max,
        }
      )
    )

const minMaxReqValidator = (intl, min, max) =>
  Yup.string()
    .min(
      min,
      intl.formatMessage(
        {
          id: 'VALIDATION.MIN',
        },
        {
          min: min,
        }
      )
    )
    .max(
      max,
      intl.formatMessage(
        {
          id: 'VALIDATION.MAX',
        },
        {
          max: max,
        }
      )
    )
    .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED' }))

const phoneValidator = intl =>
  Yup.string()
    .min(
      7,
      intl.formatMessage(
        {
          id: 'VALIDATION.MIN',
        },
        {
          min: 7,
        }
      )
    )
    .max(
      14,
      intl.formatMessage(
        {
          id: 'VALIDATION.MAX',
        },
        {
          max: 14,
        }
      )
    )
    .matches(phoneRegExp, intl.formatMessage({ id: 'VALIDATION.PHONE' }))
    .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED' }))

const passwordValidator = intl =>
  Yup.string()
    .min(
      8,
      intl.formatMessage(
        {
          id: 'VALIDATION.MIN',
        },
        {
          min: 8,
        }
      )
    )
    .max(
      50,
      intl.formatMessage(
        {
          id: 'VALIDATION.MAX',
        },
        {
          max: 50,
        }
      )
    )
    .matches(passRegExp, intl.formatMessage({ id: 'VALIDATION.PASSWORD' }))
    .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED' }))

const confirmPasswordValidator = intl =>
  Yup.string()
    .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED' }))
    .when('password', {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        intl.formatMessage({ id: 'VALIDATION.CONFIRM_PASSWORD' })
      ),
    })

const intigerValidator = intl =>
  Yup.number()
    .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED' }))
    .integer(intl.formatMessage({ id: 'VALIDATION.INTEGER' }))

const colorValidator = intl =>
  Yup.string()
    .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED' }))
    .length(7, intl.formatMessage({ id: 'VALIDATION.COLOR_LENGTH' }))

const radioValidator = (intl, fields) =>
  Yup.string()
    .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED' }))
    .oneOf(fields, intl.formatMessage({ id: 'VALIDATION.RADIO' }))

const dateValidator = intl =>
  Yup.date(intl.formatMessage({ id: 'VALIDATION.DATE' }))

const youtubeValidator = intl =>
  Yup.string()
    .matches(urlRegExp, intl.formatMessage({ id: 'VALIDATION.URL' }))
    .matches(/youtube/g, intl.formatMessage({ id: 'VALIDATION.YOUTUBE' }))

export {
  emailValidator,
  requiredValidator,
  onlyLettersValidator,
  minMaxValidator,
  minMaxReqValidator,
  phoneValidator,
  passwordValidator,
  confirmPasswordValidator,
  intigerValidator,
  colorValidator,
  radioValidator,
  dateValidator,
  youtubeValidator,
}
