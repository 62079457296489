import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

const SelectSubtitlesLangModal = ({ show, handleClose, handleSubmit }) => {
  const [lang, setLang] = useState('pl-PL')

  return (
    <Modal size="md" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Generowanie napisów</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <div className="col-lg-6">
            <label for="langSubtitles">Język napisów</label>
            <select
              onChange={e => {
                setLang(e.currentTarget.value)
              }}
              value={lang}
              id="langSubtitles"
              className="form-control"
            >
              <option value="pl-PL">Polski</option>
              <option value="en-US">Angielski</option>
              <option value="fr-FR">Francuski</option>
              <option value="de-DE">Niemiecki</option>
              <option value="es-ES">Hiszpański</option>
              <option value="cs-CZ">Czeski</option>
              <option value="ru-RU">Rosyjski</option>
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => handleSubmit(lang)}
          className={`btn show-notify btn-primary mr-2`}
        >
          Generuj
          {false && <span className="ml-3 mr-3 spinner spinner-white"></span>}
        </button>
        <button
          onClick={handleClose}
          className="btn show-notify btn-light mr-2"
        >
          Anuluj
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default SelectSubtitlesLangModal
