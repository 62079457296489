import React, { memo } from 'react'
import { WAVEFORMS_ENDPOINT } from 'constants.js'

const chunkSize = 1500

const mergeWaveforms = (id, duration, chunkSize) => {
  const waveforms = []
  const chunkInSeconds = chunkSize / 100
  let left = 0

  for (let i = 0; i < duration; i += chunkInSeconds) {
    waveforms.push(
      <img
        className="waveform-img"
        width={chunkSize}
        loading="lazy"
        src={`${WAVEFORMS_ENDPOINT}/${id}/${i}-${i + chunkInSeconds}.png`}
        style={{
          left: left,
        }}
      />
    )
    left += chunkSize
  }

  return waveforms
}

const Waveforms = memo(({ id, duration }) => {
  const waveformCuts = mergeWaveforms(id, duration, chunkSize)

  return <>{waveformCuts}</>
})

export default Waveforms
