import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { formatDate } from 'utils/helpers'
import { FormattedMessage, injectIntl } from 'react-intl'
// import NewTaskModal from './panel/tasks/NewTaskModal'
import AddVideoModal from './AddVideoModal'
import { IMAGES_ENDPOINT, api, VIDEOS_ENDPOINT, azure } from 'constants.js'
import { CustomizedDatePicker } from 'app/components'
// import StatusesSelect from './panel/selects/StatusesSelect'
import { useAuth } from 'contexts/authContext'
import { useHistory } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { transcribeVideo, updateProjectDeadline } from 'utils/mutations'
import { notify } from 'app/components/notify'
import SelectSubtitlesLangModal from './SelectSubtitlesLangModal'
import { DownloadMaterialsModal } from './DownloadMaterialsModal'

const ProjectCard = ({ data, formik, intl, project, settings }) => {
  const [editedDeadline, setEditedDeadline] = useState()
  const [uploading, setUploading] = useState(false)
  const [transcribing, setTranscribing] = useState(data.transcribing)

  const [modal, setModal] = useState('hidden') // hidden, materials, subtitles, video - typescript light hehe

  const history = useHistory()

  const auth = useAuth()
  const isClient = auth.state.user.role === 'client'
  const isCorrector = auth.state.user.role === 'corrector'

  const {
    createdAt,
    deadline,
    description,
    name,
    photo,
    progress,
    id,
    transcription,
    video,
    user,
    blockTranscription,
  } = data

  const queryClient = useQueryClient()

  const transcribeMutation = useMutation(
    ({ project, lang }) => transcribeVideo(project.id, lang),
    {
      onSuccess: d => {
        queryClient.invalidateQueries(project.id)
        setTranscribing(false)
        notify('success', `Transkrypcja projektu ${name} zakończyła się.`)
      },
      onError: err => {
        notify(
          'error',
          intl.formatMessage({
            id: err?.response?.data?.id || 'API.ERROR.GENERAL',
          })
        )
      },
    }
  )

  const deadlineMutation = useMutation(
    ({ id, deadline }) => updateProjectDeadline(id, deadline),
    {
      onSuccess: d => {
        queryClient.invalidateQueries(project)
        notify('success', `Deadline projektu został zaktualizowany`)
      },
    }
  )

  const videoURL = `${VIDEOS_ENDPOINT}${video}`
  const transcriptionExists = transcription?.length > 0
  const transcriptionCompleted = Number(progress) === 100

  const getVideoBtn = (videoMaterial, isVideoProcessing) => {
    if (videoMaterial) {
      const children = text => (
        <div
          style={{
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {' '}
          {text}
          <i className="fas fa-play icon-sm ml-2"></i>
        </div>
      )

      return transcriptionExists && !isClient ? (
        <Link
          to={`/editor/${project}`}
          className={`btn btn-sm btn-success ml-3 font-weight-bolder text-uppercase`}
        >
          {children('Transkrypcja')}
        </Link>
      ) : (
        <a
          href={videoURL}
          target="_blank"
          className={`btn btn-sm btn-success ml-3 font-weight-bolder text-uppercase`}
        >
          {children('Wideo')}
        </a>
      )
    }
    if (isVideoProcessing || history.location.state?.uploading || uploading)
      return (
        <button
          type="button"
          className={`btn btn-sm btn-success ml-3 font-weight-bolder text-uppercase custom-disabled`}
        >
          Wideo jest przetwarzane
          <span className="ml-3 mr-5 spinner spinner-white" />
        </button>
      )
    return (
      <button
        onClick={() => {
          handleVideoModalShow()
        }}
        type="button"
        className={`btn btn-sm btn-success ml-3 font-weight-bolder text-uppercase`}
      >
        Dodaj wideo
      </button>
    )
  }

  const handleVideoModalShow = () => {
    setModal('video')
  }

  const handleDateChange = date => {
    formik.setFieldValue('deadline', date.toISOString())
    deadlineMutation.mutate({ id: project, deadline: date })
  }

  const renderModal = useCallback(
    type => {
      switch (type) {
        case 'materials':
          return (
            <DownloadMaterialsModal
              project={data}
              show
              handleClose={() => {
                setModal('hidden')
              }}
              video={video}
            />
          )
        case 'subtitles':
          return (
            <SelectSubtitlesLangModal
              show
              handleClose={() => {
                setModal('hidden')
              }}
              handleSubmit={lang => {
                setTranscribing(true)
                transcribeMutation.mutate({ project: data, lang })
                setModal('hidden')
              }}
            />
          )
        case 'video':
          return (
            <AddVideoModal
              show
              projectId={project}
              setUploading={setUploading}
              handleClose={() => {
                setModal('hidden')
              }}
              handleShow={handleVideoModalShow}
              settings={settings}
            />
          )
        default:
          return null
      }
    },
    [data]
  )

  return (
    <>
      {renderModal(modal)}
      <div className="card card-custom gutter-b">
        <div className="card-body">
          <div className="d-flex">
            <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
              <div className="symbol symbol-50 symbol-lg-120">
                <img
                  alt="Project"
                  src={
                    photo
                      ? `${IMAGES_ENDPOINT}${photo}?${Date.now()}`
                      : '/media/project-logos/default.jpg'
                  }
                />
              </div>
            </div>

            <div className="flex-grow-1">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div className="mr-3">
                  <div className="d-flex align-items-center text-dark font-size-h5 font-weight-bold">
                    {name}
                    <i className="flaticon2-correct text-success icon-md ml-2"></i>
                  </div>
                  <div className="d-flex flex-wrap my-2">
                    <div className="text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                      <div style={{ visibility: 'hidden' }}>
                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                          <i
                            style={{ marginRight: '3px' }}
                            className="fas fa-user-tie"
                          ></i>
                        </span>
                        <span>adamus</span>
                        <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
                        <a
                          className="text-muted font-weight-bold text-hover-primary"
                          // href={`mailto:${creator.email}`}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                            <i
                              style={{ marginRight: '3px' }}
                              className="flaticon-email"
                            ></i>
                          </span>
                          adamus
                          {/* {creator.email} */}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-lg-0 my-1">
                  {!isClient && (
                    <>
                      {data.video && !transcriptionCompleted && (
                        <button
                          onClick={() => {
                            setModal('subtitles')
                          }}
                          type="button"
                          className={`btn btn-sm ${
                            blockTranscription ? 'btn-danger' : 'btn-info'
                          } font-weight-bolder text-uppercase ml-3 ${(data.transcribing ||
                            data?.transcription?.length > 0 ||
                            blockTranscription ||
                            transcribing) &&
                            'custom-disabled'}`}
                        >
                          <FormattedMessage
                            id={`PROJECT.DETAILS.${
                              blockTranscription
                                ? 'BLOCKED_SUBTITLES'
                                : data?.transcription?.length > 0
                                ? 'SUBTITLES'
                                : 'GET_SUBTITLES'
                            }`}
                          />
                          {(data.transcribing || transcribing) && (
                            <span className="ml-3 mr-5 spinner spinner-white"></span>
                          )}
                        </button>
                      )}
                    </>
                  )}

                  {data.material && !isCorrector && (
                    <button
                      onClick={() => {
                        setModal('materials')
                      }}
                      type="button"
                      className="btn btn-sm btn-info font-weight-bolder text-uppercase ml-3"
                    >
                      <FormattedMessage id={`PROJECT.DETAILS.DOWNLOAD`} />
                    </button>
                  )}

                  {getVideoBtn(data.video, data.processing)}
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div
                  style={{
                    maxWidth: '41%',
                    textAlign: 'justify',
                  }}
                  className="font-weight-bold text-dark-50 py-5 py-lg-2 mr-5"
                >
                  {description}
                </div>
                <div className="d-flex flex-wrap align-items-center py-2">
                  <div
                    className={`d-flex align-items-center ${!isClient &&
                      'mr-10'}`}
                  >
                    <div className={!isClient && 'mr-6'}>
                      <div className="font-weight-bold mb-2">
                        <FormattedMessage id="PROJECT.DETAILS.START.DATE" />
                      </div>
                      <span
                        style={{
                          cursor: 'default',
                        }}
                        className="btn btn-sm btn-text btn-light-primary text-uppercase font-weight-bold"
                      >
                        {formatDate(createdAt)}
                      </span>
                    </div>
                    {!isClient && (
                      <div className="">
                        <div className="font-weight-bold mb-2">
                          <FormattedMessage id="PROJECT.DETAILS.DEADLINE" />
                        </div>
                        <span
                          style={{ position: 'relative', cursor: 'default' }}
                          className={`btn btn-sm btn-text btn-light-${
                            editedDeadline ? 'success' : 'danger'
                          } text-uppercase font-weight-bold`}
                        >
                          {formatDate(formik.values.deadline)}
                          {isCorrector ? (
                            <label
                              style={{
                                position: 'absolute',
                                top: '-20%',
                                left: '95%',
                                width: '18px',
                                height: '18px',
                              }}
                            >
                              <CustomizedDatePicker
                                className="custom-datepicker-round"
                                name="deadline"
                                disabled={true}
                                selected={Date.parse(formik.values.deadline)}
                                onChange={date => handleDateChange(date)}
                              />
                            </label>
                          ) : (
                            <label
                              style={{
                                position: 'absolute',
                                top: '-20%',
                                left: '95%',
                                width: '18px',
                                height: '18px',
                              }}
                              className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                            >
                              <i className="fa fa-pen icon-sm text-muted custom-absolute-icon">
                                <CustomizedDatePicker
                                  className="custom-datepicker-round"
                                  name="deadline"
                                  selected={Date.parse(formik.values.deadline)}
                                  onChange={date => handleDateChange(date)}
                                />
                              </i>
                            </label>
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                  {!isClient && (
                    <div className="flex-grow-1 flex-shrink-0 w-100px w-xl-200px mt-4 mt-sm-0">
                      <span className="font-weight-bold">Progress</span>
                      <div className="progress progress-xs mt-2 mb-2">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{ width: `${progress}%` }}
                        ></div>
                      </div>
                      <span className="font-weight-bolder text-dark">
                        {isNaN(progress) ? '0.00' : progress}%
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="separator separator-solid my-7"></div>
        </div>
      </div>
    </>
  )
}

export default injectIntl(ProjectCard)
