import { get } from 'utils/api'
import {
  apiAddress,
  SETTINGS_ENDPOINT,
  GET_ME_ENDPOINT,
  PROJECTS_ENDPOINT,
  STATUSES_ENDPOINT,
  TASKS_ENDPOINT,
  USERS_ENDPOINT,
  REPORTS_ENDPOINT,
  DICTIONARIES_PATH,
  ENGLISH_DICT,
} from 'constants.js'
import axios from 'axios'

export const requestVideoWithSubtitles = async projectId => {
  return await axios.get(
    `${apiAddress}/testRoute/createVideoWithBurnedSubtitles/${projectId}`
  )
}

export const getVideoWithSubtitles = async projectId => {
  const { data } = await axios.get(
    `${apiAddress}/testRoute/downloadVideoWithBurnedSubtitles/${projectId}`,
    {
      responseType: 'blob',
    }
  )

  return data
}

//RAPORT

export const getCorrectorRaport = (startDate, endDate, correctorId, filename) =>
  get(
    REPORTS_ENDPOINT,
    {
      start: startDate.toISOString().slice(0, 10),
      end: endDate.toISOString().slice(0, 10),
      corrector: correctorId,
    },
    true
  ).then(res => {
    const fileUrl = URL.createObjectURL(res)
    const a = document.createElement('a')
    a.href = fileUrl
    a.download = filename
    a.click()
    //window.open(fileUrl)
  })

//SUBTITLES

export const downloadSubtitles = (ext, id, filename) =>
  get(`${PROJECTS_ENDPOINT}/${id}/generate-subtitles`, { ext }, true).then(
    res => {
      const fileUrl = URL.createObjectURL(res)
      const a = document.createElement('a')
      a.href = fileUrl
      a.download = filename
      a.click()
    }
  )

//USERS

export const getProfile = () => get(GET_ME_ENDPOINT).then(res => res.data.data)

export const getUser = id =>
  get(`${USERS_ENDPOINT}/${id}`).then(res => res.data.data)

export const getAvailableSeconds = () =>
  get(`${USERS_ENDPOINT}/available-seconds`)

//PROJECTS

export const getProject = id => get(`${PROJECTS_ENDPOINT}/${id}`)

export const getProjectStats = () => get(`${PROJECTS_ENDPOINT}/my-stats`)

//TASKS

export const getTask = id => get(`${TASKS_ENDPOINT}/${id}`)

export const getTaskStats = () => get(`${TASKS_ENDPOINT}/my-stats`)

//STATUSES

export const getStatuses = type =>
  get(STATUSES_ENDPOINT, { type }).then(res => res.data.data)

//SETTINGS

export const getSettings = () =>
  get(SETTINGS_ENDPOINT).then(res => res.data.data)

// DICTIONARIES

export const getDicAndAffEN = async () => {
  const aff = await axios.get(DICTIONARIES_PATH + ENGLISH_DICT.aff)
  const dic = await axios.get(DICTIONARIES_PATH + ENGLISH_DICT.dic)

  return { aff: aff.data, dic: dic.data }
}
