import React, { useEffect } from 'react'
import { Rnd } from 'react-rnd'
import { resizingConfig } from './index.js'
import FragmentContainer from 'app/pages/editor/FragmentContainer'

const TranscriptionFragment = ({
  text,
  width,
  x,
  index,
  number,
  bounds,
  handleTextTimeChange,
  onClick,
}) => {
  const { left } = bounds

  const handleDragStop = (event, data) => {
    handleTextTimeChange(
      {
        start: (data.x + left) / 100,
        end: (data.x + left + data.node.clientWidth + 2) / 100,
      },
      number
    )
  }

  const handleResizeStop = (event, dir, ref, delta, data) => {
    handleTextTimeChange(
      {
        start: (data.x + left) / 100,
        end: (data.x + left + ref.clientWidth + 2) / 100,
      },
      number
    )
  }

  return (
    <FragmentContainer bounds={bounds} index={index} number={number}>
      <div>
        <Rnd
          onClick={e => {
            if (typeof onClick === 'function') onClick(number)
          }}
          className="rnd-text-block"
          bounds="parent"
          dragAxis="x"
          position={{ x: x, y: 0 }}
          size={{ width: width }}
          enableResizing={resizingConfig}
          resizeHandleStyles={{
            left: {
              backgroundColor: '#95C952',
              width: '15px',
            },
            right: {
              backgroundColor: '#95C952',
              width: '15px',
            },
          }}
          resizeHandleClasses={{
            left: 'rnd-handle',
            right: 'rnd-handle',
          }}
          onDragStop={handleDragStop}
          onResizeStop={handleResizeStop}
        >
          <div className="rnd-text-block-inner no-user-select">
            <div style={{ marginRight: '10px', fontSize: '150%' }}>
              {number + 1}
            </div>
            <div
              style={{ fontSize: '12px', overflow: 'hidden', height: '100%' }}
            >
              {text}
            </div>
          </div>
        </Rnd>
      </div>
    </FragmentContainer>
  )
}

export default TranscriptionFragment
