import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { formatDate, mapPopulatedStatuses } from 'utils/helpers'

const Statuses = ({ formik, data, intl }) => {
  const statuses = mapPopulatedStatuses(data.statuses, intl)

  return (
    <div className="tab-content pt-5 statuses">
      <div className="tab-pane active">
        <div className="row">
          <div className="col-lg-9 col-xl-6 offset-xl-3">
            <h3 className="font-size-h6 mb-5">
              <FormattedMessage id="PROJECT.HISTORY.INNER" />:
            </h3>
          </div>
        </div>
        <div className="col-lg-9 col-xl-7 offset-xl-2">
          <div className="timeline timeline-5">
            <div className="timeline-items">
              {statuses.map(status => (
                <div className="timeline-item">
                  <div className="timeline-media bg-light-primary">
                    <span className="svg-icon-primary svg-icon-md">
                      <i className="flaticon2-arrow text-primary"></i>
                    </span>
                  </div>

                  <div className="timeline-desc timeline-desc-light-primary">
                    <span className="font-weight-bolder text-primary">
                      {formatDate(status.createdAt)}
                    </span>
                    <p className="font-weight-normal text-dark-50 pb-2">
                      {status.name}
                    </p>
                  </div>
                </div>
              ))}

              {/* <div className="timeline-item">
                <div className="timeline-media bg-light-primary">
                  <span className="svg-icon-primary svg-icon-md">
                    <i className="flaticon2-arrow text-primary"></i>
                  </span>
                </div>

                <div className="timeline-desc timeline-desc-light-primary">
                  <span className="font-weight-bolder text-primary">
                    21/01/2019 09:30
                  </span>
                  <p className="font-weight-normal text-dark-50 pb-2">
                    Projekt został utworzony.
                  </p>
                </div>
              </div>

              <div className="timeline-item">
                <div className="timeline-media bg-light-warning">
                  <span className="svg-icon-warning svg-icon-md">
                    <i className="fas fa-seedling text-warning"></i>
                  </span>
                </div>

                <div className="timeline-desc timeline-desc-light-warning">
                  <span className="font-weight-bolder text-warning">
                    23/05/2020 19:20
                  </span>
                  <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                    Status projektu został zmieniony na "w trakcie realizacji".
                  </p>
                </div>
              </div>

              <div className="timeline-item">
                <div className="timeline-media bg-light-success">
                  <span className="svg-icon-success svg-icon-md">
                    <i className="flaticon2-check-mark text-success"></i>
                  </span>
                </div>

                <div className="timeline-desc timeline-desc-light-success">
                  <span className="font-weight-bolder text-success">
                    25/06/2020 10:02
                  </span>
                  <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                    Projekt się zakończył.
                  </p>
                </div>
              </div>

              <div className="timeline-item">
                <div className="timeline-media bg-light-dark">
                  <span className="svg-icon-danger svg-icon-md">
                    <i className="flaticon2-hourglass"></i>
                  </span>
                </div>

                <div className="timeline-desc timeline-desc-light-dark">
                  <span className="font-weight-bolder text-dark">
                    30/09/2020 21:37
                  </span>
                  <p className="font-weight-normal text-dark-50 pt-1">
                    Projekt został archiwizowany.
                  </p>
                </div>
              </div>

              <div className="timeline-item">
                <div className="timeline-media bg-light-info">
                  <span className="svg-icon-info svg-icon-md">
                    <i className="far fa-flushed text-info"></i>
                  </span>
                </div>

                <div className="timeline-desc timeline-desc-light-info">
                  <span className="font-weight-bolder text-info">
                    12/10/1492 10:15
                  </span>
                  <p className="font-weight-normal text-dark-50 pt-1">
                    Projekt cofnął się w czasie.
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Statuses)
