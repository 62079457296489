import React from 'react'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '_metronic/_helpers'

export const AngleDown = props => {
  return (
    <SVG
      src={toAbsoluteUrl(
        '/media/svg/icons/Navigation/Stockholm-icons/Navigation/Angle-up.svg'
      )}
      {...props}
      style={{
        ...props.style,
        transform: 'rotate(180deg)',
      }}
    ></SVG>
  )
}

export const Marker = props => {
  return (
    <SVG
      style={{
        width: '16px',
        height: '16px',
      }}
      src={toAbsoluteUrl('/media/svg/editor/pisak.svg')}
      {...props}
    ></SVG>
  )
}

export const Centered = props => {
  return (
    <SVG
      style={{
        width: '20px',
        height: '20px',
      }}
      src={toAbsoluteUrl('/media/svg/editor/centered.svg')}
      {...props}
    ></SVG>
  )
}

export const ArrowUp = props => {
  return (
    <SVG
      style={{
        width: '20px',
        height: '20px',
      }}
      src={toAbsoluteUrl('/media/svg/editor/align-top.svg')}
      {...props}
    ></SVG>
  )
}

export const ArrowDown = props => {
  return (
    <SVG
      style={{
        width: '20px',
        height: '20px',
        transform: 'rotateX(180deg)',
      }}
      src={toAbsoluteUrl('/media/svg/editor/align-top.svg')}
      {...props}
    ></SVG>
  )
}

export const AlignLeft = props => {
  return <i className="fas fa-align-left" />
}

export const AlignCenter = props => {
  return <i className="fas fa-align-center" />
}

export const AlignRight = props => {
  return <i className="fas fa-align-right" />
}

export const A = props => {
  return <SVG src={toAbsoluteUrl('/media/svg/editor/A.svg')} {...props}></SVG>
}

export const I = props => {
  return <SVG src={toAbsoluteUrl('/media/svg/editor/I.svg')} {...props}></SVG>
}

export const B = props => {
  return <SVG src={toAbsoluteUrl('/media/svg/editor/B.svg')} {...props}></SVG>
}

export const Add = props => {
  return <SVG src={toAbsoluteUrl('/media/svg/editor/add.svg')} {...props}></SVG>
}

export const Join = props => {
  return (
    <SVG
      src={toAbsoluteUrl('/media/svg/editor/duplicate.svg')}
      {...props}
    ></SVG>
  )
}

export const Cancel = props => {
  return (
    <SVG src={toAbsoluteUrl('/media/svg/editor/close.svg')} {...props}></SVG>
  )
}

export const Accepted = props => {
  return (
    <SVG
      src={toAbsoluteUrl('/media/svg/editor/bookmark-checked.svg')}
      {...props}
    ></SVG>
  )
}

export const Play = props => {
  return (
    <SVG src={toAbsoluteUrl('/media/svg/editor/play2.svg')} {...props}></SVG>
  )
}

export const LeftArrow = props => {
  return (
    <SVG
      src={toAbsoluteUrl('/media/svg/editor/left-arrow.svg')}
      {...props}
    ></SVG>
  )
}

export const RightArrow = props => {
  return (
    <SVG
      src={toAbsoluteUrl('/media/svg/editor/right-arrow.svg')}
      {...props}
    ></SVG>
  )
}
