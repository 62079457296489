import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import pl from 'date-fns/locale/pl'
registerLocale('pl', pl)

const CustomizedDatePicker = props => {
  const renderDayContents = (day, date) => {
    return <span style={{ position: 'relative' }}>{date.getDate()}</span>
  }

  return (
    <DatePicker
      className="form-control form-control-solid form-control-lg"
      onChange={props.onChange}
      renderDayContents={renderDayContents}
      shouldCloseOnSelect={true}
      customInput={props.customInput}
      dateFormat="dd/MM/yyyy"
      locale="pl"
      {...props}
    />
  )
}

export default CustomizedDatePicker
