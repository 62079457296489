import React from 'react'

export const StatusNameFormatter = ({ status }) => {
  return (
    <>
      <span>
        <div className="font-weight-bold">{status}</div>
      </span>
    </>
  )
}

export const ColorFormatter = ({ color }) => {
  return (
    <>
      <span>
        <div
          className="marek-style"
          style={{
            backgroundColor: color,
            height: '24px',
            width: '52px',
          }}
        />
      </span>
    </>
  )
}

export const TypeFormatter = ({ type }) => {
  return (
    <>
      <span
        className={`label label-lg font-weight-bold label-inline ${
          type === 'project' ? 'label-light-success' : 'label-light-info'
        } marek-style`}
      >
        {type === 'project' ? 'Projekt' : 'Zadanie'}
      </span>
    </>
  )
}

export const DefaultFormatter = ({ active }) => {
  return (
    <>
      <span
        className={`label label-lg font-weight-bold label-inline ${
          active ? 'label-light-success' : 'label-light-info'
        } marek-style`}
      >
        {active ? 'Domyślny' : 'Własny'}
      </span>
    </>
  )
}
