import React, { useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Switch } from '@material-ui/core'

import UserNavigation from './UserNavigation'
import { patch } from '../../../utils/api'
import { useAuth } from '../../../contexts/authContext'
import { notify } from '../../components/notify'
import { useSubheader } from '_metronic/layout'
import { NOTIFICATIONS_ENDPOINT, UPDATE_USER } from 'constants.js'

const Notifications = ({ intl }) => {
  const {
    state: { user },
    dispatch,
  } = useAuth()

  const subheader = useSubheader()

  subheader.setTitle(intl.formatMessage({ id: 'PROFILE.TABS.CHANGE_PASS' }))

  const [loading, setLoading] = useState(false)

  const initialValues = { ...user.notifications }

  const notificationSchema = Yup.object().shape({
    newProject: Yup.boolean(),
    newTask: Yup.boolean(),
    subtitlesGenerated: Yup.boolean(),
    taskUpdated: Yup.boolean(),
    videoProcessed: Yup.boolean(),
  })

  const handleUpdate = async (values, { setStatus, setSubmitting }) => {
    setSubmitting(true)
    setLoading(true)

    try {
      const {
        data: { notifications },
      } = await patch(NOTIFICATIONS_ENDPOINT, {
        notifications: values,
      })
      dispatch({
        type: UPDATE_USER,
        payload: { ...user, notifications },
      })
      notify(
        'success',
        intl.formatMessage({
          id: 'PROFILE.NOTIFICATIONS.SUCCESS',
        })
      )
    } catch (err) {
      notify(
        'error',
        intl.formatMessage({
          id: err.id || 'PROFILE.PROFILE.FORM.ERROR',
        })
      )
    }

    setLoading(false)
    setSubmitting(false)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: notificationSchema,
    onSubmit: handleUpdate,
  })

  return (
    <>
      <div className="card card-custom">
        <UserNavigation />
        <div className="card-body">
          <div className="row">
            <div className="col-xl-2"></div>
            <form onSubmit={formik.handleSubmit} className="col-xl-7 my-2">
              <div className="row">
                <label className="col-3"></label>
                <div className="col-9">
                  <h6 className="text-dark font-weight-bold mb-10">
                    <FormattedMessage id="PROFILE.NOTIFICATIONS.TITLE" />
                  </h6>
                </div>
              </div>
              {Object.keys(initialValues).map(el => {
                if (
                  (user.role === 'corrector' &&
                    ['videoProcessed', 'subtitlesGenerated'].includes(el)) ||
                  (user.role === 'client' &&
                    ['projectDeadlineReminder'].includes(el))
                ) {
                  return null
                }

                return (
                  <div className="form-group row">
                    <label className="col-form-label col-3 text-lg-right text-left">
                      <FormattedMessage
                        id={`PROFILE.NOTIFICATIONS.${el.toUpperCase()}`}
                      />
                    </label>
                    <div className="col-9">
                      <Switch
                        checked={formik.values[el]}
                        onChange={checked => {
                          formik.setFieldValue(
                            el,
                            checked.currentTarget.checked
                          )
                        }}
                      />
                    </div>
                  </div>
                )
              })}

              <div className="form-group row">
                <div className="col-9 offset-3">
                  <button
                    type="submit"
                    className="btn show-notify btn-primary mr-2"
                    disabled={
                      formik.isSubmitting || !(formik.isValid && formik.dirty)
                    }
                  >
                    <span>
                      {' '}
                      <FormattedMessage id="PROFILE.CHANGE_PASS.FORM.SAVE" />
                    </span>
                    {loading && (
                      <span className="ml-3 mr-5 spinner spinner-white"></span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default injectIntl(Notifications)
