import React, { useState } from 'react'
import { Prompt } from 'react-router-dom'
import { ProjectCard, EditPanel } from './details'
import { useQueries, useMutation } from 'react-query'
import { useFormik } from 'formik'
import SaveModal from './details/SaveModal'
import { FormattedMessage, injectIntl } from 'react-intl'
import 'react-toastify/dist/ReactToastify.css'
import { getProject, getSettings } from 'utils/queries'
import { notify } from 'app/components/notify'
import PageLoader from 'app/components/PageLoader'
import * as Yup from 'yup'
import { updateProject } from 'utils/mutations'
import {
  dateValidator,
  minMaxReqValidator,
  minMaxValidator,
} from 'utils/validators'
import { useAuth } from 'contexts/authContext'
import StatusHistory from './details/StatusHistory'
import { useSubheader } from '_metronic/layout'

const ProjectDetails = ({
  match: {
    params: { id },
  },
  intl,
  history,
}) => {
  const [show, setShow] = useState(false)

  const subheader = useSubheader()

  const auth = useAuth()
  const user = auth.state.user

  const showModal = () => setShow(true)
  const hideModal = () => setShow(false)

  const [
    { isLoading, data, error, refetch },
    { isLoading: settingsLoading, data: settings },
  ] = useQueries([
    {
      queryKey: id,
      queryFn: () => getProject(id),
      refetchOnWindowFocus: false,
      refetchInterval: 10 * 1000,
      onSuccess: data => {
        const res = data?.data?.data

        if (user.role === 'client' && res?.client?._id !== user._id) {
          history.replace('/')
        }

        subheader.setTitle(res?.name)
      },
    },
    {
      queryKey: 'settings',
      queryFn: () => getSettings(),
    },
  ])

  const projectsMutation = useMutation(
    ({ id, values }) => updateProject(id, values),
    {
      onSuccess: () => {
        hideModal()
        refetch()
        notify('success', 'Zmiany zostały zapisane')
      },
    }
  )

  const handleSubmit = async values => {
    projectsMutation.mutate({ id, values })
  }

  let fetchedData = data?.data?.data

  const projectSchema = Yup.object().shape({
    name: minMaxReqValidator(intl, 2, 100),
    description: minMaxValidator(intl, 0, 250),
    date: dateValidator(intl),
  })

  const formik = useFormik({
    initialValues: {
      name: fetchedData?.name,
      description: fetchedData?.description,
      deadline: fetchedData?.deadline,
      client: fetchedData?.client,
      corrector: fetchedData?.corrector,
      hourlyRate: fetchedData?.hourlyRate,
    },
    enableReinitialize: true,
    validationSchema: projectSchema,
    onSubmit: handleSubmit,
  })

  if (error) {
    return <FormattedMessage id="PROJECT.DETAILS.NOT.FOUND" />
  }

  if (isLoading || settingsLoading) {
    return <PageLoader />
  }

  return (
    <>
      <Prompt
        when={formik.dirty}
        message={intl.formatMessage({ id: 'PROJECT.DETAILS.UNSAVED.CHANGES' })}
      />

      <form onSubmit={formik.handleSubmit}>
        <SaveModal
          show={show}
          hideModal={hideModal}
          handleSubmit={handleSubmit}
          isSubmitting={projectsMutation.isLoading}
          formik={formik}
        />

        <div className="d-flex flex-column-fluid">
          <div className="container" style={{ padding: '0' }}>
            <ProjectCard
              formik={formik}
              data={fetchedData}
              project={id}
              user={user}
              settings={settings}
            />
            <div className="row">
              <EditPanel
                project={id}
                formik={formik}
                data={fetchedData}
                showModal={showModal}
                user={user}
              />
              <div className="col-lg-4">
                <StatusHistory
                  statuses={fetchedData.statuses}
                  isClient={user.role === 'client'}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default injectIntl(ProjectDetails)
