import React from 'react'

import { LOGIN, LOGOUT, UPDATE_USER } from 'constants.js'
import { localJWT, localRefresh } from 'utils/jwt'
import { persistState, rehydrateState } from 'utils/persistor'

const AuthContext = React.createContext()

const authReducer = (state, action) => {
  switch (action.type) {
    case LOGIN: {
      localJWT.write(action.payload.jwt)
      localRefresh.write(action.payload.refresh)

      return {
        ...state,
        isAuth: true,
        user: action.payload.user,
      }
    }
    case LOGOUT: {
      localJWT.delete()
      localRefresh.delete()

      return {
        ...state,
        isAuth: false,
        user: null,
      }
    }
    case UPDATE_USER: {
      return {
        ...state,
        user: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

const initialState = {
  isAuth: false,
  user: null,
  persist: true,
}

const initialize = rehydrateState('auth', initialState)

const AuthProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(authReducer, initialize)
  const value = { state, dispatch }

  React.useEffect(() => {
    persistState('auth', state)
  }, [state])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within AuthProvider')
  }
  return context
}

export { AuthProvider, useAuth }
