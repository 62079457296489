import React, { Suspense } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout'
import { DashboardPage } from './pages/DashboardPage'
import { useAuth } from '../contexts/authContext'
import AddUser from './pages/general/AddUser'
import Projects from './pages/projects/Projects'
import AddProject from './pages/projects/AddProject'
import Users from './pages/general/Users'
import Profile from './pages/usersettings/Profile'
import ProfileSettings from './pages/usersettings/ProfileSettings'
import ChangePassword from './pages/usersettings/ChangePassword'
import Settings from './pages/platform/settings/Settings'
import ProjectDetails from './pages/projects/ProjectDetails'
import EditUser from './pages/edituser/EditUser'
import Notifications from './pages/usersettings/Notifications'

const routes = [
  {
    path: '/dashboard',
    component: DashboardPage,
    roles: ['client', 'individual-client', 'corrector', 'admin', 'super-admin'],
  },
  {
    path: '/users/list',
    component: () => <Users type="all" />,
    roles: ['super-admin'],
  },
  {
    path: '/users/add',
    component: AddUser,
    roles: ['admin', 'super-admin'],
  },
  {
    path: '/users/:id',
    component: EditUser,
    roles: ['admin', 'super-admin'],
  },
  {
    path: '/clients/list',
    component: () => <Users type="client" />,
    roles: ['admin', 'super-admin'],
  },
  {
    path: '/correctors/list',
    component: () => <Users type="corrector" />,
    roles: ['admin', 'super-admin'],
  },
  {
    path: '/projects/list',
    component: () => <Projects type="all" />,
    roles: ['admin', 'super-admin'],
  },
  {
    path: '/projects/add-project',
    component: AddProject,
    roles: ['client', 'individual-client', 'admin', 'super-admin'],
  },
  {
    path: '/projects/:id',
    component: ProjectDetails,
    roles: ['client', 'individual-client', 'corrector', 'admin', 'super-admin'],
  },
  {
    path: '/platform/settings',
    component: Settings,
    roles: ['super-admin'],
  },
  // {
  //   path: '/platform/statuses',
  //   component: Statuses,
  //   roles: ['super-admin'],
  // },
  {
    path: '/user-profile',
    component: Profile,
    roles: ['client', 'individual-client', 'corrector', 'admin', 'super-admin'],
  },
  {
    path: '/change-password',
    component: ChangePassword,
    roles: ['client', 'individual-client', 'corrector', 'admin', 'super-admin'],
  },
  {
    path: '/notifications',
    component: Notifications,
    roles: ['client', 'individual-client', 'corrector', 'admin', 'super-admin'],
  },
  {
    path: '/profile-settings',
    component: ProfileSettings,
    roles: ['client', 'individual-client', 'corrector', 'admin', 'super-admin'],
  },
  {
    path: '/my-projects',
    component: () => <Projects type="my" />,
    roles: ['client', 'individual-client', 'corrector', 'admin', 'super-admin'],
  },
  // {
  //   path: '/my-tasks',
  //   component: MyTasks,
  //   roles: ['corrector', 'admin', 'super-admin'],
  // },
  // {
  //   path: '/corrector-tasks/:id',
  //   component: CorrectorTasks,
  //   roles: ['super-admin', 'admin'],
  // },
  // {
  //   path: '/client-projects/:id',
  //   component: ClientProjects,
  //   roles: ['super-admin', 'admin'],
  // },
]

export default function BasePage() {
  const {
    state: {
      user: { role },
    },
  } = useAuth()

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }

        {routes
          .filter(el => el.roles.includes(role))
          .map(el => (
            <ContentRoute
              path={el.path}
              component={el.component}
              key={el.path}
            />
          ))}

        <Redirect to="error/error-v3" />
      </Switch>
    </Suspense>
  )
}
