import React, { useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useMutation, useQueryClient } from 'react-query'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { notify } from 'app/components/notify'
import { updateSettings } from 'utils/mutations'
import { emailValidator, intigerValidator } from 'utils/validators'

const SettingsForm = ({ initialValues, intl }) => {
  const [loading, setLoading] = useState(false)

  const queryClient = useQueryClient()

  const mutation = useMutation(updateSettings, {
    onSuccess: data => {
      queryClient.invalidateQueries('settings')
    },
  })

  const handleSubmit = async (values, { setStatus, setSubmitting }) => {
    setLoading(true)

    try {
      await mutation.mutateAsync(values)
      notify(
        'success',
        intl.formatMessage({
          id: 'SETTINGS.FORM.SUCCESS',
        })
      )
      formik.resetForm()
    } catch (err) {
      notify(
        'error',
        intl.formatMessage({
          id: err.id || 'SETTINGS.FORM.ERROR',
        })
      )
    }

    setLoading(false)
  }

  const formValidation = Yup.object().shape({
    charactersPerLine: intigerValidator(intl),
    charactersPerSecond: intigerValidator(intl),
    maxMaterialSize: intigerValidator(intl),
    maxLinesCount: intigerValidator(intl),
    secondsBetweenFragments: intigerValidator(intl),
    contactEmail: emailValidator(intl),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: formValidation,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  })

  return (
    <form onSubmit={formik.handleSubmit} className="form">
      {/*alertMessage &&
        <div className={`alert alert-${alertType} m-9`} role="alert">
          {alertMessage}
      </div>*/}

      <div className="card-body">
        <div className="form-group row">
          <div className="col-lg-4">
            <label>
              <FormattedMessage id="SETTINGS.DICTIONARY.FIELD.CPL" />
            </label>
            <input
              className={`form-control ${
                formik.touched.charactersPerLine &&
                formik.errors.charactersPerLine
                  ? 'is-invalid invalid-input'
                  : ''
              } form-control-lg form-control-solid`}
              id="charactersPerLine"
              type="number"
              {...formik.getFieldProps('charactersPerLine')}
            />

            {formik.touched.charactersPerLine &&
            formik.errors.charactersPerLine ? (
              <div className="invalid-input-label">
                {formik.errors.charactersPerLine}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="col-lg-4">
            <label>
              <FormattedMessage id="SETTINGS.DICTIONARY.FIELD.CPS" />
            </label>
            <input
              className={`form-control ${
                formik.touched.charactersPerSecond &&
                formik.errors.charactersPerSecond
                  ? 'is-invalid invalid-input'
                  : ''
              } form-control-lg form-control-solid`}
              id="charactersPerSecond"
              type="number"
              {...formik.getFieldProps('charactersPerSecond')}
            />

            {formik.touched.charactersPerSecond &&
            formik.errors.charactersPerSecond ? (
              <div className="invalid-input-label">
                {formik.errors.charactersPerSecond}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="col-lg-4">
            <label>
              <FormattedMessage id="SETTINGS.DICTIONARY.FIELD.MAX_MATERIAL" />
            </label>
            <input
              className={`form-control ${
                formik.touched.maxMaterialSize && formik.errors.maxMaterialSize
                  ? 'is-invalid invalid-input'
                  : ''
              } form-control-lg form-control-solid`}
              id="maxMaterialSize"
              type="number"
              {...formik.getFieldProps('maxMaterialSize')}
            />

            {formik.touched.maxMaterialSize && formik.errors.maxMaterialSize ? (
              <div className="invalid-input-label">
                {formik.errors.maxMaterialSize}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-4">
            <label>
              <FormattedMessage id="SETTINGS.DICTIONARY.FIELD.MAX_LINES" />
            </label>
            <input
              className={`form-control ${
                formik.touched.maxLinesCount && formik.errors.maxLinesCount
                  ? 'is-invalid invalid-input'
                  : ''
              } form-control-lg form-control-solid`}
              id="maxLinesCount"
              type="number"
              {...formik.getFieldProps('maxLinesCount')}
            />

            {formik.touched.maxLinesCount && formik.errors.maxLinesCount ? (
              <div className="invalid-input-label">
                {formik.errors.maxLinesCount}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="col-lg-4">
            <label>
              <FormattedMessage id="SETTINGS.DICTIONARY.FIELD.SECONDS_BETWEEN" />
            </label>
            <input
              className={`form-control ${
                formik.touched.secondsBetweenFragments &&
                formik.errors.secondsBetweenFragments
                  ? 'is-invalid invalid-input'
                  : ''
              } form-control-lg form-control-solid`}
              id="secondsBetweenFragments"
              type="number"
              {...formik.getFieldProps('secondsBetweenFragments')}
            />

            {formik.touched.secondsBetweenFragments &&
            formik.errors.secondsBetweenFragments ? (
              <div className="invalid-input-label">
                {formik.errors.secondsBetweenFragments}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="col-lg-4">
            <label>
              <FormattedMessage id="SETTINGS.DICTIONARY.FIELD.CONTACT_EMAIL" />
            </label>
            <input
              className={`form-control ${
                formik.touched.contactEmail && formik.errors.contactEmail
                  ? 'is-invalid invalid-input'
                  : ''
              } form-control-lg form-control-solid`}
              id="contactEmail"
              type="text"
              {...formik.getFieldProps('contactEmail')}
            />

            {formik.touched.contactEmail && formik.errors.contactEmail ? (
              <div className="invalid-input-label">
                {formik.errors.contactEmail}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className="card-footer">
        <div className="row">
          <div className="col-lg-12">
            <button
              type="submit"
              className="btn show-notify btn-primary mr-2"
              disabled={
                formik.isSubmitting || !(formik.isValid && formik.dirty)
              }
            >
              <span>
                {' '}
                <FormattedMessage id="SETTINGS.FORM.SAVE" />
              </span>
              {loading && (
                <span className="ml-3 mr-5 spinner spinner-white"></span>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default injectIntl(SettingsForm)
