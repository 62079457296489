import React, { useState, useEffect, useRef } from 'react'

const setCharAt = (str, index, chr) => {
  if (index > str.length - 1) return str
  return str.substring(0, index) + chr + str.substring(index + 1)
}

const Textarea = ({ value, onKeyDown, onChange, onClick }) => {
  const [input, setInput] = useState(value)
  const inputRange = useRef({
    start: 0,
    end: 0,
  })
  const shouldUsePreserve = useRef(false)
  const ref = useRef(null)

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(input)
    }, 250)

    return () => clearTimeout(timeout)
  }, [input])

  useEffect(() => {
    setInput(value)
  }, [value])

  useEffect(() => {
    if (shouldUsePreserve.current) {
      const { start, end } = inputRange.current
      ref.current.setSelectionRange(start, end)
      shouldUsePreserve.current = false
    }
  }, [input])

  const preserveCursorPosition = (start, end) => {
    inputRange.current = {
      start,
      end,
    }
  }

  const handleChange = e => {
    /* Replacing text causes cursor to jump at the end of
       textarea so we need to preserve current position and
       update it within useEffect input cb */
    preserveCursorPosition(e.target.selectionStart, e.target.selectionEnd)

    const content = e.target.value
    const splitted = content.split('')

    const wasAdded = regex =>
      (content.match(regex) || []).length > (input.match(regex) || []).length &&
      content[e.target.selectionStart + 1]

    /* .  ?  !   */

    if (wasAdded(/[!.?]/g)) {
      for (let i = e.target.selectionStart; i < splitted.length; i++) {
        if (splitted[i] !== ' ' && splitted[i] !== '\n') {
          splitted[i] = splitted[i].toUpperCase()
          break
        }
      }

      shouldUsePreserve.current = true
    }

    setInput(splitted.join(''))
  }

  return (
    <textarea
      ref={ref}
      onClick={onClick}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      value={input}
    />
  )
}

export default Textarea
