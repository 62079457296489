import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import CustomizedTable from 'app/modules/Table/CustomizedTable'
import DeleteTaskModal from './DeleteTaskModal'
import { useMutation, useQueryClient } from 'react-query'
import { columns, options } from 'app/modules/Table/configs/tasksConfig'
import { PROJECTS_ENDPOINT, TASKS_ENDPOINT } from 'constants.js'
import { deleteTask } from 'utils/mutations'
import { notify } from 'app/components/notify'

const Tasks = ({ project, intl }) => {
  const [modal, setModal] = useState({ show: false, id: '', projectId: '' })

  const queryClient = useQueryClient()

  const taskMutation = useMutation(
    ({ projectId, taskId }) => {
      return deleteTask(projectId, taskId)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tasks-table')
        notify('success', 'Zadanie zostało usunięte.')
      },
      onError: () => {
        notify('error', 'Wystąpił błąd podczas próby usunięcia zadania.')
      },
    }
  )

  return (
    <>
      <DeleteTaskModal
        show={modal.show}
        onConfirm={() => {
          taskMutation.mutate({ projectId: modal.projectId, taskId: modal.id })
          setModal({ ...modal, show: false })
        }}
        onClose={() => {
          setModal(false)
        }}
      />
      <div className="row">
        <div className="col-lg-12">
          <CustomizedTable
            queryIdentificator="tasks-table"
            columns={columns}
            options={options}
            keyField="name"
            endpoint={`${PROJECTS_ENDPOINT}/${project}${TASKS_ENDPOINT}`}
            {...options}
          />
        </div>
      </div>
    </>
  )
}

export default injectIntl(Tasks)
