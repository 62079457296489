/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'
import { useAuth } from 'contexts/authContext'
import { AvailableSeconds } from 'app/components/AvailableSeconds'

export function AsideMenuList({ layoutProps }) {
  const {
    state: {
      user: { role },
    },
  } = useAuth()

  const location = useLocation()
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : ''
  }

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* Components */}
        {/* begin::section */}
        {/* end:: section */}
        {/* Material-UI */}
        {/* Applications */}
        {(role === 'corrector' || role.includes('client')) && (
          <>
            <li className="menu-section ">
              <h4 className="menu-text">Menu</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>

            <li
              className={`menu-item ${getMenuItemActive('/dashboard')}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/dashboard">
                <i className="menu-bullet menu-bullet-line">
                  <span></span>
                </i>
                <span className="menu-text">Dashboard</span>
                <i className="menu-arrow" />
              </NavLink>
            </li>

            <li
              className={`menu-item ${getMenuItemActive('/my-projects')}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/my-projects">
                <i className="menu-bullet menu-bullet-line">
                  <span></span>
                </i>
                <span className="menu-text">Moje Projekty</span>
                <i className="menu-arrow" />
              </NavLink>
            </li>

            {role.includes('client') && (
              <li
                className={`menu-item ${getMenuItemActive(
                  '/projects/add-project'
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/projects/add-project">
                  <i className="menu-bullet menu-bullet-line">
                    <span></span>
                  </i>
                  <span className="menu-text">Dodaj Projekt</span>
                  <i className="menu-arrow" />
                </NavLink>
              </li>
            )}

            <li
              className={`menu-item ${getMenuItemActive('/user-profile')}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/user-profile">
                <i className="menu-bullet menu-bullet-line">
                  <span></span>
                </i>
                <span className="menu-text">Ustawienia</span>
                <i className="menu-arrow" />
              </NavLink>
            </li>
          </>
        )}

        {role.includes('admin') && (
          <>
            {/* New section */}
            <li className="menu-section ">
              <h4 className="menu-text">Użytkownicy</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>

            {role === 'super-admin' && (
              <li
                className={`menu-item ${getMenuItemActive('/users/list')}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/users/list">
                  <i className="menu-bullet menu-bullet-line">
                    <span></span>
                  </i>
                  <span className="menu-text">Pokaż</span>
                  <i className="menu-arrow" />
                </NavLink>
              </li>
            )}

            <li
              className={`menu-item ${getMenuItemActive('/users/add')}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/users/add">
                <i className="menu-bullet menu-bullet-line">
                  <span></span>
                </i>
                <span className="menu-text">Dodaj</span>
                <i className="menu-arrow" />
              </NavLink>
            </li>
            {/* End of new section */}

            {/* New section */}
            <li className="menu-section ">
              <h4 className="menu-text">Klienci</h4>
              <i className="menu-icon flaticon-more-v2"></i>
              <i className="menu-arrow" />
            </li>

            <li
              className={`menu-item ${getMenuItemActive('/clients/list')}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/clients/list">
                <i className="menu-bullet menu-bullet-line">
                  <span></span>
                </i>
                <span className="menu-text">Pokaż</span>
                <i className="menu-arrow" />
              </NavLink>
            </li>

            {/* End of new section */}

            {/* New Section */}
            <li className="menu-section ">
              <h4 className="menu-text">Projekty</h4>
            </li>

            <li
              className={`menu-item ${getMenuItemActive('/projects/list')}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/projects/list">
                <i className="menu-bullet menu-bullet-line">
                  <span></span>
                </i>
                <span className="menu-text">Pokaż</span>
                <i className="menu-arrow" />
              </NavLink>
            </li>

            <li
              className={`menu-item ${getMenuItemActive(
                '/projects/add-project'
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/projects/add-project">
                <i className="menu-bullet menu-bullet-line">
                  <span></span>
                </i>
                <span className="menu-text">Dodaj</span>
                <i className="menu-arrow" />
              </NavLink>
            </li>
            {/* End of new section */}

            {/* New section */}
            <li className="menu-section ">
              <h4 className="menu-text">Korektorzy</h4>
              <i className="menu-icon flaticon-more-v2"></i>
              <i className="menu-arrow" />
            </li>

            <li
              className={`menu-item ${getMenuItemActive('/correctors')}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/correctors/list">
                <i className="menu-bullet menu-bullet-line">
                  <span></span>
                </i>
                <span className="menu-text">Pokaż</span>
                <i className="menu-arrow" />
              </NavLink>
            </li>

            {/* End of new section */}
          </>
        )}
        {role === 'super-admin' && (
          <>
            {/* New section */}
            <li className="menu-section ">
              <h4 className="menu-text">Platforma</h4>
              <i className="menu-icon flaticon-more-v2"></i>
              <i className="menu-arrow" />
            </li>

            <li
              className={`menu-item ${getMenuItemActive('/platform/settings')}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/platform/settings">
                <i className="menu-bullet menu-bullet-line">
                  <span></span>
                </i>
                <span className="menu-text">Ustawienia</span>
                <i className="menu-arrow" />
              </NavLink>
            </li>

            {/* <li
              className={`menu-item ${getMenuItemActive('/statuses')}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/platform/statuses">
                <i className="menu-bullet menu-bullet-line">
                  <span></span>
                </i>
                <span className="menu-text">Statusy</span>
                <i className="menu-arrow" />
              </NavLink>
            </li> */}
          </>
        )}
      </ul>

      {/* end::Menu Nav */}
    </>
  )
}
