import React, { useState } from 'react'
import SVG from 'react-inlinesvg'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers'
import TaskModal from '../../../../pages/projects/details/panel/tasks/TaskModal'
import { OverlayTrigger, Tooltip, Modal } from 'react-bootstrap'
import { notify } from 'app/components/notify'
import { useMutation, useQueryClient } from 'react-query'
import { deleteProjectTask } from 'utils/mutations'

const ActionsFormatter = ({ row }) => {
  const [data, setData] = useState({})
  const [preview, setPreview] = useState(false)
  const refRow = row
  const [modal, setModal] = useState({ show: false, id: '', loading: false })

  const queryClient = useQueryClient()

  const deleteMutation = useMutation(() => deleteProjectTask(row.project._id), {
    onSuccess: data => {
      queryClient.invalidateQueries(row.project._id)
      queryClient.invalidateQueries('tasks-table')
    },
  })

  const handlePreview = () => {
    setPreview(true)
    setData(refRow)
  }

  const handleClosePreview = () => {
    setPreview(false)
    setData({})
  }

  const handleDelete = () => {
    setModal(m => ({
      ...m,
      show: true,
      id: row._id,
    }))
  }

  return (
    <>
      <TaskModal show={preview} handleClose={handleClosePreview} data={data} />
      <Modal size="md" show={modal.show}>
        <Modal.Header closeButton>
          <Modal.Title>Usuń zadanie</Modal.Title>
        </Modal.Header>
        <Modal.Body>Czy aby na pewno chcesz usunąć to zadanie?</Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            disabled={modal.loading}
            className="btn show-notify btn-danger mr-2"
            onClick={async () => {
              setModal({ ...modal, loading: true })
              try {
                await deleteMutation.mutateAsync()
              } catch (err) {
                notify('error', 'Coś poszlo nie tak')
              }
              setModal({ ...modal, show: false, loading: false })
            }}
          >
            Usuń
            {modal.loading && (
              <span className="ml-3 mr-5 spinner spinner-white"></span>
            )}
          </button>
          <button
            disabled={modal.loading}
            type="button"
            className="btn btn-secondary font-weight-bold mr-2"
            onClick={() => setModal(m => ({ ...m, show: false }))}
          >
            Anuluj
          </button>
        </Modal.Footer>
      </Modal>
      <div style={{ textAlign: 'right' }}>
        <OverlayTrigger overlay={<Tooltip>Pokaż</Tooltip>}>
          <Link
            to={`/editor/${row.project._id}?task=${row._id}`}
            className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
          >
            <span className="svg-icon svg-icon-sm">
              <i className="flaticon2-search-1 icon-size-13"></i>
            </span>
          </Link>
        </OverlayTrigger>

        <OverlayTrigger overlay={<Tooltip>Edytuj</Tooltip>}>
          <div
            onClick={handlePreview}
            className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
          >
            <span className="svg-icon svg-icon-sm">
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Edit.svg')} />
            </span>
          </div>
        </OverlayTrigger>

        <OverlayTrigger overlay={<Tooltip>Usuń</Tooltip>}>
          <div
            onClick={handleDelete}
            className="btn btn-sm btn-default btn-text-primary btn-hover-danger btn-icon mr-2"
          >
            <span className="svg-icon svg-icon-sm">
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
            </span>
          </div>
        </OverlayTrigger>
      </div>
    </>
  )
}

export default ActionsFormatter
