import React from 'react'
import { withRouter } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'

const ProjectHeader = withRouter(({ formik, showModal, history, intl }) => {
  return (
    <div className="subheader py-2 py-lg-4 subheader-solid">
      {/* <FormattedMessage id="PROJECTS.EDIT.DETAILS" />*/}
      <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <div className="d-flex align-items-center flex-wrap mr-2">
          <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
            <FormattedMessage id="PROJECT.DETAILS" />
            {/* <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON"/> */}
          </h5>
          <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
          <div className="d-flex align-items-center">
            <span className="text-dark-50 font-weight-bold">
              <FormattedMessage id="PROJECT.DETAILS.EDITING" />
            </span>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <button
            type="button"
            onClick={() => {
              history.push('/projects/list')
            }}
            className="btn btn-default font-weight-bold"
          >
            <FormattedMessage id="PROJECT.DETAILS.BACK" />
          </button>
          <button
            type="button"
            disabled={!formik.dirty}
            onClick={showModal}
            className="btn btn-primary font-weight-bold"
          >
            <FormattedMessage id="PROJECT.DETAILS.SAVE" />
          </button>
        </div>
      </div>
    </div>
  )
})

export default injectIntl(ProjectHeader)
