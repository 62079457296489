import React from 'react'

import { Link, useHistory } from 'react-router-dom'

import { toAbsoluteUrl } from '_metronic/_helpers/AssetsHelpers'

import { IMAGES_ENDPOINT } from 'constants.js'
import { ProfileModal } from 'app/components/ProfileModal'
import ConditionalLink from 'app/components/ConditionalLink'

export const ProfileFormatter = ({ user, type, id }) => {
  const [modal, setModal] = React.useState(false)
  const history = useHistory()

  if (!user) {
    return '-'
  }

  const { firstName, lastName, photo } = user

  return (
    <>
      <ProfileModal user={user} modal={modal} setModal={setModal} />

      <ConditionalLink
        to={`/projects/list?corrector=${id}`}
        condition={type === 'corrector'}
      >
        <div className="d-flex align-items-center field-formatter-user">
          <div className="symbol symbol-40 symbol-sm flex-shrink-0">
            <img
              src={
                photo
                  ? `${IMAGES_ENDPOINT}${photo}?${Date.now()}`
                  : toAbsoluteUrl('/media/client-logos/default.jpeg')
              }
              alt="user"
              width="40"
              height="40"
            ></img>
          </div>
          <div className="ml-4 d-flex flex-column justify-content-center">
            <div
              className="text-dark-75 font-weight-bolder font-size-md mb-0 text-hover-primary cursor-pointer"
              onClick={() => {
                if (type !== 'corrector') {
                  setModal(true)
                }
              }}
            >
              {firstName} {lastName}
            </div>
            {!user.active && (
              <a
                className="text-muted font-weight-bold text-hover-primary"
                style={{
                  fontSize: '90%',
                }}
                href={`mailto:${user.email}`}
              >
                {user.email}
              </a>
            )}
          </div>
        </div>
      </ConditionalLink>
    </>
  )
}
