import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useQueries, useMutation } from 'react-query'
import SVG from 'react-inlinesvg'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { isEqual } from 'lodash'
import { toAbsoluteUrl } from '_metronic/_helpers'
import { VideoPlayer, BottomEditor } from 'app/pages/editor'
import { getProject, getSettings } from 'utils/queries'
import {
  updateProjectBookmark,
  updateProjectFormatting,
  updateProjectStatus,
  updateProjectTranscription,
} from 'utils/mutations'
import { notify } from 'app/components/notify'
import { useAuth } from 'contexts/authContext'
import FormatActions from './FormatActions.js'
import {
  PIXEL_SECOND_RATIO,
  parseFormatting,
  parseFormattingForBackend,
} from './index'
import PageLoader from 'app/components/PageLoader'
import ReplaceWordsModal from './ReplaceWordsModal'
import TextareaContainer from './TextareaContainer.js'
import useInterval from '../../../utils/useInterval'
import { ChangeStatusModal } from './ChangeStatusModal.js'
import { FlagProgress } from './FlagProgress.js'
import { DownloadMaterialsModal } from 'app/pages/projects/details/DownloadMaterialsModal'
import { useTranscription } from './helpers/useTranscription.js'

const DEFAULT_VISIBLE_BLOCKS = 30
const OFFSET_ADJUSTMENT = 15

const Editor = ({
  match: {
    params: { id },
  },
  history,
}) => {
  const [transcription, setTranscription] = useState([]) // ? visible transcription blocks
  const [offset, setOffset] = useState(0) // ? something
  const [loading, setLoading] = useState(false) // * saving transcription flag
  const [playing, setPlaying] = useState(false) // * video playing flag
  const [played, setPlayed] = useState(0)
  const [duration, setDuration] = useState(1000)
  const [btmPointerX, setBtmPointerX] = useState(0)
  const [visibleBlocks, setVisibleBlocks] = useState(DEFAULT_VISIBLE_BLOCKS)
  const [showReplaceModal, setShowReplaceModal] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0) // Index of a transcription block that is currently active on a video
  const [bookmark, setBookmark] = useState()

  const [dlModal, setDlModal] = useState(false)
  const [modal, setModal] = useState({ type: 'hidden', loading: false })

  const [formatOptions, setFormatOptions] = useState({
    fontFamily: 'Arial',
    fontSize: '20px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'center',
    color: 'rgb(230,230,230)',
    bottom: 0,
    centered: true,
    textSelection: {
      type: 'wrap',
      background: 'black',
    },
  })

  // useTraceUpdate({
  //   transcription,
  //   globalTranscription,
  //   offset,
  //   loading,
  //   playing,
  //   played,
  //   duration,
  //   btmPointerX,
  //   visibleBlocks,
  //   showReplaceModal,
  //   currentIndex,
  //   bookmark,
  //   dlModal,
  //   modal,
  //   formatOptions,
  // })

  const {
    transcription: globalTranscription,
    setTranscriptionFromApi,
    handleBlockAdd,
    handleBlockDelete,
    handleBlockJoin,
    handleBlockSplit,
    handleFindAndReplace,
    handleTextChange,
    handleTextTimeChange,
    handleUndo,
    handleRedo,
  } = useTranscription()

  const player = useRef(null)
  const scrollContainer = useRef(null)
  const shouldSeekRef = useRef(false)

  const {
    state: {
      user: { role },
    },
  } = useAuth()

  if (role === 'client') {
    history.replace('/')
  }

  useEffect(() => {
    setBtmPointerX(played * duration * 100)
  }, [duration, played])

  useEffect(() => {
    adjustBottomScroll(played)

    if (shouldSeekRef.current && player.current)
      player.current.seekTo(played, 'seconds')
  }, [played])

  useEffect(() => {
    const newVisibleTranscription = [...globalTranscription].slice(
      offset,
      offset + visibleBlocks
    )

    setTranscription(newVisibleTranscription)
  }, [globalTranscription, offset, visibleBlocks])

  useEffect(() => {
    const transcriptionLoaded =
      transcription.length !== 0 && scrollContainer.current

    if (transcriptionLoaded) checkOffsetLimitsOverflow()
  }, [transcription])

  useInterval(() => {
    if (globalTranscription.length !== 0) handleSaveTranscription(true)
  }, 15000)

  const changeFormattingByKey = (key, value) => {
    setFormatOptions(prevFormatting => ({
      ...prevFormatting,
      [key]: value,
    }))
  }

  const handleBottomWheel = useCallback(
    e => {
      const newTime = e.deltaY > 0 ? played + 5 : played - 5
      updateVideoTime(newTime)
    },
    [played]
  )

  const adjustFragmentTimeAndScroll = useCallback(
    index => {
      const { start, end } = globalTranscription[index]

      const time = start + 0.01

      const currentTime = player.current.getCurrentTime()

      if (!(start <= currentTime && end >= currentTime)) {
        updateVideoTime(time)
        adjustBottomScroll(time)
      }
    },
    [globalTranscription, played]
  )

  const adjustBottomScroll = time => {
    const sight = window.innerWidth
    const leftMarker = time * 100 - sight / 2

    if (scrollContainer.current)
      scrollContainer.current.scrollTo({
        left: leftMarker,
        behavior: 'smooth',
      })
  }

  const checkOffsetLimitsOverflow = () => {
    const sight = window.innerWidth / PIXEL_SECOND_RATIO
    const startMarker = scrollContainer.current.scrollLeft / PIXEL_SECOND_RATIO
    const endMarker = startMarker + sight
    const rightLimitOverflowing =
      endMarker > transcription[transcription.length - 1].end &&
      !isEqual(
        transcription[transcription.length - 1],
        globalTranscription[globalTranscription.length - 1]
      )
    const leftLimitOverflowing =
      startMarker < transcription[0].start && offset !== 0

    if (rightLimitOverflowing) {
      showNextTranscriptionPart(offset, OFFSET_ADJUSTMENT)
    } else if (leftLimitOverflowing) {
      showPreviousTranscriptionPart(offset, OFFSET_ADJUSTMENT)
    }
  }

  const showPreviousTranscriptionPart = (
    localOffset,
    localOffsetAdjustment
  ) => {
    if (visibleBlocks > DEFAULT_VISIBLE_BLOCKS) {
      setVisibleBlocks(DEFAULT_VISIBLE_BLOCKS)
    } else {
      let firstIndex = localOffset - localOffsetAdjustment
      let nextFirstElement = globalTranscription[firstIndex]

      while (
        wouldStartOverflow(nextFirstElement.start) &&
        nextFirstElement.start !== globalTranscription[0].start
      ) {
        localOffset -= localOffsetAdjustment
        firstIndex = localOffset - localOffsetAdjustment
        nextFirstElement = globalTranscription[firstIndex]
      }

      setOffset(localOffset - localOffsetAdjustment)
    }
  }

  const showNextTranscriptionPart = (localOffset, localOffsetAdjustment) => {
    if (nextTranscriptionPartIncomplete()) {
      appendPartIntoExisting()
    } else {
      let lastIndex = visibleBlocks + localOffset + localOffsetAdjustment - 1
      let nextLastElement = globalTranscription[lastIndex]

      while (
        wouldEndOverflow(nextLastElement.end) &&
        nextLastElement.end !==
          globalTranscription[globalTranscription.length - 1].end
      ) {
        localOffset += localOffsetAdjustment
        lastIndex = visibleBlocks + localOffset + localOffsetAdjustment - 1
        if (lastIndex > globalTranscription.length - 1)
          lastIndex = globalTranscription.length - 1
        nextLastElement = globalTranscription[lastIndex]
      }

      setOffset(localOffset + localOffsetAdjustment)
    }
  }

  const wouldEndOverflow = time => {
    const sight = window.innerWidth
    const scrollLeft = scrollContainer.current.scrollLeft
    const rightMarker = (scrollLeft + sight) / 100
    return rightMarker > time
  }

  const wouldStartOverflow = time => {
    const leftMarker = scrollContainer.current.scrollLeft / 100
    return leftMarker < time
  }

  const appendPartIntoExisting = () => {
    const missingPartExtended = [...globalTranscription].splice(
      offset + OFFSET_ADJUSTMENT,
      offset + visibleBlocks
    )
    const missingPart = missingPartExtended.splice(visibleBlocks / 2)
    setVisibleBlocks(visibleBlocks + missingPart.length)
  }

  const nextTranscriptionPartIncomplete = () =>
    [...globalTranscription].splice(
      offset + OFFSET_ADJUSTMENT,
      offset + visibleBlocks
    ).length < visibleBlocks

  const updateVideoTime = useCallback((valueSeconds, actionType) => {
    switch (actionType) {
      case 'progress':
        shouldSeekRef.current = false
        break
      default:
        shouldSeekRef.current = true
        break
    }

    setPlayed(valueSeconds)
  }, [])

  const handleContainerClick = useCallback(e => {
    const time = (e.pageX + scrollContainer.current.scrollLeft) / 100
    updateVideoTime(time)
  }, [])

  // * hotkeys
  useEffect(() => {
    const handler = e => {
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 70) {
        // * ctrl+f
        e.preventDefault()
        setShowReplaceModal(m => !m)
      } else if (e.keyCode === 9) {
        // * tab
        e.preventDefault()
        setPlaying(playing => !playing)
      }
    }

    window.addEventListener('keydown', handler)

    return () => window.removeEventListener('keydown', handler)
  }, [])

  useEffect(() => {
    if (transcription.length === 0) {
      setTranscription(globalTranscription.slice(offset, 20))
    }
  }, [offset, globalTranscription, transcription.length])

  useEffect(() => {
    if (bookmark && bookmark >= 0) {
      updateProjectBookmarkMutation.mutate(bookmark)
    }
  }, [bookmark])

  const [
    { isLoading: projectLoading, data: project },
    { isLoading: settingsLoading, data: settings },
  ] = useQueries([
    {
      queryKey: id,
      queryFn: () => getProject(id),
      onSuccess: data => {
        const trans = data?.data?.data?.transcription
        const bm = data?.data?.data?.bookmark
        const lastStatus = data?.data?.data?.lastStatus
        if (
          role === 'corrector' &&
          (lastStatus === 'new' || lastStatus === 'rejected')
        ) {
          updateProjectStatusMutation.mutate('in-progress')
        }
        setTranscriptionFromApi(trans)
        setFormatOptions(parseFormatting(data.data.data.formatting))
        setBookmark(bm)
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
    },
    {
      queryKey: 'settings',
      queryFn: () => getSettings(),
    },
  ])

  const updateProjectStatusMutation = useMutation(status =>
    updateProjectStatus(id, status)
  )

  const updateProjectTranscriptionMutation = useMutation(trans =>
    updateProjectTranscription(id, trans)
  )

  const updateProjectBookmarkMutation = useMutation(bm =>
    updateProjectBookmark(id, bm)
  )

  const handleBlockChange = useCallback(
    index => {
      if (index !== currentIndex) {
        setCurrentIndex(index)
      }
    },
    [currentIndex]
  )

  const handleChangeProjectStatus = async status => {
    setModal(m => ({ ...m, loading: true }))
    const projectData = project?.data?.data
    const lastStatus = projectData.statuses[projectData.statuses.length - 1]
    if (
      (lastStatus.status === status || projectData.finished) &&
      !(status === 'rejected' && lastStatus.status === 'finished')
    ) {
      setModal({ type: 'hidden', loading: false })
      return
    }
    await updateProjectStatusMutation.mutateAsync(status)
    if (status === 'rejected') {
      notify('success', 'Odrzucono transkrypcje projektu.')
    } else if (status === 'finished') {
      notify('success', 'Zatwierdzono transkrypcje projektu.')
    } else if (status === 'ready') {
      handleSaveTranscription(false)
      notify('success', 'Zgłoszono transkrypcje do sprawdzenia.')
    }
    setModal({ type: 'hidden', loading: false })
  }

  const handleSaveTranscription = async (auto = false) => {
    const projectData = project?.data?.data
    if (projectData.finished && auto) {
      return
    }

    setLoading(true)

    await updateProjectTranscriptionMutation.mutateAsync(globalTranscription)

    if (!auto) {
      const parsedFormatting = parseFormattingForBackend(formatOptions)
      await updateProjectFormatting(id, parsedFormatting)
    }

    setLoading(false)

    if (!auto) {
      notify(
        'success',
        role === 'corrector'
          ? 'Transkrypcja została zapisana, a administrator został powiadomiony.'
          : 'Transkrypcja projektu została zaktualizowana.'
      )
    }
  }

  if (projectLoading || settingsLoading) {
    return <PageLoader />
  }

  const projectData = project?.data?.data

  return (
    <div className="editor">
      <DownloadMaterialsModal
        project={projectData}
        show={dlModal}
        video={projectData.video}
        handleClose={() => {
          setDlModal(false)
        }}
      />
      <ChangeStatusModal
        modal={modal}
        setModal={setModal}
        onAccept={handleChangeProjectStatus}
      />
      <ReplaceWordsModal
        onBlockSelect={block => {
          updateVideoTime(block.start)
        }}
        show={showReplaceModal}
        onConfirm={handleFindAndReplace}
        onCancel={() => {
          setShowReplaceModal(false)
        }}
        transcription={globalTranscription}
      />
      <div className="editor-header">
        <div className="editor-logo">
          <Link to={`/projects/${id}`}>
            <img
              src={toAbsoluteUrl('/media/logos/logo.png')}
              className="max-h-70px"
            />
          </Link>
        </div>
        <FlagProgress
          bookmark={bookmark}
          transcriptionLength={globalTranscription.length}
        />
        <div className="editor-options">
          <h3 style={{ margin: '0' }}>{projectData.name}</h3>
          {(!projectData.finished || role.includes('admin')) && (
            <div className="editor-options-ctrls">
              <div
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => {
                  handleSaveTranscription(false)
                }}
              >
                {loading ? (
                  <span className="spinner spinner-black" />
                ) : (
                  <SVG
                    className="editor-save-icon"
                    src={toAbsoluteUrl('/media/svg/editor/dyskietka.svg')}
                    style={{ marginRight: '0.75rem' }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="editor-container">
        <div className="editor-timestamps">
          <TextareaContainer
            currentIndex={currentIndex}
            transcription={globalTranscription}
            role={role}
            settings={settings}
            handleTextChange={handleTextChange}
            handleBlockDelete={handleBlockDelete}
            handleBlockSplit={handleBlockSplit}
            handleBlockAdd={handleBlockAdd}
            handleBlockJoin={handleBlockJoin}
            onFragmentClick={adjustFragmentTimeAndScroll}
            useBookmark={() => [bookmark, setBookmark]}
          />
        </div>

        <div className="editor-video">
          <FormatActions
            formatting={formatOptions}
            setStatusModal={setModal}
            onFormattingChange={changeFormattingByKey}
            onModalOpen={() => {
              setPlaying(false)
            }}
            setDlModal={setDlModal}
            role={role}
          />
          <VideoPlayer
            duration={duration}
            formatting={formatOptions}
            video={projectData.video}
            transcription={globalTranscription}
            player={player}
            played={played ? played : 0}
            playing={playing}
            setPlaying={setPlaying}
            onBlockChange={handleBlockChange}
            onReplace={() => {
              setShowReplaceModal(true)
            }}
            updateVideoTime={updateVideoTime}
            onReady={() => {
              setDuration(player.current.getDuration())
            }}
            handleUndo={handleUndo}
            handleRedo={handleRedo}
          />
        </div>
      </div>
      <div className="editor-audio">
        <BottomEditor
          transcription={transcription}
          handleTextTimeChange={handleTextTimeChange}
          btmPointerX={btmPointerX}
          onScroll={checkOffsetLimitsOverflow}
          onClick={handleContainerClick}
          duration={duration}
          scrollContainer={scrollContainer}
          offset={offset}
          onWheel={handleBottomWheel}
          played={played ? played : 0}
          id={id}
        />
      </div>
    </div>
  )
}

export default injectIntl(Editor)
