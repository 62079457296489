import React, { forwardRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useQuery } from 'react-query'

import { toAbsoluteUrl } from '_metronic/_helpers'

import { useAuth } from 'contexts/authContext'
import { USERS_ENDPOINT, IMAGES_ENDPOINT } from 'constants.js'
import { CustomizedDatePicker, CustomizedSelect } from 'app/components'
import { getCorrectorRaport, getProjectStats } from 'utils/queries'
import MyProjects from './MyProjects'
import RaportCalendar from './RaportCalendar'
import PageLoader from 'app/components/PageLoader'
import { notify } from 'app/components/notify'

const formatName = obj => {
  return obj ? `${obj.firstName} ${obj.lastName}` : 'Wybierz korektora'
}

const AdminDashboard = () => {
  const {
    state: { user },
  } = useAuth()

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState()
  const [corrector, setCorrector] = useState()
  const [generating, setGenerating] = useState(false)
  const [showError, setShowError] = useState(true)

  const { isLoading, data } = useQuery('projectsStats', getProjectStats)

  if (isLoading) return <PageLoader />

  const generateRaport = () => {
    if (corrector && endDate) {
      setGenerating(true)

      try {
        getCorrectorRaport(
          startDate,
          endDate,
          corrector._id,
          `Raport_${corrector.firstName}_${corrector.lastName}.pdf`
        ).then(() => {
          setGenerating(false)
        })
      } catch (err) {
        notify('error', 'Podczas generowania raportu wystąpił bład')
      }
    }
  }

  const changeCorrector = corrector => {
    setCorrector(corrector)
  }

  return (
    <>
      <div class="row">
        <div className="card card-custom gutter-b col-lg-7">
          <div className="card-body d-flex flex-column justify-content-between">
            <div className="d-flex mb-9">
              <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                <div className="symbol symbol-50 symbol-lg-120">
                  <img
                    src={
                      user.photo
                        ? `${IMAGES_ENDPOINT}${user.photo}?${Date.now()}`
                        : toAbsoluteUrl('/media/client-logos/default.jpeg')
                    }
                    alt=""
                  />
                </div>
              </div>

              <div className="flex-grow-1">
                <div className="d-flex justify-content-between flex-wrap mt-1">
                  <div className="d-flex mr-3">
                    <span className="text-dark font-size-h5 font-weight-bold mr-3">
                      {user.firstName + ' ' + user.lastName}
                    </span>
                    <span>
                      <i className="flaticon2-correct text-success font-size-h5"></i>
                    </span>
                  </div>
                  <NavLink to="/user-profile">
                    <div className="my-lg-0 my-3">
                      <a className="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3">
                        Edycja profilu
                      </a>
                    </div>
                  </NavLink>
                </div>

                <div className="d-flex flex-wrap justify-content-between mt-1">
                  <div className="d-flex flex-column flex-grow-1 pr-8">
                    <div className="d-flex flex-wrap mb-4">
                      <a className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                        <i className="flaticon2-new-email mr-2 font-size-lg"></i>
                        <a
                          href={`mailto:${user.email}`}
                          style={{
                            color: 'inherit',
                          }}
                        >
                          {user.email}
                        </a>
                      </a>

                      <span className="text-dark-50 font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                        <i className="flaticon2-calendar-3 mr-2 font-size-lg"></i>
                        Admin
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="separator separator-solid"></div>

              <div className="d-flex align-items-center flex-wrap mt-8">
                <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                  <span className="mr-4">
                    <i className="flaticon-layers display-4 text-muted font-weight-bold"></i>
                  </span>
                  <div className="d-flex flex-column icon-color">
                    <span className="font-weight-bolder font-size-sm">
                      Nowe
                    </span>
                    <span className="font-weight-bolder font-size-h5">
                      {data?.data?.newProjects}
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                  <span className="mr-4">
                    <i className="flaticon-list display-4 text-muted font-weight-bold"></i>
                  </span>
                  <div className="d-flex flex-column icon-color">
                    <span className="font-weight-bolder font-size-sm">
                      W trakcie
                    </span>
                    <span className="font-weight-bolder font-size-h5">
                      {data?.data?.currentProjects}
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                  <span className="mr-4">
                    <i className="flaticon-notes display-4 text-muted font-weight-bold"></i>
                  </span>
                  <div className="d-flex flex-column icon-color">
                    <span className="font-weight-bolder font-size-sm">
                      Ukończone
                    </span>
                    <span className="font-weight-bolder font-size-h5">
                      {data?.data?.completedProjects}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 pr-0">
          <div
            className="card card-custom gutter-b"
            style={{
              padding: '2rem 2.25rem',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3 className="card-title font-weight-bolder">Raporty</h3>
              <div className="card-toolbar"></div>
            </div>

            <div className="card-body pt-0 d-flex flex-column">
              <div className="pt-2">
                <p className="text-center font-weight-normal font-size-lg pb-2">
                  <div className="d-flex" style={{ flexFlow: 'column' }}>
                    <i className="flaticon2-pie-chart-4 display-2 text-muted font-weight-bold"></i>
                    Wygeneruj raport korektora
                    <div className="col-12 ml-auto p-0">
                      <CustomizedSelect
                        className="form-control mt-8"
                        handleSelect={changeCorrector}
                        fontSize="small"
                        role="corrector"
                        disableLoader
                        placeholder={
                          corrector ? formatName(corrector) : formatName('')
                        }
                        endpoint={USERS_ENDPOINT}
                        style={{
                          background: 'white',
                          borderColor: 'rgb(228, 230, 239)',
                          borderRadius: '5.46px',
                          width: '100%',
                          padding: '0',
                          height: '38px',
                        }}
                        disableStyles
                        inputStyle={{
                          width: '100%',
                          height: '100%',
                          border: '0',
                          borderRadius: '5.46px',
                          padding: '1em',
                        }}
                      />
                    </div>
                    <div className="datepicker-range mt-10">
                      <div className="row">
                        <div className="input-group date col-6">
                          <div
                            style={{ textAlign: 'left', paddingLeft: '13px' }}
                          >
                            od:
                          </div>
                          <CustomizedDatePicker
                            selected={startDate}
                            onChange={date => {
                              //setFieldValue('deadline', date.toISOString())

                              setStartDate(date)
                            }}
                            customInput={<RaportCalendar />}
                          />
                        </div>

                        <div className="input-group date col-6">
                          <div
                            style={{ textAlign: 'left', paddingLeft: '13px' }}
                          >
                            do:
                          </div>
                          <CustomizedDatePicker
                            selected={endDate}
                            onChange={date => {
                              if (date.getTime() >= startDate.getTime())
                                setEndDate(date)
                              else setEndDate(startDate)
                            }}
                            customInput={<RaportCalendar />}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </p>
                <button
                  onClick={() => {
                    generateRaport()
                  }}
                  className="btn btn-success btn-shadow-hover font-weight-bolder w-100 py-3 position-relative"
                  style={{
                    pointerEvents: generating ? 'none' : 'unset',
                  }}
                >
                  <span
                    style={{
                      opacity: generating ? '0.25' : '1',
                      transition: '0.27s opacity',
                    }}
                  >
                    Generuj raport
                  </span>
                  <div
                    className="spinner spinner-white position-absolute"
                    style={{
                      top: '50%',
                      left: '50%',
                      opacity: generating ? '1' : '0',
                      transition: '0.35s opacity',
                    }}
                  ></div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 p-0">
          <div
            className="card card-custom card-stretch gutter-b"
            style={{
              padding: '2rem 2.25rem',
            }}
          >
            <h3 className="card-title font-weight-bolder">Ostatnie projekty</h3>
            <MyProjects type="dashboard" role="admin" />
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminDashboard
