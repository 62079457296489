import React, { forwardRef } from 'react'
import { AngleDown as ArrowDownIcon } from './icons'

export const Toggle = forwardRef(({ children, onClick }, ref) => {
  return (
    <div
      className="gray-border-input"
      href=""
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
      <ArrowDownIcon
        className="ml-2"
        style={{
          width: '13px',
          height: '13px',
        }}
      />
    </div>
  )
})

export const Menu = forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <div
          className="menu-utils"
          style={{
            display: 'flex',
            flexFlow: 'column',
            textAlign: 'center',
            minWidth: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {children}
        </div>
      </div>
    )
  }
)

export const Item = forwardRef(({ children, onClick }, ref) => {
  return (
    <span
      href=""
      className="mr-2"
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </span>
  )
})
