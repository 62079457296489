import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import { injectIntl } from 'react-intl'

import { toAbsoluteUrl } from '_metronic/_helpers'
import { post } from 'utils/api'
import { notify } from 'app/components/notify'
import { RaportModal } from 'app/components/RaportModal'
import { LOGIN_AS_ENDPOINT, LOGIN } from 'constants.js'
import { useAuth } from 'contexts/authContext'
import { UserActionsModal } from 'app/components/UserActionsModal'
import { ProfileModal } from 'app/components/ProfileModal'

export const ActionsFormatter = injectIntl(({ user, intl, raports }) => {
  const [modal, setModal] = useState({
    type: 'hidden',
    loading: false,
  })

  const [contactModal, setContactModal] = React.useState(false)

  const history = useHistory()

  const {
    state: {},
    dispatch,
  } = useAuth()

  const loginAction = payload =>
    dispatch({
      type: LOGIN,
      payload,
    })

  const loginAs = async id => {
    try {
      const response = await post(`${LOGIN_AS_ENDPOINT}/${id}`)
      loginAction({
        jwt: response.token,
        refresh: response.refresh,
        user: response.data.user,
      })
      history.push('/')
    } catch (err) {
      notify(
        'error',
        intl.formatMessage({
          id: err?.response?.data?.id || 'API.ERROR.GENERAL',
        })
      )
    }
  }

  if (user.role === 'super-admin') {
    return <></>
  }

  return (
    <>
      <ProfileModal
        user={user}
        modal={contactModal}
        setModal={setContactModal}
      />
      <UserActionsModal modal={modal} setModal={setModal} id={user._id} />
      <div className="text-right">
        {user.active && (
          <>
            {raports && (
              <>
                <RaportModal modal={modal} setModal={setModal} user={user} />
                <OverlayTrigger overlay={<Tooltip>Wygeneruj raport</Tooltip>}>
                  <div
                    onClick={() => {
                      setModal(m => ({ ...m, type: 'raport' }))
                    }}
                    className={`btn btn-sm btn-default btn-text-warning btn-hover-dark btn-icon mr-2`}
                  >
                    <span className="svg-icon svg-icon-sm">
                      <i className="fas fa-file-pdf"></i>
                    </span>
                  </div>
                </OverlayTrigger>
              </>
            )}
            <OverlayTrigger
              overlay={
                <Tooltip>{user.blocked ? 'Odblokuj' : 'Zablokuj'}</Tooltip>
              }
            >
              <div
                onClick={() => {
                  setModal(m => ({
                    ...m,
                    type: user.blocked ? 'unblock' : 'block',
                  }))
                }}
                className={`btn btn-sm btn-${
                  user.blocked ? 'danger' : 'default'
                } btn-text-${user.blocked ? 'primary' : 'warning'} btn-hover-${
                  user.blocked ? 'primary' : 'warning'
                } btn-icon mr-2`}
              >
                <span className="svg-icon svg-icon-sm">
                  <i className="far fa-stop-circle "></i>
                </span>
              </div>
            </OverlayTrigger>

            {/* {!user.role.includes('admin') && (
              <Link to={`/projects/list?${user.role}=${user._id}`}>
                <OverlayTrigger overlay={<Tooltip>Lista projektów</Tooltip>}>
                  <div
                    className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                    onClick={() => {
                      console.log('pop')
                    }}
                  >
                    <span className="svg-icon svg-icon-sm">
                      <i className="fas fa-list icon-size-13"></i>
                    </span>
                  </div>
                </OverlayTrigger>
              </Link>
            )} */}

            {user.role === 'corrector' && (
              <OverlayTrigger overlay={<Tooltip>Kontakt</Tooltip>}>
                <div
                  onClick={() => {
                    setContactModal(true)
                  }}
                  className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                >
                  <span className="svg-icon svg-icon-sm">
                    <i className="fas fa-phone icon-size-13"></i>
                  </span>
                </div>
              </OverlayTrigger>
            )}

            <OverlayTrigger overlay={<Tooltip>Zaloguj jako</Tooltip>}>
              <div
                className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                onClick={() => {
                  loginAs(user._id)
                }}
              >
                <span className="svg-icon svg-icon-sm">
                  <i className="fas fa-lock-open icon-size-13"></i>
                </span>
              </div>
            </OverlayTrigger>

            <Link to={`/users/${user._id}`}>
              <OverlayTrigger overlay={<Tooltip>Edytuj</Tooltip>}>
                <div className="btn btn-sm btn-default btn-text-primary btn-hover-success btn-icon mr-2">
                  <span className="svg-icon svg-icon-sm">
                    <i className="fas fa-pencil-alt icon-size-13"></i>
                  </span>
                </div>
              </OverlayTrigger>
            </Link>
          </>
        )}

        <OverlayTrigger overlay={<Tooltip>Usuń</Tooltip>}>
          <div
            onClick={() => {
              setModal(m => ({
                ...m,
                type: 'delete',
              }))
            }}
            className="btn btn-sm btn-default btn-text-primary btn-hover-danger btn-icon mr-2"
          >
            <span className="svg-icon svg-icon-sm">
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
            </span>
          </div>
        </OverlayTrigger>
      </div>
    </>
  )
})
