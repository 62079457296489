import React, { useState, useEffect, useMemo, useCallback } from 'react'
import TranscriptionFragment from 'app/pages/editor/TranscriptionFragment'
import Waveforms from 'app/pages/editor/Waveforms'

/* eslint-disable */

const BottomEditor = ({
  transcription,
  handleTextTimeChange,
  duration,
  scrollContainer,
  onScroll,
  offset,
  onWheel,
  onClick,
  onBlockClick,
  played,
  id,
}) => {
  const transcriptions = useMemo(() => {
    return transcription.map((t, i) => {
      const lBound = transcription[i - 1] ? transcription[i - 1].end * 100 : 0
      const rBound = transcription[i + 1]
        ? transcription[i + 1].start * 100
        : transcription[i - 1]?.end * 100 + 500
      const isLastElement = i === transcription.length - 1

      const x = transcription[i - 1]
        ? (t.start - transcription[i - 1].end) * 100
        : t.start * 100

      const bounds = {
        left: lBound,
        right: isLastElement ? duration * 100 : rBound,
      }

      return (
        <TranscriptionFragment
          bounds={bounds}
          text={t.segment}
          x={x}
          width={(t.end - t.start) * 100}
          index={i}
          number={i + offset}
          handleTextTimeChange={handleTextTimeChange}
          onClick={onBlockClick}
          duration={duration}
          isLastElement={isLastElement}
        />
      )
    })
  }, [transcription])

  const [left, setLeft] = useState('50vw')

  const adjustPointerPosition = () => {
    const leftPosition = played * 100

    if (leftPosition < window.innerWidth / 2) {
      setLeft(leftPosition + 'px')
    } else {
      setLeft('50vw')
    }
  }

  useEffect(adjustPointerPosition, [played])

  return (
    <>
      <div
        ref={scrollContainer}
        className="bottom-editor"
        onClick={onClick}
        onScroll={onScroll}
        onWheel={onWheel}
      >
        <div
          className="btm-pointer"
          style={{
            left: left,
          }}
        ></div>

        <div style={{ paddingTop: '1.5em' }}>{transcriptions}</div>

        <Waveforms id={id} duration={duration} />
      </div>
    </>
  )
}

export default BottomEditor
