import React from 'react'

const LoginError = ({ message, type }) => {
  const typeClasses = {
    success: 'alert-success',
    error: 'alert-danger',
  }

  {
    return message === '' ? (
      <></>
    ) : (
      <div className={`alert ${typeClasses[type]}`} role="alert">
        {message}
      </div>
    )
  }
}

export default LoginError
