import React from 'react'
import UserNavigation from './UserNavigation'

const ProfileSettings = () => {
  return (
    <>
      <UserNavigation />
      ProfilSettings
    </>
  )
}

export default ProfileSettings
