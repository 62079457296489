import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { injectIntl, FormattedMessage } from 'react-intl'
import WordsContainer from 'app/pages/editor/WordsContainer'

const ReplaceWordsModal = injectIntl(
  ({ show, onConfirm, onBlockSelect, onCancel, transcription }) => {
    const [oldValue, setOldValue] = useState('')
    const [newValue, setNewValue] = useState('')
    const [foundValues, setFoundValues] = useState([])

    const cleanValues = () => {
      setOldValue('')
      setNewValue('')
    }

    const handleChange = e => {
      setOldValue(e.target.value)
      findValues(e.target.value)
    }

    const findValues = text => {
      const results = transcription.filter(block => {
        return block.segment.toLowerCase().includes(text.toLowerCase())
      })

      setFoundValues(results)
    }

    return (
      <Modal show={show} size="lg" centered onHide={onCancel}>
        <Modal.Body>
          <div className="form-group p-4">
            <label>
              <FormattedMessage id="GENERAL.FIND" />:
            </label>
            <input
              onChange={handleChange}
              value={oldValue}
              className="form-control form-control-solid"
              type="text"
            ></input>

            <label>
              <FormattedMessage id="EDITOR.CHANGE.TO" />:
            </label>
            <input
              onChange={e => setNewValue(e.target.value)}
              value={newValue}
              className="form-control form-control-solid"
              type="text"
            ></input>

            <WordsContainer
              items={foundValues}
              keyWord={oldValue.toLowerCase()}
              replacingWord={newValue}
              onSelect={onBlockSelect}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>
            <FormattedMessage id="GENERAL.LEAVE" />
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onConfirm(oldValue, newValue)
              onCancel()
              cleanValues()
            }}
          >
            <FormattedMessage id="GENERAL.SAVE" />
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
)

export default ReplaceWordsModal
