/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import Editor from 'app/pages/editor/Editor'

import { Layout } from '../_metronic/layout'

import BasePage from './BasePage'
import Logout from './modules/Auth/pages/Logout'
import AuthPage from './modules/Auth/pages/AuthPage'
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage'
import { useAuth } from '../contexts/authContext'

const Routes = props => {
  const { state: authState } = useAuth()
  const { isAuth } = authState

  return (
    <Switch>
      {!isAuth ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="*/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />
      <Route path="/editor/:id" component={Editor} />

      {!isAuth ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  )
}

export default Routes
