import React from 'react'

export const ProgressFormatter = ({ value }) => (
  <div className="progress">
    <div
      className="progress-bar bg-success"
      role="progressbar"
      style={{ width: `${value}%` }}
      aria-valuenow={value}
      aria-valuemin="0"
      aria-valuemax="100"
    />
  </div>
)
