import React from 'react'

export const FlagProgress = ({ bookmark, transcriptionLength }) => {
  const value = (bookmark / transcriptionLength) * 100
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100px',
      }}
    >
      <label for="progress-bar">Postęp pracy</label>
      {/* <progress
        aria-label="Postęp pracy korektora"
        value={bookmark}
        max={transcriptionLength}
      /> */}
      <div className="progress">
        <div
          className="progress-bar bg-success"
          role="progressbar"
          style={{ width: `${value}%` }}
          aria-valuenow={value}
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </div>
    </div>
  )
}
