import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Check from '@material-ui/icons/Check'
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Button,
} from '@material-ui/core'

export function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  const icons = {
    1: (
      <i
        style={{ fontSize: '3.75rem' }}
        className="wizard-icon flaticon-calendar-3 MuiStep-completed"
      />
    ),
    2: (
      <i
        style={{ fontSize: '3.75rem' }}
        className="wizard-icon flaticon-upload-1"
      ></i>
    ),
    3: (
      <i
        style={{ fontSize: '3.75rem' }}
        className="wizard-icon flaticon-list"
      ></i>
    ),
    4: (
      <i
        style={{ fontSize: '3.75rem' }}
        className="wizard-icon flaticon-globe"
      ></i>
    ),
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

export const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector)

export const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'red',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
})

export function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles()
  const { active, completed } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  )
}

export const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 28,
  },
  active: {
    '& $line': {
      backgroundColor: '#3699FF',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#3699FF',
    },
  },
  line: {
    height: 3,
    width: '90%',
    margin: '0 auto',
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
    transition: '0.15s background-color',
  },
})(StepConnector)

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: '0 auto',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

export const useColorlibStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    width: 50,
    height: 50,
    color: 'red',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export const scrollUp = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}
