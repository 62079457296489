import React from 'react'
import { useAuth } from 'contexts/authContext'
import { FormattedMessage } from 'react-intl'

const PanelNavigation = ({ activeTab, setActiveTab, user }) => {
  return (
    <div className="card-header card-header-tabs-line">
      <div className="card-toolbar">
        <ul
          className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-tabs-bold nav-tabs-line-3x"
          role="tablist"
        >
          <li className="nav-item mr-3">
            <button
              type="button"
              style={{ background: 'white' }}
              className={`nav-link ${activeTab === 'details' ? 'active' : ''} `}
              onClick={() => {
                setActiveTab('details')
              }}
            >
              <span className="nav-icon mr-2">
                <span className="svg-icon mr-3">
                  <i className="flaticon2-settings"></i>
                </span>
              </span>
              <span className="nav-text font-weight-bold">
                <FormattedMessage id="PROJECT.INFO" />
              </span>
            </button>
          </li>

          {!user.role.includes('client') && (
            <li className="nav-item mr-3">
              <button
                type="button"
                style={{ backgroundColor: 'white' }}
                className={`nav-link ${
                  activeTab === 'corrector' ? 'active' : ''
                } `}
                onClick={() => {
                  setActiveTab('corrector')
                }}
              >
                <span className="nav-icon mr-2">
                  <span className="svg-icon mr-3">
                    <i className="flaticon-file-2"></i>
                  </span>
                </span>
                <span className="nav-text font-weight-bold">
                  <FormattedMessage id="PROJECT.CORRECTOR" />
                </span>
              </button>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default PanelNavigation
