import React from 'react'
import { Modal } from 'react-bootstrap'
import { injectIntl, FormattedMessage } from 'react-intl'

import { statusesConstants } from 'constants.js'

export const ChangeStatusModal = injectIntl(
  ({ modal, setModal, intl, onAccept }) => {
    const show = modal.type !== 'hidden'

    const status = statusesConstants[modal.type]

    return (
      <Modal
        show={show}
        onHide={() => {
          setModal(m => ({ ...m, type: 'hidden' }))
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Status zadania</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Czy chcesz zmienić status na "
          {status && intl.formatMessage({ id: status.intl } || 'Brak')}"
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            disabled={modal.loading}
            className={`btn show-notify btn-primary mr-2`}
            onClick={() => onAccept(modal.type)}
          >
            <FormattedMessage id="GENERAL.ACCEPT" />
            {modal.loading && (
              <span className="ml-3 mr-5 spinner spinner-white"></span>
            )}
          </button>
          <button
            disabled={modal.loading}
            type="button"
            className="btn btn-secondary font-weight-bold mr-2"
            onClick={() => {
              setModal(m => ({ ...m, type: 'hidden' }))
            }}
          >
            <FormattedMessage id="GENERAL.CANCEL" />
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
)
