import React from 'react'
import { selectFilter } from 'react-bootstrap-table2-filter'

import { sortCaret, headerSortingClasses } from '_metronic/_helpers'

import {
  ActionsFormatter,
  CompanyFormatter,
  ProfileFormatter,
  RoleFormatter,
  StatusFormatter,
} from '../formatters/users'
import { DateFormatter } from '../formatters/common/DateFormatter'
import { USERS_ENDPOINT } from 'constants.js'

export const rowStyle = (row, rowIndex) => {
  return {
    height: '67px',
  }
}

export const options = {
  defaultSortField: '-createdAt',
  keyField: 'email',
  endpoint: USERS_ENDPOINT,
  rowStyle: rowStyle,
}

const actions = {
  dataField: 'actions',
  text: 'Akcje',
  formatter: (value, row) => <ActionsFormatter user={row} />,
  headerStyle: (colum, colIndex) => {
    return {
      width: '20%',
      textAlign: 'right',
      paddingRight: 'calc(0.75rem + 6.5px)',
    }
  },
}

const correctorProjects = {
  dataField: 'corrector-projects',
  text: 'Aktywne zadania',
  formatter: (value, row) => (
    <span
      className="font-weight-bold text-muted"
      style={{ paddingLeft: '10px' }}
    >
      {value}
    </span>
  ),
  headerStyle: (colum, colIndex) => {
    return {
      width: '10%',
    }
  },
}

export const columns = (type = 'all') => {
  const cols = [
    {
      dataField: 'lastName',
      text: 'Użytkownik',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (value, row) => {
        return <ProfileFormatter user={row} type={type} id={row._id} />
      },
    },
    {
      dataField: 'role',
      text: 'Rola',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (value, row) => <RoleFormatter role={value} />,
      headerStyle: (colum, colIndex) => {
        return {
          width: '10%',
        }
      },
    },
    {
      dataField: 'active',
      text: 'Status',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (value, row) => <StatusFormatter status={value} />,
      headerStyle: (colum, colIndex) => {
        return {
          width: '9%',
        }
      },
    },
    {
      dataField: 'company',
      text: 'Organizacja',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (value, row) => <CompanyFormatter company={value} />,
      headerStyle: (colum, colIndex) => {
        return {
          width: '15%',
        }
      },
    },
    {
      dataField: 'activatedAt',
      text: 'Data rejestracji',
      sort: true,
      sortCaret: sortCaret,
      formatter: (value, row) => <DateFormatter date={value} />,
      headerStyle: (colum, colIndex) => {
        return {
          width: '15%',
        }
      },
    },
    actions,
  ]

  if (type !== 'all') {
    const roleIndex = cols.findIndex(el => el.dataField === 'role')
    cols.splice(roleIndex, 1)
  }

  if (type === 'corrector') {
    const companyIndex = cols.findIndex(el => el.dataField === 'company')
    cols.splice(companyIndex, 1)

    const actionsIndex = cols.findIndex(el => el.dataField === 'actions')
    const newActions = { ...actions }
    newActions.formatter = (value, row) => (
      <ActionsFormatter user={row} raports />
    )
    cols.splice(actionsIndex, 1, newActions)

    cols.splice(1, 0, correctorProjects)
  }

  if (type === 'client') {
    const companyIndex = cols.findIndex(el => el.dataField === 'company')
    const companyItem = { ...cols[companyIndex] }
    cols.splice(companyIndex, 1)
    cols.unshift(companyItem)
  }

  return cols
}
