import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { CustomizedDropzone } from 'app/components'
import { startUpload } from 'utils/upload'
import { notify } from 'app/components/notify'
import { uploadYoutube } from 'utils/mutations'

const AddVideoModal = ({
  show,
  setShow,
  handleClose,
  handleShow,
  projectId,
  setUploading,
  settings,
}) => {
  const [video, setVideo] = useState()
  const [videoUrl, setVideoUrl] = useState('')

  const handleSubmit = () => {
    if (!video && !videoUrl) {
      notify('error', 'Nie dodano żadnego materiału wideo.')
    } else if (videoUrl) {
      setUploading(true)
      uploadYoutube(projectId, videoUrl).then(() => setUploading(false))
    } else {
      startUpload([video], projectId)
      setUploading(true)
    }

    handleClose()
  }

  const handleCancel = () => {
    setVideo()
    setVideoUrl()
    handleClose()
  }

  const handleVideoAdd = file => {
    setVideo(file)
  }

  return (
    <>
      <Modal show={show} onHide={handleCancel} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Dodanie wideo projektu</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ width: '80%', margin: '0 auto' }}>
          <label>Link do filmu na YouTube</label>
          <input
            name="videoUrl"
            placeholder="Adres URL"
            className="form-control form-control-solid form-control-lg mb-5"
            value={videoUrl}
            onChange={e => {
              setVideoUrl(e.target.value)
            }}
          ></input>
          {/* <div className="mb-6">
            <span className="font-weight-bold text-dark">Lub</span>
          </div> */}
          <label>Wybierz z komputera</label>
          <div className="mb-5">
            <CustomizedDropzone
              isProjectDetailPage={true}
              onFileAdded={handleVideoAdd}
              maxFileSize={settings.maxMaterialSize * 1000000}
            />
          </div>

          <button
            className="btn btn-secondary font-weight-bold mt-4 ml-4 float-right"
            type="button"
            onClick={handleCancel}
          >
            Anuluj
          </button>
          <button
            className="btn btn-primary font-weight-bold mt-4 float-right"
            onClick={handleSubmit}
            type="button"
          >
            Wyślij
          </button>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddVideoModal
