import React, { useState, useEffect } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useInfiniteQuery } from 'react-query'
import { get } from '../../utils/api'
import { SelectUserItem } from './SelectUserItem'
import InfiniteScroll from 'react-infinite-scroll-component'

const CustomizedSelect = ({
  endpoint,
  role,
  className,
  placeholder,
  activePlaceholder,
  handleSelect,
  style,
  disableStyles = false,
  disableLoader = false,
  inputStyle,
  fontSize,
  queryKey,
}) => {
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [isSelectOpen, setIsSelectOpen] = useState(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      refetch()
    }, 500)
  }, [refetch, search])

  const {
    data,
    error,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    status,
    refetch,
    isLoading,
  } = useInfiniteQuery(
    queryKey || 'customSelect',
    ({ pageParam = 1 }) =>
      get(endpoint, {
        role: role,
        sort: '_id',
        limit: 5,
        page: pageParam,
        search: search,
        active: true,
      }).then(res => ({ results: res.data.data, next: pageParam + 1 })),
    {
      getNextPageParam: lastPage => lastPage.next,
    }
  )

  if (data === undefined) {
    return null
  }
  const dataLength = data.pages.reduce((counter, page) => {
    return counter + page.results.length
  }, 0)

  const handleSearch = e => {
    setSearch(e.target.value)
  }

  return (
    <Dropdown
      drop="down"
      onToggle={(isOpen, event, metadata) => {
        setIsSelectOpen(isOpen)
      }}
    >
      <Dropdown.Toggle className={className} style={style}>
        <input
          className={disableStyles ? '' : 'custom-search-input'}
          placeholder={
            isSelectOpen ? activePlaceholder || placeholder : placeholder
          }
          value={search}
          onChange={handleSearch}
          type="text"
          style={inputStyle}
        />
        {loading && !disableLoader ? (
          <div className="spinner spinner-primary mr-15 float-left"></div>
        ) : (
          ''
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ width: '100%' }}>
        <InfiniteScroll
          dataLength={dataLength}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          loader={<div></div>}
          height={300}
        >
          <div>
            {dataLength === 0 ? <NoDataIndicator /> : ''}
            {data &&
              data.pages.map(group =>
                group.results.map((el, i) => (
                  <Dropdown.Item
                    key={i}
                    className="btn-block px-5 py-3"
                    onSelect={() => {
                      setSearch('')
                      handleSelect(el)
                    }}
                  >
                    <SelectUserItem user={el} />
                  </Dropdown.Item>
                ))
              )}
          </div>
        </InfiniteScroll>
      </Dropdown.Menu>
    </Dropdown>
  )
}

const NoDataIndicator = () => {
  return <div className="text-muted text-center">Brak danych.</div>
}

export default CustomizedSelect
