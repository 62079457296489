import React from 'react'
import { Modal } from 'react-bootstrap'

export const ProfileModal = ({ user, modal, setModal }) => (
  <Modal size="md" show={modal}>
    <Modal.Header closeButton>
      <Modal.Title>Dane użytkownika</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className={`col-md-12 col-sm-12 pb-5`}>
        <div className="text-dark-50 line-height-lg custom-summary">
          <div>
            <span className="text-muted">Email:</span>{' '}
            <a
              className="font-weight-bold text-hover-primary"
              href={`mailto:${user.email}`}
            >
              {user.email}
            </a>
          </div>
          <div>
            <span className="text-muted">Telefon:</span> {user.phone || 'Brak'}
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button
        type="button"
        className="btn btn-primary font-weight-bold mr-2"
        onClick={() => {
          setModal(false)
        }}
      >
        Zamknij
      </button>
    </Modal.Footer>
  </Modal>
)
