import React from 'react'

const SearchBar = ({ input, onChange }) => {
  return (
    <div className="col-md-4 my-2 my-md-0">
      <div className="input-icon">
        <input
          className="form-control"
          type="text"
          placeholder="Szukaj..."
          onChange={onChange}
          value={input}
        ></input>
        <span className="">
          <i className="flaticon2-search-1 text-muted"></i>
        </span>
      </div>
    </div>
  )
}

export default SearchBar
