import React from 'react'

const CustomOverlay = React.memo(({ loading }) => {
  return (
    <div
      style={{
        opacity: loading ? '1' : '0',
        transition: '0.12s opacity ease-in',
        cursor: 'default',
      }}
      className="table_loader_content custom_overlay"
    >
      <span>Proszę czekać...</span>
      <span
        style={{ left: '75%', paddingLeft: '0.55em' }}
        className="spinner spinner-loader spinner-primary"
      ></span>
    </div>
  )
})

export default CustomOverlay
