import React from 'react'
import { DateFormatter } from '../formatters/common/DateFormatter'
import { ProfileFormatter } from '../formatters/users/ProfileFormatter'
import ActionsFormatter from '../formatters/TaskFormatters/ActionsFormatter'
import { StatusFormatter } from 'app/modules/Table/formatters/projects/StatusFormatter'
import { mapPopulatedStatuses } from 'utils/helpers'
import StatusesSelect from 'app/pages/projects/details/panel/selects/StatusesSelect'

export const options = {
  defaultSortField: '-createdAt',
  keyField: '_id',
}

export const columns = [
  {
    dataField: 'name',
    text: 'Nazwa',
  },
  // {
  //   dataField: 'project.deadline',
  //   text: 'Deadline',
  //   formatter: (value, row) => {
  //     return (
  //       <div style={{ display: 'flex', justifyContet: 'center' }}>
  //         <DateFormatter date={value} formatString />
  //       </div>
  //     )
  //   },
  //   headerStyle: (colum, colIndex) => {
  //     return { width: '15%' }
  //   },
  // },
  // {
  //   dataField: 'statuses',
  //   text: 'Status',
  //   sort: false,
  //   formatter: (value, row) => {
  //     const status = value[value.length - 1]
  //     return (
  //       <StatusFormatter
  //         text={status.name}
  //         bgColor={status.bgColor}
  //         txtColor={status.txtColor}
  //       />
  //     )
  //   },
  // },
  {
    dataField: 'corrector',
    text: 'Korektor',
    formatter: (value, row) => {
      return <ProfileFormatter user={value} />
    },
  },
  {
    text: 'Akcje',
    formatter: (value, row) => {
      return <ActionsFormatter row={row} />
    },
    headerStyle: (colum, colIndex) => {
      return {
        textAlign: 'right',
        paddingRight: 'calc(0.75rem + 6.5px)',
        width: '18%',
      }
    },
  },
]
