import React, { useState, useEffect, useCallback } from 'react'
import Textarea from './Textarea'
import {
  Cancel as CancelIcon,
  Accepted as AcceptedIcon,
  Play as PlayIcon,
  LeftArrow as LeftArrowIcon,
  RightArrow as RightArrowIcon,
  Add as AddIcon,
  Join as JoinIcon,
} from './icons'
import { displayHMSM } from './index'
import { injectIntl, useIntl } from 'react-intl'

const visibleElements = 30
const elementHeight = 135

const TextareaContainer = injectIntl(
  ({
    currentIndex,
    transcription,
    settings,
    handleTextChange,
    handleBlockDelete,
    handleBlockSplit,
    handleBlockAdd,
    handleBlockJoin,
    onFragmentClick,
    useBookmark,
  }) => {
    const [containerHeight, setContainerHeight] = useState(0)
    const [offset, setOffset] = useState(0)

    const [bookmark, setBookmark] = useBookmark()
    const intl = useIntl()

    const scrollableRef = useCallback(node => {
      if (node) {
        setContainerHeight(node.getBoundingClientRect().height)
      }
    })

    useEffect(() => {
      const scrollable = document.querySelector('.left-scrollable')

      const height = scrollable.getBoundingClientRect().height
      const elementsCnt = Math.floor(height / elementHeight) - 1

      const isActiveIndexOutsideOfView =
        currentIndex * elementHeight >
          scrollable.scrollTop + elementsCnt * elementHeight ||
        currentIndex * elementHeight < scrollable.scrollTop

      if (isActiveIndexOutsideOfView) {
        scrollable.scrollTop = currentIndex * elementHeight
      }
    }, [currentIndex])

    useEffect(() => {
      if (bookmark) {
        const scrollable = document.querySelector('.left-scrollable')
        scrollable.scrollTop = bookmark * elementHeight
      }
    }, [bookmark])

    const getOffset = scroll => {
      const x = scroll / elementHeight - visibleElements / 2
      const offset = Math.floor(x / 10) * 10
      return offset < 0 ? 0 : offset
    }

    const virtualize = scroll => {
      const foundOffset = getOffset(scroll + containerHeight)
      setOffset(foundOffset)
    }

    const trackScroll = e => {
      const newScroll = e.target.scrollTop
      virtualize(newScroll)
    }

    const focusPreviousSibling = (e, position) => {
      const targetElement = e.target.parentElement.previousSibling.querySelector(
        'textarea'
      )

      const contentLength = targetElement.value.length
      targetElement.focus()

      setTimeout(() => {
        targetElement.setSelectionRange(contentLength, contentLength)
      }, 10)
    }

    const focusNextSibling = (e, position) => {
      const targetElement = e.target.parentElement.nextSibling.querySelector(
        'textarea'
      )

      targetElement.focus()

      setTimeout(() => {
        targetElement.setSelectionRange(0, 0)
      }, 10)
    }

    const handleKeydown = (e, i) => {
      if (
        e.key === 'Backspace' &&
        e.target.selectionStart === 0 &&
        e.target.selectionEnd === 0
      ) {
        handleBlockJoin(i - 1)
        focusPreviousSibling(e)
      }
      if (e.key === 'Enter') {
        handleEnter(e, i)
      }
    }

    const handleEnter = (e, i) => {
      if (e.shiftKey) return
      e.preventDefault()

      if (i !== transcription.length - 1) {
        focusNextSibling(e)
      }

      const selection = e.target.selectionStart
      const firstSegment = e.target.value.substring(0, selection)
      const secondSegment = e.target.value.substring(selection)
      if (secondSegment === '') {
        handleBlockAdd(i)
      } else {
        handleBlockSplit(firstSegment, secondSegment, i)
      }
    }

    return (
      <div
        className="left-scrollable"
        ref={scrollableRef}
        onScroll={trackScroll}
      >
        <div
          className="left-inside-scrollable"
          style={{
            height: transcription.length * elementHeight,
          }}
        >
          {transcription
            .slice(offset, offset + visibleElements)
            .map((block, i) => {
              const cps = Math.round(
                block.segment.length / (block.end - block.start)
              )

              return (
                <div
                  className={`${
                    i + offset === currentIndex ? 'active-scroll-element' : ''
                  } left-block`}
                  style={{
                    display: 'flex',
                    position: 'absolute',
                    height: elementHeight,
                    width: '100%',
                    top: (i + offset) * elementHeight,
                    padding: '0.2rem 1rem',
                  }}
                  key={i + offset}
                >
                  <div className="editor-timestamps-data-holder">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <PlayIcon className="play-icon" />
                      <span className="index-num ml-2">{i + 1 + offset}</span>
                      <div
                        className={`lazy-cps ${
                          cps > settings?.charactersPerSecond ? 'danger' : ''
                        } ${cps < 0 ? 'danger-cps' : ''}`}
                      >
                        {cps} CPS
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexFlow: 'column' }}>
                      <div>
                        <RightArrowIcon className="arrow-icon mr-2" />
                        {displayHMSM(block.start)}
                      </div>
                      <div>
                        <LeftArrowIcon className="arrow-icon mr-2" />
                        {displayHMSM(block.end)}
                      </div>
                    </div>
                  </div>

                  <div
                    className={`lazy-cpl ${
                      block.segment
                        .split('\n')
                        .filter(
                          line => line.length > settings.charactersPerLine
                        ).length > 0
                        ? 'danger'
                        : ''
                    }`}
                    style={{
                      width: '35px',
                    }}
                  >
                    <span>CPL</span>
                    {block.segment.split('\n').map(line => (
                      <span>{line.length}</span>
                    ))}
                  </div>
                  <Textarea
                    onClick={e => {
                      onFragmentClick(i + offset)
                    }}
                    onKeyDown={e => handleKeydown(e, i + offset)}
                    onChange={newValue => {
                      handleTextChange(newValue, i + offset)
                    }}
                    value={block.segment}
                  />
                  <div className="lazy-icons">
                    <div>
                      <CancelIcon
                        onClick={() => {
                          handleBlockDelete(i + offset)
                        }}
                        className="action-icon"
                        title={intl.formatMessage({
                          id: 'EDITOR.DELETE.BLOCK',
                        })}
                      />
                    </div>

                    {/* {role === 'corrector' && ( */}
                    <div>
                      <AcceptedIcon
                        className={`action-icon ${
                          bookmark === i + offset ? 't-accepted' : ''
                        }`}
                        onClick={() => {
                          setBookmark(i + offset)
                        }}
                        title={intl.formatMessage({
                          id: 'EDITOR.BLOCK.STATE',
                        })}
                      />
                    </div>
                    {/* )} */}
                  </div>
                  <div className="lazy-border-icons">
                    <AddIcon
                      onClick={() => {
                        handleBlockAdd(i + offset)
                      }}
                      className="mr-2"
                      title={intl.formatMessage({
                        id: 'EDITOR.ADD.BLOCK',
                      })}
                    />
                    <JoinIcon
                      onClick={() => {
                        handleBlockJoin(i + offset)
                      }}
                      title={intl.formatMessage({
                        id: 'EDITOR.CONNECT.BLOCKS',
                      })}
                    />
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    )
  }
)

export default TextareaContainer
