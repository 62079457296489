/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import ReactPlayer from 'react-player'
import { displayHMSM } from './index.js'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '_metronic/_helpers/AssetsHelpers'
import 'bootstrap/dist/css/bootstrap.css'
import { VIDEOS_ENDPOINT } from 'constants.js'
import { Slider } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { injectIntl, FormattedMessage } from 'react-intl'

const getJustify = textAlign => {
  switch (textAlign) {
    case 'left':
      return 'flex-start'
    case 'center':
      return 'center'
    case 'right':
      return 'flex-end'
  }
}

const pixelsToViewportWidth = px => {
  if (px) {
    const pixels = px.toString().includes('px') ? px : px + 'px'

    switch (pixels) {
      case '28px':
        return '1.1vw'
      case '24px':
        return '0.9vw'
      case '20px':
        return '0.85vw'
      case '16px':
        return '0.58vw'
      case '12px':
        return '0.47vw'
      default:
        return '0.85vw'
    }
  } else return '0.85vw'
}

const getVerticalAlign = align => {
  const upStyles = {
    top: '0',
    height: '100%',
  }
  const downStyles = {
    bottom: '15px',
  }

  return align === 'up' ? upStyles : downStyles
}

const VideoPlayer = injectIntl(
  ({
    duration,
    video,
    formatting,
    transcription,
    player,
    played,
    playing,
    setPlaying,
    handleUndo,
    handleRedo,
    onReady,
    updateVideoTime,
    onReplace,
    onBlockChange,
    intl,
  }) => {
    const [currentBlock, setCurrentBlock] = useState(null)
    const [playbackRate, setPlaybackRate] = useState(1)
    const playerSubtitles = useRef(null)

    useEffect(() => {
      document.querySelector('body').style.overflow = 'hidden'

      return () => (document.querySelector('body').style.overflow = 'auto')
    }, [])

    useEffect(() => {
      const currBlock = transcription.find(
        el => el.start <= played && played <= el.end
      )
      if (currBlock !== currentBlock) {
        setCurrentBlock(currBlock)
      }
    }, [transcription])

    const onProgress = data => {
      updateVideoTime(data.playedSeconds, 'progress')

      const highlightedIndex = transcription.findIndex(
        el => el.start <= played && played <= el.end
      )

      const newBlock = transcription[highlightedIndex]

      setCurrentBlock(newBlock)

      if (!isEmpty(newBlock)) {
        onBlockChange(highlightedIndex)
      }
    }

    const getTopBottomStyles = bottom => {
      if (formatting.centered)
        return {
          bottom: 0,
        }

      if (bottom < 50) {
        return {
          bottom: bottom + '%',
        }
      } else {
        return {
          top: 100 - bottom + '%',
        }
      }
    }

    return (
      <>
        <div className="editor-container-block">
          <div className="player-wrapper">
            <ReactPlayer
              url={`${VIDEOS_ENDPOINT}/${video}`}
              className="react-player"
              progressInterval={100}
              onProgress={onProgress}
              playing={playing}
              playbackRate={playbackRate}
              ref={player}
              onReady={onReady}
            />

            <div
              className="player-subtitles"
              ref={playerSubtitles}
              style={{
                justifyContent: getJustify(formatting.textAlign),
                ...getVerticalAlign(formatting.verticalAlign),
                ...getTopBottomStyles(formatting.bottom),
                height: formatting.centered ? '100%' : 'auto',
                display: formatting.centered ? 'flex' : 'block',
                alignItems: formatting.centered ? 'center' : 'unset',
              }}
            >
              <p
                className={`${
                  formatting.textSelection.type === 'box' ? 'box-selection' : ''
                }`}
                style={{
                  margin: '0',
                  backgroundColor:
                    formatting.textSelection.type === 'box'
                      ? formatting.textSelection.background
                      : '',
                  height: 'max-content',
                }}
              >
                {currentBlock?.segment.split('\n').map(line => {
                  return (
                    <div
                      style={{
                        ...formatting,
                        fontSize: pixelsToViewportWidth(formatting.fontSize),
                      }}
                    >
                      <span
                        style={{
                          display: 'inline-block',
                          backgroundColor:
                            formatting.textSelection.type === 'wrap'
                              ? formatting.textSelection.background
                              : '',
                          WebkitTextStrokeWidth:
                            formatting.textSelection.type === 'outline'
                              ? '1px'
                              : '0',
                          WebkitTextStrokeColor:
                            formatting.textSelection.background,
                        }}
                      >
                        {line}
                      </span>
                    </div>
                  )
                })}
              </p>
            </div>
          </div>

          <div className="player-controls-custom">
            <>
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  fontSize: '14px',
                  justifyContent: 'flex-end',
                  marginTop: '15px',
                }}
              >
                <span style={{ textAlign: 'right' }}></span>
              </div>

              <Slider
                className="control-input-video"
                min={0}
                max={1}
                step={0.015}
                defaultValue={0}
                value={played / duration}
                onChange={(e, newValue) => {
                  updateVideoTime(newValue * player.current.getDuration())
                }}
              />
            </>

            <div className="control-buttons">
              <div className="playstop-ctrls">
                <button
                  title={intl.formatMessage({
                    id: 'VIDEO.SCROLL.MINUS.10',
                  })}
                  className="ctrl-btn-trans"
                  onClick={() => {
                    updateVideoTime(played - 10)
                  }}
                >
                  <SVG src={toAbsoluteUrl('/media/svg/editor/prev10.svg')} />
                </button>
                {playing ? (
                  <button
                    title={intl.formatMessage({
                      id: 'VIDEO.PAUSE',
                    })}
                    className="ctrl-btn-playstop"
                    onClick={() => {
                      setPlaying(false)
                    }}
                  >
                    <SVG
                      className="green-hover-icon"
                      src={toAbsoluteUrl('/media/svg/icons/Media/Pause.svg')}
                    />
                  </button>
                ) : (
                  <button
                    title={intl.formatMessage({
                      id: 'VIDEO.PLAY',
                    })}
                    className="ctrl-btn-playstop"
                    onClick={() => {
                      setPlaying(true)
                    }}
                  >
                    <SVG
                      src={toAbsoluteUrl('/media/svg/icons/Media/Play.svg')}
                    />
                  </button>
                )}

                <button
                  title={intl.formatMessage({
                    id: 'VIDEO.SCROLL.PLUS.10',
                  })}
                  className="ctrl-btn-trans"
                  onClick={() => {
                    updateVideoTime(played + 10)
                  }}
                >
                  <SVG src={toAbsoluteUrl('/media/svg/editor/next10.svg')} />
                </button>
              </div>

              <div className="control-speed-time-indicator">
                <span
                  style={{
                    fontWeight: '400',
                    color: '#5B5B5B',
                    fontSize: '110%',
                    position: 'relative',
                  }}
                >
                  {played >= 0 ? displayHMSM(played) : '00:00:00.000'}
                  <span
                    style={{
                      color: 'gray',
                      fontSize: 10,
                    }}
                  >
                    {' '}
                    {displayHMSM(duration)}
                  </span>
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <span
                    style={{
                      fontSize: '90%',
                      color: '#95C952',
                      fontWeight: '500',
                      marginRight: '10px',
                      fontWeight: 300,
                    }}
                  >
                    <FormattedMessage id="VIDEO.SPEED" />
                  </span>

                  <select
                    style={{ fontSize: '75%' }}
                    title={intl.formatMessage({
                      id: 'VIDEO.SPEED',
                    })}
                    className="green-border-input"
                    value={playbackRate}
                    onChange={e => {
                      setPlaybackRate(e.target.value)
                    }}
                  >
                    <option value="0.6">0.6x</option>
                    <option value="0.8">0.8x</option>
                    <option value="1">1.0x</option>
                    <option value="1.2">1.2x</option>
                    <option value="1.4">1.4x</option>
                    <option value="1.6">1.6x</option>
                    <option value="1.8">1.8x</option>
                    <option value="2.0">2.0x</option>
                  </select>
                </div>
              </div>

              <div className="undo-redo-controls">
                <button className="ctrl-btn-trans" onClick={handleUndo}>
                  <SVG
                    style={{ margin: '0 auto' }}
                    src={toAbsoluteUrl('/media/svg/editor/undo.svg')}
                  />
                  <label>
                    <FormattedMessage id="GENERAL.UNDO" />
                  </label>
                </button>
                <button className="ctrl-btn-trans" onClick={handleRedo}>
                  <SVG
                    style={{ margin: '0 auto' }}
                    src={toAbsoluteUrl('/media/svg/editor/redo.svg')}
                  />
                  <label>
                    <FormattedMessage id="GENERAL.REPEAT" />
                  </label>
                </button>
                <button onClick={onReplace} className="ctrl-btn-trans">
                  <SVG
                    style={{ margin: '0 auto' }}
                    src={toAbsoluteUrl('/media/svg/editor/replace.svg')}
                  />
                  <label>
                    <FormattedMessage id="GENERAL.REPLACE" />
                  </label>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
)

export default VideoPlayer
