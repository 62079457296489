import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { Formik, Form, Field } from 'formik'
import {
  CustomizedSelect,
  CustomizedTextarea,
  CustomizedDatePicker,
} from 'app/components'
import { USERS_ENDPOINT } from 'constants.js'
import { useMutation, useQueryClient } from 'react-query'
import { updateTask } from 'utils/mutations'
import { notify } from 'app/components/notify'

const TaskModal = ({ show, handleClose, data }) => {
  const [corrector, setCorrector] = useState()
  const [selectedDate, setSelectedDate] = useState(new Date())

  const queryClient = useQueryClient()

  const formatName = obj => {
    return obj ? `${obj.firstName} ${obj.lastName}` : 'Brak'
  }

  const initialValues = {
    name: data?.name,
    description: data?.description,
    deadline: data?.deadline,
    corrector: data?.corrector,
    minuteRate: data?.minuteRate,
  }

  const selectOptions = {
    role: 'corrector',
    endpoint: USERS_ENDPOINT,
  }

  const taskMutation = useMutation(
    editedTask => updateTask(editedTask._id, editedTask.values, data.project),
    {
      onSuccess: () => {
        queryClient.invalidateQueries()
        handleClose()
        notify('success', 'Zadanie zostało edytowane.')
      },
    }
  )

  return (
    <>
      <Modal className="custom-modal" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edycja zadania</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              taskMutation.mutate({ values, _id: data._id })
            }}
          >
            {({
              isSubmitting,
              dirty,
              values,
              setFieldValue,
              getFieldProps,
            }) => (
              <Form className="form">
                <label className="col-form-label" htmlFor="name">
                  Nazwa
                </label>
                <Field
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  name="name"
                />

                <label className="col-form-label" htmlFor="description">
                  Opis
                </label>
                <CustomizedTextarea
                  type="text"
                  {...getFieldProps('description')}
                />
                {/* <label className="col-form-label" htmlFor="deadline">
                  Deadline
                </label>

                <CustomizedDatePicker
                  name="deadline"
                  selected={selectedDate}
                  onChange={date => {
                    setSelectedDate(date)
                    setFieldValue('deadline', date.toISOString())
                  }}
                /> */}
                <label className="col-form-label" htmlFor="deadline">
                  Przypisany korektor
                </label>
                <CustomizedSelect
                  {...selectOptions}
                  handleSelect={corrector => {
                    setFieldValue('corrector', corrector._id)
                    setCorrector(corrector)
                  }}
                  placeholder={
                    corrector
                      ? formatName(corrector)
                      : formatName(values.corrector)
                  }
                  className="btn-light form-control form-control-solid form-control-lg custom-select-lazy"
                />

                <div className="col-4 pl-0">
                  <label className="col-form-label " htmlFor="minuteRate">
                    Stawka godzinowa
                  </label>
                  <div className="input-group">
                    <Field
                      className="form-control form-control-lg form-control-solid"
                      type="number"
                      name="minuteRate"
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        style={{
                          background: 'rgb(228,230,239)',
                          border: 0,
                          color: 'rgb(87, 87, 87)',
                        }}
                      >
                        PLN
                      </span>
                    </div>
                  </div>
                </div>

                <button
                  className="btn btn-secondary font-weight-bold mt-4 ml-4 float-right"
                  type="button"
                  onClick={handleClose}
                >
                  Anuluj
                </button>
                <button
                  className="btn btn-primary font-weight-bold mt-4 float-right"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Zapisz
                </button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  )
}

export default TaskModal
