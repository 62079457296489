import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { FormattedMessage, injectIntl } from 'react-intl'
import { USERS_ENDPOINT } from '../../../constants'
import { post } from '../../../utils/api'
import Alert from '../../modules/Auth/pages/Alerts/Alert'
import {
  emailValidator,
  intigerValidator,
  radioValidator,
} from 'utils/validators'

const roles = ['client', 'individual-client', 'corrector', 'admin']

const AddUser = ({ intl, ...props }) => {
  const [loading, setLoading] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertType, setAlertType] = useState('')

  const formValidation = Yup.object().shape({
    email: emailValidator(intl),
    role: radioValidator(intl, [
      'client',
      'individual-client',
      'corrector',
      'admin',
    ]),
    minutes: intigerValidator(intl),
  })

  const addUser = async (values, { setStatus, setSubmitting }) => {
    setLoading(true)

    const data = {
      ...values,
      minutes: undefined,
      availableSeconds: values.minutes * 60,
    }

    try {
      await post(USERS_ENDPOINT, data)
      setAlertMessage(<FormattedMessage id="USERS.ADD.FORM.SUCCESS" />)
      setAlertType('success')
      formik.resetForm()
    } catch (err) {
      setAlertMessage(
        intl.formatMessage(
          { id: err?.response?.data?.id },
          { document: 'Użytkownik', value: 'adresie e-mail' }
        )
      )

      setAlertType('error')
    }

    setLoading(false)
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      role: 'client',
      minutes: 0,
    },
    validationSchema: formValidation,
    onSubmit: addUser,
  })

  return (
    <>
      <Alert message={alertMessage} type={alertType} />
      <div className="card card-custom">
        <div className="card-header">
          <h3 className="card-title">
            <FormattedMessage id="USERS.ADD.TITLE" />
          </h3>
        </div>
        <form id="add_client" onSubmit={formik.handleSubmit} className="form">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-4">
                <label>
                  <FormattedMessage id="USERS.ADD.FIELD.EMAIL" />
                </label>
                <input
                  className={`form-control ${
                    formik.touched.email && formik.errors.email
                      ? 'is-invalid invalid-input'
                      : ''
                  }`}
                  id="name"
                  type="email"
                  placeholder="Wpisz e-mail"
                  {...formik.getFieldProps('email')}
                />

                {formik.touched.email && formik.errors.email ? (
                  <div className="invalid-input-label">
                    {formik.errors.email}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col">
                <label>
                  <FormattedMessage id="USERS.ADD.FIELD.ROLE" />
                </label>
                <div className="radio-inline">
                  {roles.map((el, i) => (
                    <label className="radio radio-solid" key={i}>
                      <input
                        type="radio"
                        name={el}
                        checked={formik.values.role === el}
                        value={el}
                        onChange={() => formik.setFieldValue('role', el)}
                      />
                      <span></span>
                      <FormattedMessage
                        id={`USERS.ADD.ROLE.${el.toUpperCase()}`}
                      />
                    </label>
                  ))}
                </div>
              </div>
            </div>
            {formik.values.role === 'individual-client' && (
              <div className="form-group row">
                <div className="col-lg-4">
                  <label>
                    <FormattedMessage id="USERS.ADD.FIELD.MINUTES" />
                  </label>
                  <input
                    className={`form-control ${
                      formik.touched.minutes && formik.errors.minutes
                        ? 'is-invalid invalid-input'
                        : ''
                    }`}
                    id="minutes"
                    type="number"
                    placeholder="Wpisz liczbę minut"
                    {...formik.getFieldProps('minutes')}
                  />

                  {formik.touched.minutes && formik.errors.minutes ? (
                    <div className="invalid-input-label">
                      {formik.errors.minutes}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="card-footer">
            <div className="row">
              <div className="col-lg-12">
                <button
                  type="submit"
                  className="btn show-notify btn-primary mr-2"
                  disabled={formik.isSubmitting}
                >
                  <span>
                    {' '}
                    <FormattedMessage id="USERS.ADD.FIELD.SUBMIT" />
                  </span>
                  {loading && (
                    <span className="ml-3 mr-5 spinner spinner-white"></span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default injectIntl(AddUser)
