/* eslint-disable */

import React from 'react'

const FragmentContainer = ({ children, bounds, index, number }) => {
  const { left, right } = bounds

  return (
    <>
      <div
        className="fragment-container"
        style={{
          width: right - left,
          left: left,
          height: '100%',
          position: 'absolute',
        }}
      >
        {children}
      </div>
    </>
  )
}

export default FragmentContainer
