import React from 'react'

const PageLoader = () => {
  return (
    <div
      className="page-custom-loader"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <div className="spinner spinner-track spinner-primary spinner-lg mr-15"></div>
    </div>
  )
}

export default PageLoader
