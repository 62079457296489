import { useAuth } from 'contexts/authContext'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const Content = ({
  activeStep,
  setActiveStep,
  handleBack,
  handleNext,
  getStepContent,
  steps,
  formik,
  loading,
  formSubmitted,
  progress,
  totalSize,
  showProgress,
  video,
}) => {
  const {
    state: {
      user: { role },
    },
  } = useAuth()

  const nextDisabled =
    role === 'client'
      ? false
      : activeStep === 1 && !(!!video || !!formik.values.videoUrl)

  return (
    <div className="col-xl-12 col-xxl-7">
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {getStepContent(activeStep)}

        <div
          className={`d-flex justify-content-between border-top mt-5 pt-10 ${
            showProgress ? 'custom-disabled' : ''
          }`}
        >
          <button
            disabled={activeStep === 0}
            onClick={handleBack}
            type="button"
            className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
          >
            <FormattedMessage id="PROJECT.ADD.BACK" />
          </button>
          <div>
            {' '}
            {role !== 'client' && (
              <button
                onClick={() => {
                  setActiveStep(2)
                }}
                type="button"
                className="btn btn-light-secondary font-weight-bolder text-uppercase px-9 py-4"
                style={{
                  color: 'gray',
                  display: activeStep === 1 ? 'initial' : 'none',
                  marginRight: '1em',
                }}
              >
                <FormattedMessage id="PROJECT.ADD.SKIP" />
              </button>
            )}
            {!nextDisabled && (
              <button
                style={{
                  display: activeStep === steps.length - 1 ? 'none' : 'initial',
                }}
                onClick={handleNext}
                type="button"
                className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                disabled={
                  role === 'client' &&
                  activeStep === 1 &&
                  !(!!video || !!formik.values.videoUrl)
                }
              >
                <FormattedMessage id="PROJECT.ADD.NEXT" />
              </button>
            )}
          </div>

          <button
            style={{
              display: activeStep === steps.length - 1 ? 'initial' : 'none',
            }}
            type="submit"
            className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
            disabled={
              formSubmitted ||
              formik.isSubmitting ||
              !(formik.isValid && formik.dirty)
            }
          >
            <FormattedMessage id="PROJECT.ADD.SAVE" />
            {loading && (
              <span className="ml-3 mr-5 spinner spinner-white"></span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default Content
