import React from 'react'
import { displayHMS } from './index'
import { injectIntl, FormattedMessage } from 'react-intl'

const FormattedSentence = ({ sentence, keyWord }) => {
  return (
    <span>
      {sentence
        .split(' ')
        .map(word =>
          word.includes(keyWord) && keyWord !== '' ? (
            <span className="font-weight-bolder">{word} </span>
          ) : (
            <span className="font-weight-light">{word} </span>
          )
        )}
    </span>
  )
}

const WordItem = ({ value, keyWord, replacingWord, onSelect }) => {
  return (
    <div className="words-item">
      <div className="timestamps-icons">
        {displayHMS(value.start)}{' '}
        <i
          onClick={() => {
            if (typeof onSelect === 'function') onSelect(value)
          }}
          className="fas fa-reply mr-2"
        />
      </div>

      <div className="mr-6 holder">
        <FormattedSentence sentence={value.segment} keyWord={keyWord} />
      </div>
      <div className="holder">
        <span>
          <FormattedSentence
            sentence={value.segment.replace(keyWord, replacingWord)}
            keyWord={replacingWord}
          />
        </span>
      </div>
    </div>
  )
}

const WordsContainer = injectIntl(
  ({ items, keyWord, replacingWord, onSelect }) => {
    return (
      <div className="words-container">
        {items.length > 0 && keyWord !== '' ? (
          items.map(item => (
            <WordItem
              value={item}
              keyWord={keyWord}
              replacingWord={replacingWord}
              onSelect={onSelect}
            />
          ))
        ) : (
          <div className="p-4">
            <FormattedMessage id="GENERAL.NO.RESULTS" />
          </div>
        )}
      </div>
    )
  }
)

export default WordsContainer
