import React, { useEffect, useState } from 'react'
import Grow from '@material-ui/core/Grow'
import { FormattedMessage, injectIntl } from 'react-intl'

import { formatDate } from 'utils/helpers'
import { statusesConstants } from 'constants.js'

const StatusNode = ({ color, background, date, label, timeout }) => {
  const [animation, setAnimation] = useState(false)

  useEffect(() => {
    setAnimation(true)
  }, [])

  return (
    <Grow
      in={animation}
      style={{ transformOrigin: '0 0 0' }}
      {...(animation ? { timeout: timeout } : {})}
    >
      <div className="custom-timeline timeline timeline-6 mt-3">
        <div className="timeline-item align-items-start">
          <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
            {formatDate(date)}
          </div>
          <div className="timeline-badge">
            <i
              className="fa fa-genderless icon-xl"
              style={{
                color: background,
              }}
            ></i>
          </div>
          <div
            className="font-weight-normal font-size-lg timeline-content text-muted pl-3"
            style={{
              minHeight: '43px',
            }}
          >
            <span
              className="font-weight-bold"
              style={{
                color: background,
              }}
            >
              {label}
            </span>
          </div>
        </div>
      </div>
    </Grow>
  )
}

const StatusHistory = ({ statuses, intl, isClient }) => {
  let timeout = 0

  return (
    <div className="card card-custom card-stretch gutter-b">
      <div className="card-body">
        <h3 className="card-title font-weight-bolder">Historia</h3>

        <div className="card-body pt-4">
          {statuses
            .filter(
              el => !(isClient && ['ready', 'rejected'].includes(el.status))
            )
            .map(el => {
              timeout = timeout + 325

              const status =
                statusesConstants[el.status] || statusesConstants.new

              return (
                <StatusNode
                  label={intl.formatMessage({ id: status.intl })}
                  background={status.bgColor}
                  color={status.color}
                  date={el.createdAt}
                  timeout={timeout}
                />
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default injectIntl(StatusHistory)
