import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'

import { CustomizedDatePicker } from 'app/components'

import RaportCalendar from 'app/pages/dashboards/RaportCalendar'

import { getCorrectorRaport } from 'utils/queries'

import { notify } from 'app/components/notify'

export const RaportModal = ({ user, modal, setModal }) => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState()
  const [generating, setGenerating] = useState(false)

  const generateRaport = async () => {
    if (endDate) {
      setGenerating(true)

      try {
        await getCorrectorRaport(
          startDate,
          endDate,
          user._id,
          `Raport_${user.firstName}_${user.lastName}.pdf`
        )
      } catch (err) {
        notify('error', 'Podczas generowania raportu wystąpił błąd')
      }

      setGenerating(false)
    }
  }

  const show = modal.type === 'raport'

  return (
    <Modal
      show={show}
      size="lg"
      centered
      onHide={() => {
        setModal(m => ({ ...m, type: 'hidden' }))
      }}
    >
      <Modal.Body>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 className="card-title font-weight-bolder">Raporty</h3>
          <div className="card-toolbar"></div>
        </div>

        <div className="card-body pt-0 d-flex flex-column">
          <div className="pt-2">
            <p className="text-center font-weight-normal font-size-lg pb-2">
              <div className="d-flex" style={{ flexFlow: 'column' }}>
                <i className="flaticon2-pie-chart-4 display-2 text-muted font-weight-bold"></i>
                Wygeneruj raport
                <div className="datepicker-range mt-10">
                  <div
                    className="row"
                    style={{
                      width: '100%',
                    }}
                  >
                    <div className="input-group date col-6">
                      <div style={{ textAlign: 'left', paddingLeft: '13px' }}>
                        od:
                      </div>
                      <CustomizedDatePicker
                        selected={startDate}
                        onChange={date => {
                          //setFieldValue('deadline', date.toISOString())

                          setStartDate(date)
                        }}
                        customInput={<RaportCalendar />}
                      />
                    </div>

                    <div className="input-group date col-6">
                      <div style={{ textAlign: 'left', paddingLeft: '13px' }}>
                        do:
                      </div>
                      <CustomizedDatePicker
                        selected={endDate}
                        onChange={date => {
                          if (date.getTime() >= startDate.getTime())
                            setEndDate(date)
                          else setEndDate(startDate)
                        }}
                        customInput={<RaportCalendar />}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </p>
            <button
              onClick={generateRaport}
              className="btn btn-success ml-auto btn-shadow-hover font-weight-bolder  py-3 position-relative"
              style={{
                pointerEvents: generating ? 'none' : 'unset',
              }}
            >
              <span
                style={{
                  opacity: generating ? '0.25' : '1',
                  transition: '0.27s opacity',
                }}
              >
                Generuj raport
              </span>
              <div
                className="spinner spinner-white position-absolute"
                style={{
                  top: '50%',
                  left: '50%',
                  opacity: generating ? '1' : '0',
                  transition: '0.35s opacity',
                }}
              ></div>
            </button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  )
}
