import React from 'react'
import { Details } from './'
import CorrectorDetails from './CorrectorDetails'
import Tasks from './tasks/Tasks'

const PanelContainer = ({ activeTab, formik, data, showModal, project }) => {
  const getStepContent = step => {
    switch (step) {
      case 'details':
        return <Details formik={formik} data={data} showModal={showModal} />
      case 'corrector':
        return (
          <CorrectorDetails formik={formik} data={data} showModal={showModal} />
        )
      default:
        return ''
    }
  }

  return <div className="card-body">{getStepContent(activeTab)} </div>
}

export default PanelContainer
