import { useRef, useEffect } from 'react'

import Editor from 'app/pages/editor/Editor'
import VideoPlayer from 'app/pages/editor/VideoPlayer'
import BottomEditor from 'app/pages/editor/BottomEditor'

const getTranslateX = element => {
  const style = window.getComputedStyle(element)
  const matrix = new DOMMatrixReadOnly(style.transform)
  return matrix.m41
}

const PIXEL_SECOND_RATIO = 100

const minTwoDigits = n => {
  return (n < 10 ? '0' : '') + n
}

const resizingConfig = {
  bottom: false,
  bottomLeft: false,
  bottomRight: false,
  left: true,
  right: true,
  top: false,
  topLeft: false,
  topRight: false,
}

const displayHMSM = seconds => {
  const format = val => `0${Math.floor(val)}`.slice(-2)
  const hours = seconds / 3600
  const minutes = (seconds % 3600) / 60

  const output = [hours, minutes].map(format)
  output.push(minTwoDigits((seconds % 60).toFixed(3)).toString())

  return output.join(':')
}

const displayHMS = seconds => {
  const format = val => `0${Math.floor(val)}`.slice(-2)
  const hours = seconds / 3600
  const minutes = (seconds % 3600) / 60

  return [hours, minutes, seconds % 60].map(format).join(':')
}

const convertHMSMtoSeconds = str => {
  const timestamp = str.split(':')
  const hours = parseInt(timestamp[0]) * 60 * 60
  const minutes = parseInt(timestamp[1]) * 60
  const seconds = parseFloat(timestamp[2])

  return [hours, minutes, seconds].reduce((x, y) => x + y)
}

const useHorizontalScroll = () => {
  const elRef = useRef()
  useEffect(() => {
    const el = elRef.current
    if (el) {
      const onWheel = e => {
        if (e.deltaY == 0) return
        e.preventDefault()
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          behavior: 'smooth',
        })
      }
      el.addEventListener('wheel', onWheel)
      return () => el.removeEventListener('wheel', onWheel)
    }
  }, [])
  return elRef
}

const parseFormatting = formatting => {
  return {
    fontFamily: formatting.fontFamily,
    fontSize: formatting.fontSize,
    fontWeight: formatting.fontWeight,
    fontStyle: formatting.fontStyle,
    textAlign: formatting.textAlign,
    color: formatting.color,
    bottom: formatting.bottom,
    centered: formatting.centered,
    textSelection: {
      type: formatting.selectionType,
      background: formatting.selectionBackground,
    },
  }
}

const parseFormattingForBackend = formatting => {
  return {
    fontFamily: formatting.fontFamily,
    fontSize: parseInt(formatting.fontSize),
    fontWeight: formatting.fontWeight,
    fontStyle: formatting.fontStyle,
    textAlign: formatting.textAlign,
    color: formatting.color,
    bottom: formatting.bottom,
    selectionType: formatting.textSelection.type,
    selectionBackground: formatting.textSelection.background,
    centered: formatting.centered,
  }
}

export {
  Editor,
  VideoPlayer,
  BottomEditor,
  displayHMSM,
  displayHMS,
  resizingConfig,
  PIXEL_SECOND_RATIO,
  getTranslateX,
  minTwoDigits,
  useHorizontalScroll,
  convertHMSMtoSeconds,
  parseFormatting,
  parseFormattingForBackend,
}
