import React, { useEffect, useState } from 'react'
import { Stepper, Step, StepLabel, Button } from '@material-ui/core'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  Informations,
  Summary,
  Upload,
  Content,
} from 'app/pages/projects/stepper/steps'
import {
  ColorlibConnector,
  useStyles,
  ColorlibStepIcon,
  scrollUp,
} from 'app/pages/projects/stepper/utils'
import { post, get } from 'utils/api'
import { PROJECTS_ENDPOINT } from 'constants.js'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import { ValidationErrorMessage } from 'app/components'
import { startUpload } from 'utils/upload'
import {
  dateValidator,
  intigerValidator,
  minMaxReqValidator,
  minMaxValidator,
  requiredValidator,
  youtubeValidator,
} from 'utils/validators'
import { uploadYoutube } from 'utils/mutations'
import { getThumb } from 'utils/helpers'
import { useAuth } from 'contexts/authContext'

const CustomizedSteppers = ({ intl, location, history, setMessage }) => {
  const classes = useStyles()
  const steps = getSteps()
  const [selectedClient, setSelectedClient] = useState()
  const [selectedCorrector, setSelectedCorrector] = useState()
  const [uploadSkipped, setUploadSkipped] = useState(false)
  const [totalSize, setTotalSize] = useState(0)

  const [videoFile, setVideoFile] = useState()

  const {
    state: {
      user: { role, _id },
    },
  } = useAuth()

  const isClient = role === 'client'
  const isIndividualClient = role === 'individual-client'

  useEffect(() => {
    setTotalSize(videoFile?.size)
  }, [videoFile])

  function getSteps() {
    return [
      '1. ' + intl.formatMessage({ id: 'PROJECT.ADD.INFORMATIONS' }),
      '2. ' + intl.formatMessage({ id: 'PROJECT.ADD.UPLOAD' }),
      '3. ' + intl.formatMessage({ id: 'PROJECT.ADD.SUMMARY' }),
    ]
  }

  const [activeStep, setActiveStep] = React.useState(0)
  const [loading, setLoading] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)

  const handleSubmit = async (values, { setStatus, setSubmitting }) => {
    setLoading(true)

    const valuesCopy = { ...values }

    if (valuesCopy.corrector === '') {
      delete valuesCopy.corrector
    }

    try {
      const body = new FormData()

      if (!valuesCopy.photo && valuesCopy.videoUrl) {
        try {
          const thumb = await fetch(getThumb(valuesCopy.videoUrl), {
            // headers: {
            //   'Access-Control-Allow-Origin': '*',
            // },
          })
          valuesCopy.photo = await thumb.blob()
        } catch (err) {
          // console.log(err)
        }
      }

      for (const key in valuesCopy) {
        body.append(
          key,
          typeof valuesCopy[key] !== 'string' && key !== 'photo'
            ? JSON.stringify(valuesCopy[key])
            : valuesCopy[key]
        )
      }

      const res = await post(PROJECTS_ENDPOINT, body)

      const projId = res.data.data._id

      if (valuesCopy.videoUrl) {
        uploadYoutube(projId, valuesCopy.videoUrl)
      } else if (videoFile) {
        startUpload([videoFile], projId)
      }

      setMessage(
        intl.formatMessage({ id: 'PROJECT.ADD.SUCCESSFUL' }),
        'success'
      )
      setFormSubmitted(true)
      history.push({
        pathname: `/projects/${res?.data?.data?._id}`,
        state: { uploading: !!videoFile || !!valuesCopy.videoUrl },
      })
    } catch (err) {
      //setMessage(intl.formatMessage({ id: err?.id }), 'error')
      setMessage('Wystąpił błąd podczas dodawania projektu.', 'error')
    }

    setLoading(false)
  }

  useEffect(() => {
    scrollUp()
  }, [activeStep])

  const handleNext = async () => {
    const validateObj = await formik.validateForm()

    if (_.isEmpty(validateObj))
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    else formik.submitForm()
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const ProjectSchema = Yup.object().shape({
    name: minMaxReqValidator(intl, 2, 100),
    description: minMaxValidator(intl, 0, 250),
    date: dateValidator(intl),
    client: requiredValidator(intl),
    // corrector: requiredValidator(intl),
    hourlyRate: intigerValidator(intl),
    videoUrl: youtubeValidator(intl),
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      deadline: '',
      client: '',
      corrector: '',
      videoUrl: '',
      hourlyRate: 0,
      informClient: false,
    },
    onSubmit: handleSubmit,
    validationSchema: ProjectSchema,
    enableReinitialize: true,
  })

  useEffect(() => {
    if (isClient) {
      formik.setFieldValue('client', _id)
      formik.setFieldValue('corrector', undefined)
    } else if (isIndividualClient) {
      formik.setFieldValue('client', _id)
      formik.setFieldValue('corrector', undefined)
    }
  }, [])

  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <Informations
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
            selectedCorrector={selectedCorrector}
            setSelectedCorrector={setSelectedCorrector}
            formik={formik}
            role={role}
          />
        )
      case 1:
        return (
          <Upload
            formik={formik}
            uploadSkipped={uploadSkipped}
            setUploadSkipped={setUploadSkipped}
            setVideoFile={setVideoFile}
            setTotalSize={setTotalSize}
            videoFile={videoFile}
            role={role}
          />
        )
      case 2:
        return <Summary formik={formik} video={videoFile} role={role} />
      default:
        return 'Unknown step'
    }
  }

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map(label => (
          <Step
            onClick={() => {
              const labelStep = parseInt(label[0]) - 1
              setActiveStep(labelStep)
            }}
            key={label}
          >
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
        <Content
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleBack={handleBack}
          handleNext={handleNext}
          getStepContent={getStepContent}
          steps={steps}
          formik={formik}
          loading={loading}
          formSubmitted={formSubmitted}
          video={videoFile}
        />
      </div>
    </div>
  )
}

export default injectIntl(withRouter(CustomizedSteppers))
