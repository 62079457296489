import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useMutation, useQueryClient } from 'react-query'

import { blockUser, unblockUser, archiveUser } from 'utils/mutations'
import { notify } from 'app/components/notify'

export const UserActionsModal = injectIntl(({ modal, setModal, id, intl }) => {
  const queryClient = useQueryClient()

  const setSuccess = type => {
    queryClient.invalidateQueries('users-table')
    notify('success', `Użytkownik został ${type}`)
  }

  const onError = () => notify('error', 'Coś poszło nie tak')

  const blockMutation = useMutation(blockUser, {
    onSuccess: () => {
      setSuccess('zablokowany')
    },
    onError,
  })

  const unblockMutation = useMutation(unblockUser, {
    onSuccess: () => {
      setSuccess('odblokowany')
    },
    onError,
  })

  const archiveMutation = useMutation(archiveUser, {
    onSuccess: () => {
      setSuccess('usunięty')
    },
    onError,
  })

  const show =
    modal.type === 'block' ||
    modal.type === 'unblock' ||
    modal.type === 'delete'

  return (
    <Modal size="md" show={show}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage
            id={`USERS.LIST.${
              modal.type === 'block'
                ? 'BLOCK'
                : modal.type === 'unblock'
                ? 'UNBLOCK'
                : 'DELETE'
            }.TITLE`}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage
          id={`USERS.LIST.${
            modal.type === 'block'
              ? 'BLOCK'
              : modal.type === 'unblock'
              ? 'UNBLOCK'
              : 'DELETE'
          }.TEXT`}
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          disabled={modal.loading}
          className={`btn show-notify btn-${
            modal.type === 'block' || modal.type === 'delete'
              ? 'danger'
              : 'primary'
          } mr-2`}
          onClick={async () => {
            setModal(m => ({ ...m, loading: true }))
            try {
              if (modal.type === 'block') {
                await blockMutation.mutateAsync(id)
              } else if (modal.type === 'unblock') {
                await unblockMutation.mutateAsync(id)
              } else {
                await archiveMutation.mutateAsync(id)
              }
            } catch (err) {
              notify(
                'error',
                intl.formatMessage({
                  id: err?.response?.data?.id || 'API.ERROR.GENERAL',
                })
              )
            }
            setModal(m => ({ ...m, type: 'hidden', loading: false }))
          }}
        >
          <FormattedMessage
            id={`USERS.LIST.${
              modal.type === 'block'
                ? 'BLOCK'
                : modal.type === 'unblock'
                ? 'UNBLOCK'
                : 'DELETE'
            }.CONFIRM`}
          />
          {modal.loading && (
            <span className="ml-3 mr-5 spinner spinner-white"></span>
          )}
        </button>
        <button
          disabled={modal.loading}
          type="button"
          className="btn btn-secondary font-weight-bold mr-2"
          onClick={() => {
            setModal(m => ({ ...m, type: 'hidden' }))
          }}
        >
          <FormattedMessage id="USERS.LIST.MODAL.CANCEL" />
        </button>
      </Modal.Footer>
    </Modal>
  )
})
