import { apiAddress } from 'constants.js'
import { notify } from 'app/components/notify'
import { localJWT } from 'utils/jwt'
const progressBox = document.querySelector('#progress-box')

export const ENDPOINTS = {
  UPLOAD: `${apiAddress}/projects/ASDAS/upload`,
  UPLOAD_STATUS: `${apiAddress}/projects/ASDAD/upload-status`,
  UPLOAD_REQUEST: `${apiAddress}/projects/ASDAS/upload-request`,
}

export const uploadFiles = (() => {
  const fileRequests = new WeakMap()

  const defaultOptions = {
    url: ENDPOINTS.UPLOAD,
    startingByte: 0,
    fileId: '',
    onAbort() {},
    onProgress() {},
    onError() {},
    onComplete() {},
  }

  const uploadFileChunks = (file, options) => {
    const formData = new FormData()
    const req = new XMLHttpRequest()
    const chunk = file.slice(options.startingByte)

    formData.append('chunk', chunk, file.name)
    formData.append('fileId', options.data.fileId)

    req.open('POST', options.url, true)

    req.setRequestHeader(
      'Content-Range',
      `bytes=${options.startingByte}-${options.startingByte + chunk.size}/${
        file.size
      }`
    )
    req.setRequestHeader('X-File-Id', options.data.fileId)
    req.setRequestHeader('Authorization', `Bearer ${localJWT.read()}`)

    req.onload = e => {
      // it is possible for load to be called when the request status is not 200
      // this will treat 200 only as success and everything else as failure

      if (req.status === 200) {
        options.onComplete(e, file)
      } else {
        options.onError(e, file)
      }
    }

    req.upload.onprogress = e => {
      const loaded = options.startingByte + e.loaded

      createProgressToast((loaded * 100) / file.size)

      options.onProgress(
        {
          ...e,
          loaded,
          total: file.size,
          percentage: (loaded * 100) / file.size,
        },
        file
      )
    }

    req.ontimeout = e => options.onError(e, file)

    req.onabort = e => options.onAbort(e, file)

    req.onerror = e => options.onError(e, file)

    fileRequests.get(file).request = req

    req.send(formData)
  }

  const uploadFile = (file, options) => {
    return fetch(ENDPOINTS.UPLOAD_REQUEST, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localJWT.read()}`,
      },
      body: JSON.stringify({
        fileName: file.name,
      }),
    })
      .then(res => res.json())
      .then(res => {
        options = { ...options, ...res }
        fileRequests.set(file, { request: null, options })

        uploadFileChunks(file, options)
      })
      .catch(e => {
        options.onError({ ...e, file })
      })
  }

  const abortFileUpload = async file => {
    const fileReq = fileRequests.get(file)

    if (fileReq && fileReq.request) {
      fileReq.request.abort()
      return true
    }

    return false
  }

  const retryFileUpload = file => {
    const fileReq = fileRequests.get(file)

    if (fileReq) {
      // try to get the status just in case it failed mid upload
      return fetch(
        `${ENDPOINTS.UPLOAD_STATUS}?fileName=${file.name}&fileId=${fileReq.options.fileId}`
      )
        .then(res => res.json())
        .then(res => {
          // if uploaded we continue
          uploadFileChunks(file, {
            ...fileReq.options,
            startingByte: Number(res.totalChunkUploaded),
          })
        })
        .catch(() => {
          // if never uploaded we start
          uploadFileChunks(file, fileReq.options)
        })
    }
  }

  const clearFileUpload = async file => {
    const fileReq = fileRequests.get(file)

    if (fileReq) {
      await abortFileUpload(file)
      fileRequests.delete(file)

      return true
    }

    return false
  }

  const resumeFileUpload = file => {
    const fileReq = fileRequests.get(file)

    if (fileReq) {
      return fetch(
        `${ENDPOINTS.UPLOAD_STATUS}?fileName=${file.name}&fileId=${fileReq.options.fileId}`
      )
        .then(res => res.json())
        .then(res => {
          uploadFileChunks(file, {
            ...fileReq.options,
            startingByte: Number(res.totalChunkUploaded),
          })
        })
        .catch(e => {
          fileReq.options.onError({ ...e, file })
        })
    }
  }

  return (files, options = defaultOptions) => {
    ;[...files].forEach(file =>
      uploadFile(file, {
        url: ENDPOINTS.UPLOAD,
        startingByte: 0,
        fileId: '',
        onAbort() {},
        onProgress() {},
        onError() {},
        onComplete() {},
      })
    )

    return {
      abortFileUpload,
      retryFileUpload,
      clearFileUpload,
      resumeFileUpload,
    }
  }
})()

export const uploadAndTrackFiles = (() => {
  const files = new Map()

  const FILE_STATUS = {
    PENDING: 'pending',
    UPLOADING: 'uploading',
    PAUSED: 'paused',
    COMPLETED: 'completed',
    FAILED: 'failed',
  }
  let uploader = null

  const updateProgressBox = () => {}

  const updateFileElement = fileObject => {
    const [
      {
        children: [
          {
            children: [status],
          },
          progressBar,
        ],
      }, // .file-details
      {
        children: [retryBtn, pauseBtn, resumeBtn, clearBtn],
      }, // .file-actions
    ] = fileObject.element.children

    requestAnimationFrame(() => {
      status.textContent =
        fileObject.status === FILE_STATUS.COMPLETED
          ? fileObject.status
          : `${Math.round(fileObject.percentage)}%`
      status.className = `status ${fileObject.status}`
      progressBar.style.width = fileObject.percentage + '%'
      progressBar.style.background =
        fileObject.status === FILE_STATUS.COMPLETED
          ? 'green'
          : fileObject.status === FILE_STATUS.FAILED
          ? 'red'
          : '#222'
      pauseBtn.style.display =
        fileObject.status === FILE_STATUS.UPLOADING ? 'inline-block' : 'none'
      retryBtn.style.display =
        fileObject.status === FILE_STATUS.FAILED ? 'inline-block' : 'none'
      resumeBtn.style.display =
        fileObject.status === FILE_STATUS.PAUSED ? 'inline-block' : 'none'
      clearBtn.style.display =
        fileObject.status === FILE_STATUS.COMPLETED ||
        fileObject.status === FILE_STATUS.PAUSED
          ? 'inline-block'
          : 'none'
      updateProgressBox()
    })
  }

  const setFileElement = file => {
    const extIndex = file.name.lastIndexOf('.')
    const fileElement = document.createElement('div')
    fileElement.className = 'file-progress'
    fileElement.innerHTML = `
              <div class="file-details" style="position: relative">
                  <p>
                      <span class="status">pending</span>
                      <span class="file-name">${file.name.substring(
                        0,
                        extIndex
                      )}</span>
                      <span class="file-ext">${file.name.substring(
                        extIndex
                      )}</span>
                  </p>
                  <div class="progress-bar" style="width: 0;"></div>
              </div>
              <div class="file-actions">
                  <button type="button" class="retry-btn" style="display: none">Retry</button>
                  <button type="button" class="cancel-btn" style="display: none">Pause</button>
                  <button type="button" class="resume-btn" style="display: none">Resume</button>
                  <button type="button" class="clear-btn" style="display: none">Clear</button>
              </div>
          `
    files.set(file, {
      element: fileElement,
      size: file.size,
      status: FILE_STATUS.PENDING,
      percentage: 0,
      uploadedChunkSize: 0,
    })

    const [
      _,
      {
        children: [retryBtn, pauseBtn, resumeBtn, clearBtn],
      },
    ] = fileElement.children

    clearBtn.addEventListener('click', () => {
      uploader.clearFileUpload(file)
      files.delete(file)
      fileElement.remove()
      updateProgressBox()
    })
    retryBtn.addEventListener('click', () => uploader.retryFileUpload(file))
    pauseBtn.addEventListener('click', () => uploader.abortFileUpload(file))
    resumeBtn.addEventListener('click', () => uploader.resumeFileUpload(file))
  }

  const onComplete = (e, file) => {
    const fileObj = files.get(file)

    document.querySelector('#progress-box').style.opacity = 0
    fileObj.status = FILE_STATUS.COMPLETED
    fileObj.percentage = 100

    updateFileElement(fileObj)
  }

  const onProgress = (e, file) => {
    const fileObj = files.get(file)

    fileObj.status = FILE_STATUS.UPLOADING
    fileObj.percentage = e.percentage
    fileObj.uploadedChunkSize = e.loaded

    updateFileElement(fileObj)
  }

  const onError = (e, file) => {
    const fileObj = files.get(file)

    fileObj.status = FILE_STATUS.FAILED
    fileObj.percentage = 100

    updateFileElement(fileObj)
  }

  const onAbort = (e, file) => {
    const fileObj = files.get(file)

    fileObj.status = FILE_STATUS.PAUSED

    updateFileElement(fileObj)
  }

  return (uploadedFiles, id) => {
    ;[...uploadedFiles].forEach(setFileElement)

    uploader = uploadFiles(uploadedFiles, id, {
      onProgress,
      onError,
      onAbort,
      onComplete,
    })
  }
})()

const createProgressToast = progress => {
  const p = Math.round(progress)
  document.querySelector('#progress-box').style.opacity = 1

  if (p > 80) {
    document.querySelector('#progress-box').style.color = 'white'
  }

  if (p >= 100) {
    document.querySelector('#progress-box').style.opacity = 0

    notify('success', 'Plik został pomyślnie dodany')
  }

  progressBox.innerHTML = `Przesyłanie pliku... <span>${p}%</span>`
  progressBox.style.background = `linear-gradient(to right, var(--primary) ${p}%, white ${p}%)`
}

export const startUpload = (files, projId) => {
  ENDPOINTS.UPLOAD = `${apiAddress}/projects/${projId}/upload`
  ENDPOINTS.UPLOAD_STATUS = `${apiAddress}/projects/${projId}/upload-status`
  ENDPOINTS.UPLOAD_REQUEST = `${apiAddress}/projects/${projId}/upload-request`
  uploadAndTrackFiles(files)
}
