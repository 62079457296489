import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useQuery, useQueries } from 'react-query'
import { CustomizedDatePicker } from 'app/components'
import RaportCalendar from './RaportCalendar'
import { toAbsoluteUrl } from '_metronic/_helpers'
import { useAuth } from 'contexts/authContext'
import { IMAGES_ENDPOINT } from 'constants.js'
import { getProfile, getProjectStats } from 'utils/queries'
import { getCorrectorRaport } from 'utils/queries'
import PageLoader from 'app/components/PageLoader'
import { notify } from 'app/components/notify'
import MyProjects from './MyProjects'

const CorrectorDashboard = () => {
  const {
    state: { user },
  } = useAuth()

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState()
  const [generating, setGenerating] = useState(false)

  const generateRaport = async () => {
    if (endDate) {
      setGenerating(true)

      try {
        await getCorrectorRaport(
          startDate,
          endDate,
          user._id,
          `Raport_${user.firstName}_${user.lastName}.pdf`
        )
      } catch (err) {
        notify('error', 'Podczas generowania raportu wystąpił błąd')
      }

      setGenerating(false)
    }
  }

  const { isLoading, data } = useQuery('projectsStats', getProjectStats)

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      <div className="row">
        <div className="card card-custom gutter-b col-lg-7">
          <div className="card-body d-flex flex-column justify-content-between">
            <div className="d-flex mb-9">
              <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                <div className="symbol symbol-50 symbol-lg-120">
                  <img
                    src={
                      user.photo
                        ? `${IMAGES_ENDPOINT}${user.photo}?${Date.now()}`
                        : toAbsoluteUrl('/media/client-logos/default.jpeg')
                    }
                    alt=""
                  />
                </div>
              </div>

              <div className="flex-grow-1">
                <div className="d-flex justify-content-between flex-wrap mt-1">
                  <div className="d-flex mr-3">
                    <span className="text-dark font-size-h5 font-weight-bold mr-3">
                      {user.firstName + ' ' + user.lastName}
                    </span>
                    <span>
                      <i className="flaticon2-correct text-success font-size-h5"></i>
                    </span>
                  </div>

                  <NavLink to="/user-profile">
                    <div className="my-lg-0 my-3">
                      <a className="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3">
                        Edycja profilu
                      </a>
                    </div>
                  </NavLink>
                </div>

                <div className="d-flex flex-wrap justify-content-between mt-1">
                  <div className="d-flex flex-column flex-grow-1 pr-8">
                    <div className="d-flex flex-wrap mb-4">
                      <a className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                        <i className="flaticon2-new-email mr-2 font-size-lg"></i>
                        <a
                          href={`mailto:${user.email}`}
                          style={{
                            color: 'inherit',
                          }}
                        >
                          {user.email}
                        </a>
                      </a>

                      <span className="text-dark-50 font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                        <i className="flaticon2-calendar-3 mr-2 font-size-lg"></i>
                        Korektor
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="separator separator-solid"></div>

              <div className="d-flex align-items-center flex-wrap mt-8">
                <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                  <span className="mr-4">
                    <i className="flaticon-piggy-bank display-4 text-muted font-weight-bold"></i>
                  </span>
                  <div className="d-flex flex-column icon-color">
                    <span className="font-weight-bolder font-size-sm">
                      Całkowite zarobki
                    </span>
                    <span className="font-weight-bolder font-size-h5">
                      {/* TO DO */}
                      {/* {data?.data?.totalEarnings} PLN */}0 PLN
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                  <span className="mr-4">
                    <i className="flaticon-clock-2 display-4 text-muted font-weight-bold"></i>
                  </span>
                  <div className="d-flex flex-column icon-color">
                    <span className="font-weight-bolder font-size-sm">
                      Nowe
                    </span>
                    <span className="font-weight-bolder font-size-h5">
                      {data?.data?.newProjects}
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                  <span className="mr-4">
                    <i className="flaticon-edit display-4 text-muted font-weight-bold"></i>
                  </span>
                  <div className="d-flex flex-column icon-color">
                    <span className="font-weight-bolder font-size-sm">
                      W trakcie
                    </span>
                    <span className="font-weight-bolder font-size-h5">
                      {data?.data?.currentProjects}
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                  <span className="mr-4">
                    <i className="flaticon-list display-4 text-muted font-weight-bold"></i>
                  </span>
                  <div className="d-flex flex-column icon-color">
                    <span className="font-weight-bolder font-size-sm">
                      Ukończone
                    </span>
                    <span className="font-weight-bolder font-size-h5">
                      {data?.data?.completedProjects}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 pr-0">
          <div
            className="card card-custom gutter-b"
            style={{
              padding: '2rem 2.25rem',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3 className="card-title font-weight-bolder">Raporty</h3>
              <div className="card-toolbar"></div>
            </div>

            <div className="card-body pt-0 d-flex flex-column">
              <div className="pt-2">
                <p className="text-center font-weight-normal font-size-lg pb-2">
                  <div className="d-flex" style={{ flexFlow: 'column' }}>
                    <i className="flaticon2-pie-chart-4 display-2 text-muted font-weight-bold"></i>
                    Wygeneruj raport
                    <div className="datepicker-range mt-10">
                      <div className="row">
                        <div className="input-group date col-6">
                          <div
                            style={{ textAlign: 'left', paddingLeft: '13px' }}
                          >
                            od:
                          </div>
                          <CustomizedDatePicker
                            selected={startDate}
                            onChange={date => {
                              //setFieldValue('deadline', date.toISOString())

                              setStartDate(date)
                            }}
                            customInput={<RaportCalendar />}
                          />
                        </div>

                        <div className="input-group date col-6">
                          <div
                            style={{ textAlign: 'left', paddingLeft: '13px' }}
                          >
                            do:
                          </div>
                          <CustomizedDatePicker
                            selected={endDate}
                            onChange={date => {
                              if (date.getTime() >= startDate.getTime())
                                setEndDate(date)
                              else setEndDate(startDate)
                            }}
                            customInput={<RaportCalendar />}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </p>
                <button
                  onClick={generateRaport}
                  className="btn btn-success btn-shadow-hover font-weight-bolder w-100 py-3 position-relative"
                  style={{
                    pointerEvents: generating ? 'none' : 'unset',
                  }}
                >
                  <span
                    style={{
                      opacity: generating ? '0.25' : '1',
                      transition: '0.27s opacity',
                    }}
                  >
                    Generuj raport
                  </span>
                  <div
                    className="spinner spinner-white position-absolute"
                    style={{
                      top: '50%',
                      left: '50%',
                      opacity: generating ? '1' : '0',
                      transition: '0.35s opacity',
                    }}
                  ></div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg p-0">
          <div
            className="card card-stretch card-custom gutter-b"
            style={{
              padding: '2rem 2.25rem',
            }}
          >
            <h3 className="card-title font-weight-bolder">Moje projekty</h3>

            <MyProjects type="dashboard" role="corrector" />
          </div>
        </div>
      </div>
    </>
  )
}

export default CorrectorDashboard
