import queryString from 'query-string'
import axios from 'axios'

import { apiAddress, REFRESH_TOKEN_ENDPOINT } from 'constants.js'
import { localJWT, localRefresh } from 'utils/jwt'

axios.interceptors.request.use(
  config => {
    const token = localJWT.read()

    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }

    return config
  },
  error => Promise.reject(error)
)

axios.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config
    let refresh = localRefresh.read()

    if (
      refresh &&
      error?.response?.status &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true

      return axios
        .post(apiAddress + REFRESH_TOKEN_ENDPOINT, { refresh })
        .then(res => {
          if (res.status === 200) {
            localJWT.write(res.data.token)
            localRefresh.write(res.data.refresh)
            return axios(originalRequest)
          }
          localStorage.clear()
          window.location.reload()
        })
        .catch(() => {
          localStorage.clear()
          window.location.reload()
        })
    }

    return Promise.reject(error)
  }
)

export const get = (path, params, blob = false) => {
  const paramsStringified = queryString.stringify(params)
  const pathParams = path.includes('?')
    ? path + '&' + paramsStringified
    : path + '?' + paramsStringified

  const url = apiAddress + pathParams.replace(/\?$/, '')

  return axios
    .get(url, { responseType: blob ? 'blob' : 'json' })
    .then(res => res.data)
}

export const post = (path, body) => {
  const url = apiAddress + path

  return axios.post(url, body).then(res => res.data)
}

export const patch = (path, body) => {
  const url = apiAddress + path

  return axios.patch(url, body).then(res => res.data)
}

export const del = path => {
  const url = apiAddress + path

  return axios.delete(url).then(res => res.data)
}
