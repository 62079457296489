import React, { useState, useEffect } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useQueryClient, useMutation } from 'react-query'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toAbsoluteUrl } from '../../../_metronic/_helpers/AssetsHelpers'

import { UPDATE_USER, IMAGES_ENDPOINT } from '../../../constants'
import { patch } from '../../../utils/api'
import { useAuth } from '../../../contexts/authContext'
import { notify } from '../../components/notify'
import { updateMe } from 'utils/mutations'
import {
  minMaxValidator,
  onlyLettersValidator,
  phoneValidator,
} from 'utils/validators'

const getImageThumb = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = resolve
    reader.readAsDataURL(file)
  })
}

const ProfileForm = ({ intl, initialValues }) => {
  const [loading, setLoading] = useState(false)
  const [thumb, setThumb] = useState('')

  const { state: authState, dispatch } = useAuth()
  const {
    user: { _id, role, photo, ...user },
  } = authState

  useEffect(() => {
    setThumb(
      photo
        ? `${IMAGES_ENDPOINT}${photo}?${Date.now()}`
        : toAbsoluteUrl('/media/client-logos/default.jpeg')
    )
  }, [photo])

  const queryClient = useQueryClient()

  const mutation = useMutation(updateMe, {
    onSuccess: data => {
      dispatch({ type: UPDATE_USER, payload: data.data.user })
      queryClient.invalidateQueries('profile')
    },
  })

  const userSchema = Yup.object().shape({
    firstName: onlyLettersValidator(intl, 2, 100),
    lastName: onlyLettersValidator(intl, 2, 100),
    company: minMaxValidator(intl, 2, 100),
    phone: phoneValidator(intl),
  })
  // photo: Yup.mixed()
  //   .test(
  //     'fileSize',
  //     'File Size is too large',
  //     value => value.size <= FILE_SIZE
  //   )
  //   .test('fileType', 'Unsupported File Format', value =>
  //     SUPPORTED_FORMATS.includes(value.type)
  //   ),

  const handleUpdate = async (values, { setStatus, setSubmitting }) => {
    setSubmitting(true)
    setLoading(true)

    const body = new FormData()
    for (const key in values) {
      body.append(key, values[key])
    }
    try {
      await mutation.mutateAsync(body)
      notify(
        'success',
        intl.formatMessage({
          id: 'PROFILE.PROFILE.FORM.SUCCESS',
        })
      )
    } catch (err) {
      notify(
        'error',
        intl.formatMessage({
          id: err.id || 'PROFILE.PROFILE.FORM.ERROR',
        })
      )
    }

    setLoading(false)
    setSubmitting(false)
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: userSchema,
    onSubmit: handleUpdate,
  })

  return (
    <form onSubmit={formik.handleSubmit} className="col-xl-7 my-2">
      <div className="row">
        <label className="col-3"></label>
        <div className="col-9">
          <h6 className="text-dark font-weight-bold mb-10">
            <FormattedMessage id="PROFILE.PROFILE.TITLE" />
          </h6>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-3 text-lg-right text-left">
          <FormattedMessage id="PROFILE.PROFILE.FIELD.AVATAR" />
        </label>
        <div className="col-9">
          <div
            className="image-input image-input-empty image-input-outline"
            style={{
              backgroundImage: `url(${thumb})`,
              backgroundPosition: 'center',
            }}
          >
            <div className="image-input-wrapper">
              <label
                style={{
                  position: 'relative',
                  left: '90%',
                  top: '-10%',
                }}
                className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
              >
                <i className="fa fa-pen icon-sm text-muted"></i>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  name="profile_avatar"
                  accept=".png, .jpg, .jpeg"
                  onChange={e => {
                    const photo = e.currentTarget.files[0]
                    formik.setFieldValue('photo', photo)
                    setLoading(true)
                    getImageThumb(photo).then(res => {
                      setThumb(res.currentTarget.result)
                      setLoading(false)
                    })
                  }}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-3 text-lg-right text-left">
          <FormattedMessage id="PROFILE.PROFILE.FIELD.NAME" />
        </label>
        <div className="col-9">
          <input
            className={`form-control ${
              formik.touched.firstName && formik.errors.firstName
                ? 'is-invalid invalid-input'
                : ''
            } form-control-lg form-control-solid`}
            type="text"
            name="firstName"
            {...formik.getFieldProps('firstName')}
          />
          {formik.touched.firstName && formik.errors.firstName && (
            <div className="invalid-input-label">{formik.errors.firstName}</div>
          )}
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-3 text-lg-right text-left">
          <FormattedMessage id="PROFILE.PROFILE.FIELD.SURNAME" />
        </label>
        <div className="col-9">
          <input
            className={`form-control ${
              formik.touched.lastName && formik.errors.lastName
                ? 'is-invalid invalid-input'
                : ''
            } form-control-lg form-control-solid`}
            type="text"
            name="lastName"
            {...formik.getFieldProps('lastName')}
          />
          {formik.touched.lastName && formik.errors.lastName && (
            <div className="invalid-input-label">{formik.errors.lastName}</div>
          )}
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-3 text-lg-right text-left">
          <FormattedMessage id="PROFILE.PROFILE.FIELD.PHONE" />
        </label>
        <div className="col-9">
          <input
            className={`form-control ${
              formik.touched.phone && formik.errors.phone
                ? 'is-invalid invalid-input'
                : ''
            } form-control-lg form-control-solid`}
            type="text"
            name="phone"
            {...formik.getFieldProps('phone')}
          />
          {formik.touched.phone && formik.errors.phone && (
            <div className="invalid-input-label">{formik.errors.phone}</div>
          )}
        </div>
      </div>
      {role === 'client' && (
        <div className="form-group row">
          <label className="col-form-label col-3 text-lg-right text-left">
            <FormattedMessage id="PROFILE.PROFILE.FIELD.COMPANY" />
          </label>
          <div className="col-9">
            <input
              className={`form-control ${
                formik.touched.company && formik.errors.company
                  ? 'is-invalid invalid-input'
                  : ''
              } form-control-lg form-control-solid`}
              type="text"
              name="company"
              {...formik.getFieldProps('company')}
            />
            {formik.touched.company && formik.errors.company && (
              <div className="invalid-input-label">{formik.errors.company}</div>
            )}
          </div>
        </div>
      )}
      <div className="form-group row">
        <div className="col-9 offset-3">
          <button
            type="submit"
            className="btn show-notify btn-primary mr-2"
            disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}
          >
            <span>
              {' '}
              <FormattedMessage id="PROFILE.PROFILE.FORM.SAVE" />
            </span>
            {loading && (
              <span className="ml-3 mr-5 spinner spinner-white"></span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}

export default injectIntl(ProfileForm)
