export const persistState = (storageKey, state) => {
  localStorage.setItem(storageKey, JSON.stringify(state))
}

export const rehydrateState = (storageKey, initialState) => {
  const state = localStorage.getItem(storageKey)
  try {
    if (!state) {
      return initialState
    }

    return JSON.parse(state ?? {})
  } catch (err) {
    console.log('Error loading state with key: ' + storageKey)

    return initialState
  }
}
