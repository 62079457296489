import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { injectIntl } from 'react-intl'
import { useAuth } from 'contexts/authContext'
import { getStatuses } from 'utils/queries'
import { updateProjectStatus, updateTaskStatus } from 'utils/mutations'
import { notify } from 'app/components/notify'
import { getTextColor } from 'utils/helpers'
import { ColorFormatter } from 'app/modules/Table/formatters/StatusesFormatters'

const StatusesSelect = ({
  type,
  status,
  intl,
  projectId,
  statuses,
  taskId,
}) => {
  const [selectedStatus, setSelectedStatus] = useState({
    ...status,
    name: status?.slug
      ? intl.formatMessage({ id: `STATUS.${status?.slug}` })
      : status?.name,
  })

  const queryClient = useQueryClient()
  const auth = useAuth()
  const isClient = auth.state.user.role === 'client'

  const { data, error, isLoading } = useQuery('statusesSelect', () =>
    getStatuses(type)
  )

  const projectMutation = useMutation(
    ({ projectId, statusId }) => updateProjectStatus(projectId, statusId),
    {
      onSuccess: () => {
        notify('success', 'Status zaktualizowany')
        queryClient.invalidateQueries(projectId)
      },
      onError: () => {
        notify('error', 'Coś poszło nei tak..')
      },
    }
  )

  const taskMutation = useMutation(
    ({ projectId, taskId, statusId }) =>
      updateTaskStatus(projectId, taskId, statusId),
    {
      onSuccess: () => {
        notify('success', 'Status zaktualizowany')
        queryClient.invalidateQueries(projectId)
        queryClient.invalidateQueries('tasks-table')
      },
      onError: () => {
        notify('error', 'Coś poszło nei tak..')
      },
    }
  )

  return (
    <Dropdown drop="down" className="status-select">
      <Dropdown.Toggle
        className="btn btn-sm btn-text text-uppercase font-weight-bold mr-3"
        style={{
          backgroundColor: selectedStatus?.bgColor,
          borderWidth: 1,
          borderColor: selectedStatus?.bgColor,
          //borderRadius: '10px',
          padding: '0.55rem 0.75rem',
          color:
            selectedStatus?.txtColor || getTextColor(selectedStatus?.bgColor),
        }}
      >
        <span>{selectedStatus?.name}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div
          style={{
            maxHeight: '300px',
            width: '100%',
            overflowY: 'auto',
            zIndex: 10,
          }}
        >
          {data &&
            data
              .map(status => ({
                id: status.id,
                name: status?.slug
                  ? intl.formatMessage({ id: `STATUS.${status?.slug}` })
                  : status?.name,
                bgColor: status?.bgColor,
                txtColor: status?.txtColor,
              }))
              // .filter(status => !statuses?.includes(status.id))
              .map((status, i) => (
                <Dropdown.Item
                  key={i}
                  onSelect={() => {
                    if (!isClient) {
                      setSelectedStatus(status)
                      if (type === 'project') {
                        projectMutation.mutate({
                          projectId,
                          statusId: status.id,
                        })
                      } else {
                        taskMutation.mutate({
                          projectId,
                          statusId: status.id,
                          taskId,
                        })
                      }
                    }
                  }}
                  className="p-0 statuses-dropdown"
                >
                  <div className="d-flex align-items-center justify-content-between p-3">
                    <span
                      className="pr-5"
                      // className="label label-lg font-weight-bold label-inline label-light-info marek-style"
                      // style={{
                      //   color: status.txtColor || getTextColor(status.bgColor),
                      // }}
                    >
                      {status.name || 'Brak'}
                    </span>
                    <ColorFormatter color={status.bgColor} />
                  </div>
                </Dropdown.Item>
              ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default injectIntl(StatusesSelect)
