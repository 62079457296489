import React from 'react'
import { injectIntl } from 'react-intl'
import { useQuery } from 'react-query'

import UserNavigation from './UserNavigation'
import ProfileForm from './ProfileForm'
import { getProfile } from '../../../utils/queries'
import { useSubheader } from '_metronic/layout'
import PageLoader from 'app/components/PageLoader'

const Profile = ({ intl, ...props }) => {
  const { isLoading, isError, data } = useQuery('profile', getProfile, {
    refetchOnWindowFocus: false,
  })

  const subheader = useSubheader()

  subheader.setTitle(intl.formatMessage({ id: 'PROFILE.TABS.PROFILE' }))

  if (isLoading) {
    return (
      <span>
        <PageLoader />
      </span>
    )
  }

  if (isError) {
    return <span>Error</span>
  }

  const { lastTask, ...rest } = data

  return (
    <>
      <div className="card card-custom">
        <UserNavigation />
        <div className="card-body">
          <div className="row">
            <div className="col-xl-2"></div>
            <ProfileForm initialValues={rest} />
          </div>
        </div>
      </div>
    </>
  )
}

export default injectIntl(Profile)
