import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { FormattedMessage, injectIntl } from 'react-intl'

import { useAuth } from 'contexts/authContext'
import { LOGIN, LOGIN_ENDPOINT } from 'constants.js'
import { post } from 'utils/api'
import Alert from './Alerts/Alert'
import { emailValidator, requiredValidator } from 'utils/validators'

const Login = ({ intl, ...props }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const {
    state: {},
    dispatch,
  } = useAuth()

  const loginAction = payload =>
    dispatch({
      type: LOGIN,
      payload,
    })

  const initialValues = {
    email: '',
    password: '',
  }

  const LoginSchema = Yup.object().shape({
    email: emailValidator(intl),
    password: requiredValidator(intl),
  })

  const handleLogin = async (values, { setStatus, setSubmitting }) => {
    setLoading(true)
    try {
      const response = await post(LOGIN_ENDPOINT, values)
      loginAction({
        jwt: response.token,
        refresh: response.refresh,
        user: response.data.user,
      })
    } catch (err) {
      setError(
        intl.formatMessage({ id: err.id || 'API.ERROR.AUTH.INCORRECT_DATA' })
      )
    }
    setLoading(false)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: handleLogin,
  })

  return (
    <div className="login-form login-signin">
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="pb-5 pb-lg-15">
          <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
            <FormattedMessage id="AUTH.LOGIN.TITLE" />
          </h3>
        </div>
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        <div className="form-group fv-plugins-icon-container">
          <Alert message={error} type="error" />
          <label className="font-size-h6 font-weight-bolder text-dark">
            <FormattedMessage id="AUTH.INPUT.EMAIL" />
          </label>
          <input
            type="email"
            className={`form-control h-auto py-7 px-6 rounded-lg border-0 ${
              formik.touched.email && formik.errors.email
                ? 'is-invalid invalid-input'
                : ''
            }`}
            name="email"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          )}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <div className="d-flex justify-content-between mt-n5">
            <label className="font-size-h6 font-weight-bolder text-dark pt-5">
              <FormattedMessage id="AUTH.INPUT.PASSWORD" />
            </label>
            <Link
              to="/auth/forgot-password"
              className="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
            >
              <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
            </Link>
          </div>
          <input
            type="password"
            className={`form-control h-auto py-7 px-6 rounded-lg border-0 ${
              formik.touched.password && formik.errors.password
                ? 'is-invalid invalid-input'
                : ''
            }`}
            name="password"
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          )}
        </div>
        <div className="pb-lg-0 pb-5">
          <button
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3`}
          >
            <span>
              <FormattedMessage id="AUTH.LOGIN.BUTTON" />
            </span>
            {loading && (
              <span className="ml-3 mr-4 spinner spinner-white"></span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default injectIntl(Login)
