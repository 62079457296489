import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '_metronic/_partials/controls'

import CustomizedTable from 'app/modules/Table/CustomizedTable'
import ProjectsFilter from 'app/modules/Table/filters/ProjectsFilter'
import { columns, options } from 'app/modules/Table/configs/projectsConfig'
import { useAuth } from 'contexts/authContext'
import useLocalStorage from 'utils/useLocalStorage'

const Projects = ({ type = 'all' }) => {
  const [status, setStatus] = useLocalStorage('projects-filter-status')
  const [finished, setFinished] = useLocalStorage(
    'projects-filter-finished',
    false
  )

  const {
    state: {
      user: { role },
    },
  } = useAuth()

  const { search } = useLocation()

  const queryTableParams = {
    lastStatus: status,
  }

  if (type === 'all') {
    const { client, corrector } = queryString.parse(search)

    if (client) {
      queryTableParams.client = client
    } else if (corrector) {
      queryTableParams.corrector = corrector
    }
  }

  const dependencyFields = [status]

  if (!finished) {
    dependencyFields.push(finished)
    queryTableParams.finished = finished
  }

  return (
    <Card style={{ minHeight: '290px' }}>
      <CardHeader
        icon={
          <span className="card-icon">
            <i className="flaticon2-layers text-primary"></i>
          </span>
        }
        className=""
        title="Lista projektów"
      >
        <CardHeaderToolbar></CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CustomizedTable
          columns={columns(type, role)}
          queryIdentificator="projects-table"
          filterRendered={
            <ProjectsFilter
              useStatus={() => [status, setStatus]}
              useFinished={() => [finished, setFinished]}
            />
          }
          displaySearch
          dependencyParams={queryTableParams}
          dependencyFields={dependencyFields}
          {...options(type)}
        />
      </CardBody>
    </Card>
  )
}

export default Projects
