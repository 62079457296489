import React, { forwardRef } from 'react'

const RaportCalendar = forwardRef(({ value, onClick }, ref) => (
  /*<button onClick={onClick} ref={ref}>
      {value}
    </button>*/ <div
    className="input-group date"
    onClick={onClick}
  >
    <input className="form-control datetimepicker-input" value={value}></input>
    <div className="input-group-append">
      <span
        ref={ref}
        className="input-group-text cursor-pointer"
        style={{ padding: '10px' }}
      >
        <i className="ki ki-calendar"></i>
      </span>
    </div>
  </div>
))

export default RaportCalendar
