export const api = process.env.REACT_APP_API_ADDRESS || 'http://localhost:2137'
export const azure =
  process.env.REACT_APP_AZURE_ADDRESS ||
  'https://tipscribeprod.blob.core.windows.net/materials/'

export const apiAddress = `${api}/api/v1`

//api router
export const AUTH_ENDPOINT = '/auth'
export const USERS_ENDPOINT = '/users'
export const STATUSES_ENDPOINT = '/statuses'
export const SETTINGS_ENDPOINT = '/settings'
export const PROJECTS_ENDPOINT = '/projects'
export const TASKS_ENDPOINT = '/tasks'

export const IMAGES_ENDPOINT = `${api}/images/`
export const VIDEOS_ENDPOINT = `${api}/videos/`
export const WAVEFORMS_ENDPOINT = `${api}/waveforms`
export const SUBT_VIDEOS_ENDPOINT = `${api}/ass/videos/`

export const LOGIN_ENDPOINT = `${AUTH_ENDPOINT}/login`
export const LOGIN_AS_ENDPOINT = `${AUTH_ENDPOINT}/login-as`
export const FORGOT_PASSWORD_ENDPOINT = `${AUTH_ENDPOINT}/forgotPassword`
export const RESET_PASSWORD_ENDPOINT = `${AUTH_ENDPOINT}/resetPassword`
export const UPDATE_PASSWORD_ENDPOINT = `${AUTH_ENDPOINT}/updatePassword`
export const VALIDATE_ACTIVATE_TOKEN_ENDPOINT = `${AUTH_ENDPOINT}/validateToken`
export const VALIDATE_RESET_TOKEN_ENDPOINT = `${AUTH_ENDPOINT}/validateResetToken`
export const ACTIVATE_ENDPOINT = `${AUTH_ENDPOINT}/activate`
export const REFRESH_TOKEN_ENDPOINT = `${AUTH_ENDPOINT}/refreshToken`

export const GET_ME_ENDPOINT = `${USERS_ENDPOINT}/me`
export const UPDATE_ME_ENDPOINT = `${USERS_ENDPOINT}/updateMe`
export const NOTIFICATIONS_ENDPOINT = `${USERS_ENDPOINT}/update-notifications`
export const REPORTS_ENDPOINT = `${USERS_ENDPOINT}/generate-raport`

export const MY_TASKS_ENDPOINT = `${TASKS_ENDPOINT}/my-tasks`
export const MY_PROJECTS_ENDPOINT = `${PROJECTS_ENDPOINT}/my-projects`

//context
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const UPDATE_USER = 'UPDATE_USER'

//regexp
export const phoneRegExp = /^\+?\d+$/
export const onlyLetterRegExp = /^[\s\p{L}]+$/u
export const passRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/
export const urlRegExp = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi

export const DICTIONARIES_PATH = '/dictionaries'
export const ENGLISH_DICT = {
  aff: '/en_US/en_US.aff',
  dic: '/en_US/en_US.dic',
}

//statuses

export const statusesConstants = {
  new: {
    bgColor: '#E4E6EF',
    txtColor: '#3F4254',
    intl: 'STATUS.NEW',
  },
  'in-progress': {
    bgColor: '#187DE4',
    txtColor: '#FFFFFF',
    intl: 'STATUS.IN_PROGRESS',
  },
  ready: {
    bgColor: '#187DE4',
    txtColor: '#FFFFFF',
    intl: 'STATUS.READY',
  },
  rejected: {
    bgColor: '#EE2D41',
    txtColor: '#FFFFFF',
    intl: 'STATUS.REJECTED',
  },
  finished: {
    bgColor: '#0BB7AF',
    txtColor: '#FFFFFF',
    intl: 'STATUS.FINISHED',
  },
  archived: {
    bgColor: '#131628',
    txtColor: '#FFFFFF',
    intl: 'STATUS.ARCHIVED',
  },
}
