import React from 'react'
import { Select, MenuItem } from '@material-ui/core'

const UsersFilter = ({ role, useStatus, useRole }) => {
  const [status, setStatus] = useStatus()
  const [roleF, setRole] = useRole()

  return (
    <>
      <div className="col-md-3 my-2 my-md-0">
        <div className="d-flex align-items-center">
          <label className="mr-3 mb-0 d-none d-md-block">Status:</label>
          <Select
            onChange={e => {
              setStatus(e.target.value)
            }}
            variant="outlined"
            value={status}
          >
            <MenuItem value={undefined}>
              <em>Wszystkie</em>
            </MenuItem>
            <MenuItem value={true}>Aktywny</MenuItem>
            <MenuItem value={false}>Nieaktywny</MenuItem>
          </Select>
        </div>
      </div>

      {role && (
        <div className="col-md-3 my-2 my-md-0">
          <div className="d-flex align-items-center">
            <label className="mr-3 mb-0 d-none d-md-block">Rola:</label>
            <Select
              onChange={e => {
                setRole(e.target.value)
              }}
              variant="outlined"
              value={roleF}
            >
              <MenuItem value={undefined}>
                <em>Wszystkie</em>
              </MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="client">Klient</MenuItem>
              <MenuItem value="individual-client">Klient indywidualny</MenuItem>
              <MenuItem value="corrector">Korektor</MenuItem>
            </Select>
          </div>
        </div>
      )}
    </>
  )
}

export default UsersFilter
