import React from 'react'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../_metronic/_helpers'
import { FormattedMessage } from 'react-intl'

const UserNavigation = () => {
  return (
    <div className="card card-custom">
      <div className="card-header card-header-tabs-line nav-tabs-line-x">
        <div className="card-toolbar">
          <ul className="nav nav-tabs nav-bold nav-tabs-line nav-tabs-line-3x">
            {/* Profil */}
            <li className="nav-item mr-3">
              <NavLink className="nav-link" to="/user-profile">
                <span className="nav-icon">
                  <span className="svg-icon">
                    <SVG
                      src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')}
                    />
                  </span>
                </span>
                <span className="nav-text font-size-lg">
                  <FormattedMessage id="PROFILE.TABS.PROFILE" />
                </span>
              </NavLink>
            </li>
            {/* Zmiana hasła */}
            <li className="nav-item mr-3">
              <NavLink className="nav-link" to="/change-password">
                <span className="nav-icon">
                  <span className="svg-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        '/media/svg/icons/Communication/Shield-user.svg'
                      )}
                    />
                  </span>
                </span>
                <span className="nav-text font-size-lg">
                  <FormattedMessage id="PROFILE.TABS.CHANGE_PASS" />
                </span>
              </NavLink>
            </li>
            {/* Powiadomienia */}
            <li className="nav-item mr-3">
              <NavLink className="nav-link" to="/notifications">
                <span className="nav-icon">
                  <span className="svg-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        '/media/svg/icons/Communication/Sending-mail.svg'
                      )}
                    />
                  </span>
                </span>
                <span className="nav-text font-size-lg">
                  <FormattedMessage id="PROFILE.TABS.NOTIFICATIONS" />
                </span>
              </NavLink>
            </li>
            {/* <li className="nav-item mr-3">
              <NavLink className="nav-link" to="/profile-settings">
                <span className="nav-icon">
                  <span className="svg-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        '/media/svg/icons/Communication/Mail-opened.svg'
                      )}
                    />
                  </span>
                </span>
                <span className="nav-text font-size-lg">
                  <FormattedMessage id="PROFILE.TABS.SETTINGS" />
                </span>
              </NavLink>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default UserNavigation
