import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import SVG from 'react-inlinesvg'

import { toAbsoluteUrl } from '../../../../_helpers'
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns'

import { useAuth } from 'contexts/authContext'
import { IMAGES_ENDPOINT } from 'constants.js'

export function UserProfileDropdown() {
  const { state: authState } = useAuth()

  const { user } = authState

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            'btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2'
          }
        >
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
            Cześć,
          </span>{' '}
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {user.firstName}
          </span>
          <div className="symbol symbol-40 symbol-sm flex-shrink-0">
            <img
              src={
                user.photo
                  ? `${IMAGES_ENDPOINT}${user.photo}?${Date.now()}`
                  : toAbsoluteUrl('/media/client-logos/default.jpeg')
              }
              alt=""
            />
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-8 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <div className="d-flex align-items-center mt-5">
          <div className="symbol symbol-100 mr-5">
            <div
              className="symbol-label"
              style={{
                backgroundImage: `url(${
                  user.photo
                    ? `${IMAGES_ENDPOINT}${user.photo}`
                    : toAbsoluteUrl('/media/client-logos/default.jpeg')
                })`,
              }}
            ></div>
            <i className="symbol-badge bg-success"></i>
          </div>
          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >{`${user.firstName} ${user.lastName}`}</a>
            <div className="text-muted mt-1">
              <FormattedMessage
                id={`USERS.ADD.ROLE.${user.role.toUpperCase()}`}
              />
            </div>
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-text text-muted text-hover-primary">
                    {user.email}
                  </span>
                </span>
              </a>
              <Link
                to="/logout"
                className="btn btn-light-primary font-weight-bold"
              >
                <FormattedMessage id="USER.DROPDOWN.LOGOUT" />
              </Link>
            </div>
          </div>
        </div>
        <div className="separator separator-dashed mt-8 mb-5" />
        <div className="navi navi-spacer-x-0 p-0">
          <Link to="/user-profile" className="navi-item cursor-pointer">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <SVG
                      src={toAbsoluteUrl(
                        '/media/svg/icons/General/Notification2.svg'
                      )}
                    />
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">
                  <FormattedMessage id="USER.DROPDOWN.SETTINGS.TITLE" />
                </div>
                <div className="text-muted">
                  <FormattedMessage id="USER.DROPDOWN.SETTINGS.DESC" />
                </div>
              </div>
            </div>
          </Link>
          {/* <Link to="/my-reports" className="navi-item cursor-pointer">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-warning">
                    <SVG
                      src={toAbsoluteUrl(
                        '/media/svg/icons/Shopping/Chart-bar1.svg'
                      )}
                    />
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">
                  <FormattedMessage id="USER.DROPDOWN.RAPORTS.TITLE" />
                </div>
                <div className="text-muted">
                  <FormattedMessage id="USER.DROPDOWN.RAPORTS.DESC" />
                </div>
              </div>
            </div>
          </Link> */}
          {!user.role.includes('admin') && (
            <Link to="/my-projects" className="navi-item cursor-pointer">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-md svg-icon-danger">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/Files/Selected-file.svg'
                        )}
                      />
                    </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    <FormattedMessage id="USER.DROPDOWN.PROJECTS.TITLE" />
                  </div>
                  <div className="text-muted">
                    <FormattedMessage id="USER.DROPDOWN.PROJECTS.DESC" />
                  </div>
                </div>
              </div>
            </Link>
          )}
          {/* {user.role !== 'client' && (
            <Link to="/my-tasks" className="navi-item cursor-pointer">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/Communication/Mail-opened.svg'
                        )}
                      />
                    </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    <FormattedMessage id="USER.DROPDOWN.TASKS.TITLE" />
                  </div>
                  <div className="text-muted">
                    <FormattedMessage id="USER.DROPDOWN.TASKS.DESC" />
                  </div>
                </div>
              </div>
            </Link>
          )} */}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}
