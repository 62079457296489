import * as React from 'react'
import { useQuery } from 'react-query'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'

import { getAvailableSeconds } from 'utils/queries'

export const AvailableSeconds = () => {
  const { isLoading, data } = useQuery({
    queryKey: 'availableSeconds',
    queryFn: getAvailableSeconds,
    refetchInterval: 30 * 1000,
  })

  return (
    <div className="topbar-item" style={{ marginRight: '8px' }}>
      <Tooltip title="W celu zwiększenia ilości minut prosimy o skontaktowanie się z administratorem.">
        <Chip
          variant="outlined"
          clickable
          color="primary"
          label={`Dostępne minuty: ${
            isLoading
              ? '-'
              : Math.floor((data.data.availableSeconds / 60) * 100) / 100
          }`}
        />
      </Tooltip>
    </div>
  )
}
