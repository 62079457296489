import React, { useState } from 'react'
import LinearStepper from './stepper/LinearStepper'
import Alert from '../../modules/Auth/pages/Alerts/Alert'
import { FormattedMessage, injectIntl } from 'react-intl'

import 'react-toastify/dist/ReactToastify.css'

const AddProject = ({ intl }) => {
  const [message, setMessage] = useState('')
  const [type, setType] = useState('')

  const setMessageData = (nmessage, ntype) => {
    setMessage(nmessage)
    setType(ntype)
  }

  return (
    <>
      <Alert type={type} message={message} />
      <div className="card card-custom">
        <div className="card-body p-0">
          <LinearStepper setMessage={setMessageData} />
        </div>
      </div>
    </>
  )
}

export default injectIntl(AddProject)
