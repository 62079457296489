import * as React from 'react'

import { ActionsHistory, actionTypes, handleUndo, handleRedo } from './Actions'

export const useTranscription = () => {
  const [transcription, setTranscription] = React.useState([])
  const actionsHistory = React.useRef(new ActionsHistory())

  React.useEffect(() => {
    const handler = e => {
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 90) {
        e.preventDefault()
        handleUndo(actionsHistory.current, setTranscription)
      } else if ((e.ctrlKey || e.metaKey) && e.keyCode === 89) {
        e.preventDefault()
        handleRedo(actionsHistory.current, setTranscription)
      }
    }

    window.addEventListener('keydown', handler)

    return () => window.removeEventListener('keydown', handler)
  }, [])

  const setTranscriptionFromApi = initTranscription =>
    setTranscription(initTranscription)

  const handleBlockSplit = (text, textAfter, i) =>
    setTranscription(gt => {
      const t = [...gt]
      const oldBlock = t[i]
      const firstBlock = { ...oldBlock }
      const firstBlockLength = firstBlock.end - firstBlock.start
      const secondBlockEnd = firstBlock.end

      firstBlock.segment = text
      firstBlock.end -= firstBlockLength / 2

      const secondBlock = {
        start: firstBlock.end,
        end: secondBlockEnd,
        segment: textAfter,
      }

      t.splice(i, 1, firstBlock, secondBlock)

      actionsHistory.current.pushAction({
        type: actionTypes.BLOCK_SPLIT,
        index: i,
        payload: [oldBlock, firstBlock, secondBlock],
      })

      return t
    })

  const handleTextTimeChange = (timestamp, i) =>
    setTranscription(gt => {
      const t = [...gt]
      const oldBlock = t[i]
      const newBlock = {
        ...oldBlock,
        ...timestamp,
      }
      const nextBlock = t[i + 1]
      const prevBlock = t[i - 1]

      if (nextBlock && newBlock.end >= nextBlock.start) {
        newBlock.end = nextBlock.start
      }

      if (prevBlock && newBlock.start <= prevBlock.end) {
        newBlock.start = prevBlock.end
      }

      t.splice(i, 1, newBlock)

      actionsHistory.current.pushAction({
        type: actionTypes.BLOCK_TIME_CHANGE,
        index: i,
        payload: [oldBlock, newBlock],
      })

      return t
    })

  const handleTextChange = (text, i) =>
    setTranscription(gt => {
      const t = [...gt]
      const oldBlock = t[i]
      const newBlock = {
        ...oldBlock,
        segment: text,
      }

      t.splice(i, 1, newBlock)

      return t
    })

  const handleBlockDelete = i =>
    setTranscription(gt => {
      const t = [...gt]
      const oldBlock = t[i]

      t.splice(i, 1)

      actionsHistory.current.pushAction({
        type: actionTypes.BLOCK_DELETE,
        index: i,
        payload: oldBlock,
      })

      return t
    })

  const handleBlockAdd = i =>
    setTranscription(gt => {
      const t = [...gt]
      const blockBefore = t[i]
      const blockAfter = t[i + 1]
      let start = 0
      let end = 0

      if (blockAfter) {
        const blocksDiff = blockAfter.start - blockBefore.end

        if (blocksDiff > 1.5) {
          start = blockBefore.end + 0.5
          end = blockBefore.end + 1.5
        } else if (blocksDiff <= 1.5 && blocksDiff > 0) {
          start = blockBefore.end
          end = blockAfter.start
        } else {
          return t
        }
      } else {
        start = blockBefore.end + 0.5
        end = blockBefore.end + 1.5
      }

      const newBlock = {
        start,
        end,
        segment: '',
      }

      if (blockAfter) {
        t.splice(i, 2, blockBefore, newBlock, blockAfter)
      } else {
        t.splice(i, 1, blockBefore, newBlock)
      }

      actionsHistory.current.pushAction({
        type: actionTypes.BLOCK_ADD,
        index: i + 1,
        payload: newBlock,
      })

      return t
    })

  const handleBlockJoin = i =>
    setTranscription(gt => {
      const t = [...gt]
      const blockBefore = t[i]
      const blockAfter = t[i + 1]

      if (blockAfter) {
        const newBlock = {
          start: blockBefore.start,
          end: blockAfter.end,
          segment: blockBefore.segment + '\n' + blockAfter.segment,
        }

        t.splice(i, 2, newBlock)

        actionsHistory.current.pushAction({
          type: actionTypes.BLOCK_JOIN,
          index: i,
          payload: [newBlock, blockBefore, blockAfter],
        })
      }

      return t
    })

  const handleFindAndReplace = (oldValue, newValue) =>
    setTranscription(t =>
      t.map(block => ({
        ...block,
        segment: block.segment.replaceAll(oldValue, newValue),
      }))
    )

  return {
    transcription,
    setTranscriptionFromApi,
    handleTextTimeChange,
    handleTextChange,
    handleBlockSplit,
    handleBlockAdd,
    handleBlockDelete,
    handleBlockJoin,
    handleFindAndReplace,
    handleUndo: () => handleUndo(actionsHistory.current, setTranscription),
    handleRedo: () => handleRedo(actionsHistory.current, setTranscription),
  }
}
