export const localJWT = {
  read: () => localStorage.getItem('jwt'),
  write: jwt => localStorage.setItem('jwt', jwt),
  delete: () => localStorage.removeItem('jwt'),
}

export const localRefresh = {
  read: () => localStorage.getItem('refresh'),
  write: refresh => localStorage.setItem('refresh', refresh),
  delete: () => localStorage.removeItem('refresh'),
}
