import React from 'react'
import { Select, MenuItem, Switch } from '@material-ui/core'

import { statusesConstants } from 'constants.js'
import { FormattedMessage } from 'react-intl'

const ProjectsFilter = ({ useStatus, useFinished }) => {
  const [status, setStatus] = useStatus()
  const [finished, setFinished] = useFinished()

  return (
    <>
      <div className="col-md-3 my-2 my-md-0">
        <div className="d-flex align-items-center">
          <label className="mr-3 mb-0 d-none d-md-block">Status:</label>
          <Select
            onChange={e => {
              setStatus(e.target.value)
            }}
            variant="outlined"
            value={status}
          >
            <MenuItem value={undefined}>Wszystkie</MenuItem>
            {Object.keys(statusesConstants).map(key => (
              <MenuItem key={key} value={key}>
                <FormattedMessage id={statusesConstants[key].intl} />
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className="col-md-3 my-2 my-md-0">
        <div className="d-flex align-items-center">
          <label className="mr-3 mb-0 d-none d-md-block">
            Pokaż zakończone:
          </label>
          <Switch
            checked={finished}
            onChange={checked => {
              setFinished(checked.currentTarget.checked)
            }}
          />
        </div>
      </div>
    </>
  )
}

export default ProjectsFilter
