import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { useMutation, useQueryClient } from 'react-query'
import { OverlayTrigger, Tooltip, Modal } from 'react-bootstrap'
import SVG from 'react-inlinesvg'

import { toAbsoluteUrl } from '_metronic/_helpers'

import { archiveProject } from 'utils/mutations'
import { notify } from 'app/components/notify'
import { useAuth } from 'contexts/authContext'

export const ActionsFormatter = injectIntl(({ id, intl, transcription }) => {
  const [modal, setModal] = useState({
    show: false,
    loading: false,
  })

  const {
    state: { user },
  } = useAuth()

  const queryClient = useQueryClient()

  const deleteProjectMutation = useMutation(id => archiveProject(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('projects-table')
    },
  })

  return (
    <>
      <Modal size="md" show={modal.show}>
        <Modal.Header closeButton>
          <Modal.Title>Usuń projekt</Modal.Title>
        </Modal.Header>
        <Modal.Body>Czy aby na pewno chcesz usunąć ten projekt?</Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            disabled={modal.loading}
            className="btn show-notify btn-danger mr-2"
            onClick={async () => {
              setModal(m => ({ ...m, loading: true }))
              try {
                await deleteProjectMutation.mutateAsync(id)
              } catch (err) {
                notify(
                  'error',
                  intl.formatMessage({
                    id: err?.response?.data?.id || 'API.ERROR.GENERAL',
                  })
                )
              }
              setModal({ show: false, loading: false })
            }}
          >
            Usuń
            {modal.loading && (
              <span className="ml-3 mr-5 spinner spinner-white"></span>
            )}
          </button>
          <button
            disabled={modal.loading}
            type="button"
            className="btn btn-secondary font-weight-bold mr-2"
            onClick={() => setModal(m => ({ ...m, show: false }))}
          >
            Anuluj
          </button>
        </Modal.Footer>
      </Modal>
      <div style={{ textAlign: 'right' }}>
        <span>
          <Link to={`/projects/${id}`}>
            <OverlayTrigger overlay={<Tooltip>Pokaż</Tooltip>}>
              <div className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2">
                <span className="svg-icon svg-icon-sm">
                  <i className="flaticon2-search-1 icon-size-13"></i>
                </span>
              </div>
            </OverlayTrigger>
          </Link>

          {user.role === 'corrector' && transcription && (
            <OverlayTrigger overlay={<Tooltip>Transkrypcja</Tooltip>}>
              <Link
                to={`/editor/${id}`}
                className="btn btn-sm btn-default btn-text-primary btn-hover-success btn-icon mr-2"
              >
                <span className="svg-icon svg-icon-sm">
                  <i className="flaticon-edit"></i>
                </span>
              </Link>
            </OverlayTrigger>
          )}

          {user.role.includes('admin') && (
            <OverlayTrigger overlay={<Tooltip>Usuń</Tooltip>}>
              <div
                className="btn btn-sm btn-default btn-text-primary btn-hover-danger btn-icon mr-2"
                onClick={() => setModal({ show: true, loading: false })}
              >
                <span className="svg-icon svg-icon-sm">
                  <SVG
                    src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')}
                  />
                </span>
              </div>
            </OverlayTrigger>
          )}
        </span>
      </div>
    </>
  )
})
