import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { USERS_ENDPOINT } from 'constants.js'
import {
  CustomizedSelect,
  CustomizedTextarea,
  CustomizedDatePicker,
} from 'app/components'
import { toAbsoluteUrl } from '_metronic/_helpers'

Date.prototype.isSameDateAs = function(pDate) {
  return (
    this.getFullYear() === pDate.getFullYear() &&
    this.getMonth() === pDate.getMonth() &&
    this.getDate() === pDate.getDate()
  )
}

const formatName = obj => {
  return `${obj.firstName} ${obj.lastName}`
}

const Informations = ({
  formik,
  selectedClient,
  setSelectedClient,
  selectedCorrector,
  setSelectedCorrector,
  role,
}) => {
  const [preview, setPreview] = useState()
  const [selectedFile, setSelectedFile] = useState()
  const [selectedDate, setSelectedDate] = useState(
    new Date(Date.now() + 864000000)
  )

  const isClient = role === 'client'
  const isIndividualClient = role === 'individual-client'

  const handleDateChange = date => {
    setSelectedDate(date)
    formik.setFieldValue('deadline', date.toISOString())
  }

  useEffect(() => {
    if (!formik.values.deadline) {
      formik.setFieldValue('deadline', selectedDate.toISOString())
    } else {
      setSelectedDate(Date.parse(formik.values.deadline))
    }
  }, [])

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }

    setSelectedFile(e.target.files[0])
    formik.setFieldValue('photo', e.currentTarget.files[0])
  }

  const handleSelect = element => {
    formik.setFieldValue('client', element._id)
    setSelectedClient(element)
  }

  const handleCorrectorSelect = element => {
    formik.setFieldValue('corrector', element._id)
    setSelectedCorrector(element)
  }

  return (
    <div className="pb-5">
      <h4 className="mb-10 font-weight-bold text-dark">
        <FormattedMessage id="PROJECT.ADD.ENTER.INFO" />
      </h4>
      <div className="form-group fv-plugins-icon-container has-success">
        <label>
          <FormattedMessage id="PROJECT.ADD.NAME.MANDATORY" />
          <span className="text-danger">*</span>
        </label>
        <input
          name="name"
          placeholder="17. Sesja Rady Gminy X lub Wykład Profesora Nowaka"
          className={`form-control form-control-solid form-control-lg ${
            formik.touched.name && formik.errors.name
              ? 'is-invalid invalid-input'
              : ''
          }`}
          {...formik.getFieldProps('name')}
        ></input>

        {formik.touched.name && formik.errors.name ? (
          <div className="invalid-input-label">{formik.errors.name}</div>
        ) : (
          ''
        )}
      </div>
      <div className="form-group fv-plugins-icon-container has-success">
        <label>
          <FormattedMessage id="PROJECT.ADD.DESC" />
        </label>
        <CustomizedTextarea
          name="description"
          placeholder="Dodaj link do spisu nazwisk radnych, protokołu spotkania lub wpisz najtrudniejsze nazwiska lub nazwy, które padają w materiale"
          rowsMin={4}
          {...formik.getFieldProps('description')}
        ></CustomizedTextarea>

        <div className="invalid-input-label">
          {formik.touched.description && formik.errors.description
            ? formik.errors.description
            : ''}
        </div>
      </div>
      {!isClient && (
        <div className="form-group fv-plugins-icon-container has-success">
          <label>
            <FormattedMessage id="PROJECT.ADD.DEADLINE" />
          </label>
          <CustomizedDatePicker
            name="deadline"
            selected={selectedDate}
            onChange={handleDateChange}
          />
        </div>
      )}
      {!isClient && !isIndividualClient && (
        <div className="form-group fv-plugins-icon-container has-success">
          <label>
            <FormattedMessage id="PROJECT.ADD.CLIENT" />
            <span className="text-danger">*</span>
          </label>
          <CustomizedSelect
            placeholder={
              selectedClient ? formatName(selectedClient) : 'Wybierz klienta'
            }
            queryKey="clients-select"
            activePlaceholder={'Szukaj klienta'}
            endpoint={USERS_ENDPOINT}
            role="client"
            className={`btn-light form-control form-control-solid form-control-lg custom-select-lazy ${
              formik.touched.client && formik.errors.client
                ? 'is-invalid invalid-input'
                : ''
            }`}
            handleSelect={handleSelect}
          />
          <div className="invalid-input-label">
            {formik.touched.client && formik.errors.client
              ? formik.errors.client
              : ''}
          </div>
        </div>
      )}
      <div className="form-group fv-plugins-icon-container has-success">
        <label style={{ width: '100%' }}>
          <FormattedMessage id="PROJECT.ADD.AVATAR" />
        </label>

        <div
          className="image-input image-input-outline"
          style={{
            backgroundImage: `url(${preview ||
              toAbsoluteUrl('/media/project-logos/default.jpg')})`,
            backgroundColor: 'white',
          }}
          id="kt_image_4"
        >
          <div className="image-input-wrapper"></div>

          <label
            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
            data-action="change"
            data-toggle="tooltip"
            title=""
            data-original-title="Change avatar"
          >
            <i className="fa fa-pen icon-sm text-muted"></i>
            <input
              type="file"
              name="profile_avatar"
              accept=".png, .jpg, .jpeg"
              onChange={onSelectFile}
            />
            <input type="hidden" name="profile_avatar_remove" />
          </label>

          <span
            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
            data-action="cancel"
            data-toggle="tooltip"
            title="Cancel avatar"
          >
            <i className="ki ki-bold-close icon-xs text-muted"></i>
          </span>

          <span
            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
            data-action="remove"
            data-toggle="tooltip"
            title="Remove avatar"
            onClick={() => {
              setPreview(undefined)
            }}
          >
            <i className="ki ki-bold-close icon-xs text-muted"></i>
          </span>
        </div>
      </div>
      {!isClient && !isIndividualClient && (
        <div>
          <label className="col-form-label" htmlFor="deadline">
            Przypisany korektor
          </label>
          <CustomizedSelect
            role="corrector"
            queryKey="correctors-select"
            endpoint={USERS_ENDPOINT}
            placeholder={
              selectedCorrector
                ? formatName(selectedCorrector)
                : 'Wybierz korektora'
            }
            handleSelect={handleCorrectorSelect}
            className={`${
              formik.touched.corrector && formik.errors.corrector
                ? 'is-invalid invalid-input'
                : ''
            } btn-light form-control form-control-solid form-control-lg custom-select-lazy`}
          />
          {formik.touched.corrector && formik.errors.corrector && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.corrector}</div>
            </div>
          )}
          <div className="col-4 pl-0">
            <label className="col-form-label" htmlFor="minuteRate">
              Stawka godzinowa
            </label>
            <div className="input-group">
              <input
                placeholder="Stawka godzinowa"
                type="number"
                className={`form-control ${
                  formik.touched.hourlyRate && formik.errors.hourlyRate
                    ? 'is-invalid invalid-input'
                    : ''
                } form-control-lg form-control-solid`}
                name="hourlyRate"
                {...formik.getFieldProps('hourlyRate')}
              />
              <div className="input-group-append">
                <span
                  className="input-group-text"
                  style={{
                    background: 'rgb(215, 218, 231)',
                    border: 0,
                    color: 'rgb(87, 87, 87)',
                  }}
                >
                  PLN
                </span>
              </div>
            </div>

            {formik.touched.minuteRate && formik.errors.minuteRate && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.minuteRate}</div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Informations
