import React from 'react'
import _ from 'lodash'

import { CustomizedSelect } from 'app/components'
import { useAuth } from 'contexts/authContext'
import { USERS_ENDPOINT } from 'constants.js'

const formatName = obj => `${obj.firstName} ${obj.lastName}`

const CorrectorDetails = ({ formik, data, showModal }) => {
  const auth = useAuth()
  const isCorrector = auth.state.user.role === 'corrector'

  const handleSelect = corrector => {
    formik.setFieldValue('corrector', corrector)
  }

  return (
    <div className="tab-content pt-5">
      <div className="tab-pane active">
        <form className="form">
          <div className="row">
            <div className="col-lg-9 col-xl-6 offset-xl-3"></div>
          </div>

          {!isCorrector && (
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                Korektor:
              </label>
              <div className="col-lg-9 col-xl-6">
                <CustomizedSelect
                  queryKey="correctors-select"
                  endpoint={USERS_ENDPOINT}
                  placeholder={
                    formik.values.corrector
                      ? formatName(formik.values.corrector)
                      : 'Brak korektora'
                  }
                  role="corrector"
                  handleSelect={handleSelect}
                  className={`${
                    formik.touched.corrector && formik.errors.corrector
                      ? 'is-invalid invalid-input'
                      : ''
                  } btn-light form-control form-control-solid form-control-lg custom-select-lazy`}
                />
                {formik.touched.corrector && formik.errors.corrector && (
                  <div className="invalid-input-label">
                    {formik.errors.corrector}
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="form-group row">
            <label
              className="col-xl-3 col-lg-3 col-form-label text-right"
              htmlFor="hourlyRate"
            >
              Stawka godzinowa:
            </label>
            <div className="col-lg-9 col-xl-6 input-group">
              <input
                placeholder="Stawka godzinowa"
                type="number"
                className={`form-control ${
                  formik.touched.hourlyRate && formik.errors.hourlyRate
                    ? 'is-invalid invalid-input'
                    : ''
                } form-control-lg form-control-solid`}
                name="hourlyRate"
                disabled={isCorrector}
                {...formik.getFieldProps('hourlyRate')}
              />
              <div className="input-group-append">
                <span
                  className="input-group-text"
                  style={{
                    background: 'rgb(215, 218, 231)',
                    border: 0,
                    color: 'rgb(87, 87, 87)',
                  }}
                >
                  PLN
                </span>
              </div>
            </div>
          </div>

          {!isCorrector && (
            <div className="row">
              <div className="col-xl-9 d-flex justify-content-end">
                <h3 className="font-size-h6 mb-5">
                  <button
                    className="btn btn-primary font-weight-bold"
                    type="button"
                    disabled={!formik.isValid}
                    onClick={showModal}
                  >
                    Zapisz
                  </button>
                </h3>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

export default CorrectorDetails
