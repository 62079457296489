import React, { useCallback, useEffect, useState } from 'react'
import DragDrop from '@uppy/react/lib/DragDrop'
import Uppy from '@uppy/core'
import { notify } from 'app/components/notify'

import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'

const CustomizedDropzone = ({
  onComplete,
  onUpload,
  onFileAdded,
  setTotalSize,
  isProjectDetailPage = false,
  maxFileSize,
}) => {
  const uppy = new Uppy({
    meta: { type: 'video' },
    restrictions: {
      maxFileSize: maxFileSize,
      maxNumberOfFiles: 1,
      allowedFileTypes: ['video/*'],
    },
    autoProceed: false,
    onBeforeFileAdded: file => {
      if (!file.data.type.startsWith('video')) {
        notify(
          'error',
          `Format "${file.data.type}" nie jest poprawny. Plik nie został dodany.`
        )
      } else if (file.data.size > maxFileSize) {
        notify(
          'error',
          `Plik jest za duży. Maksymalny dopuszczalny rozmiar to ${maxFileSize /
            1000000}MB.`
        )
      } else {
        notify(
          'success',
          `Pomyślnie dodano wideo "${file.data.name}". ${
            !isProjectDetailPage
              ? 'Upload rozpocznie się w momencie dodania projektu.'
              : ''
          } `
        )
      }
    },
  })

  const [text, setText] = useState('Przeciągnij lub %{browse}')

  uppy.on('file-added', file => {
    //    startUpload([file.data], '60ba2a340674f2106dc2c0f0')
    onFileAdded(file.data)
    setText('Plik został dodany.')
  })

  return (
    <>
      <DragDrop
        uppy={uppy}
        note={
          !isProjectDetailPage
            ? 'Upload pliku rozpocznie się w momencie dodania projektu.'
            : ''
        }
        locale={{
          strings: {
            dropHereOr: text,
            browse: 'wybierz',
          },
        }}
      />
    </>
  )
}

export default CustomizedDropzone
