import React, { useState } from 'react'
import { useFormik } from 'formik'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { FormattedMessage, injectIntl } from 'react-intl'

import Alert from './Alerts/Alert'
import { FORGOT_PASSWORD_ENDPOINT } from 'constants.js'
import { post } from 'utils/api'
import { emailValidator } from 'utils/validators'

const initialValues = {
  email: '',
}

const ForgotPassword = ({ intl, ...props }) => {
  const [loading, setLoading] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertType, setAlertType] = useState('')
  const [formSubmitted, setFormSubmitted] = useState(false)

  const ForgotPasswordSchema = Yup.object().shape({
    email: emailValidator(intl),
  })

  const handleSubmit = async (values, { setStatus, setSubmitting }) => {
    setLoading(true)

    try {
      const response = await post(FORGOT_PASSWORD_ENDPOINT, values)
      setAlertType('success')
      setAlertMessage(
        intl.formatMessage({
          id: 'AUTH.FORGOT.SENT',
        })
      )
      setFormSubmitted(true)
    } catch (err) {
      setAlertMessage(intl.formatMessage({ id: err.id }))
      setAlertType('error')
    }

    setLoading(false)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: handleSubmit,
  })

  return (
    <div className="login-form">
      <Alert message={alertMessage} type={alertType} />
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="pb-5 pb-lg-15">
          <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
            <FormattedMessage id="AUTH.FORGOT.TITLE" />
          </h3>
          <p className="text-muted font-weight-bold font-size-h4">
            <FormattedMessage id="AUTH.FORGOT.DESC" />
          </p>
        </div>

        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        <div className="form-group fv-plugins-icon-container">
          <input
            type="email"
            placeholder="Email"
            className={`form-control h-auto py-7 px-6 border-0 rounded-lg font-size-h6 ${
              formik.touched.email && formik.errors.email
                ? 'is-invalid invalid-input'
                : ''
            }`}
            name="email"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap">
          {!formSubmitted ? (
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
              disabled={formik.isSubmitting || formSubmitted}
            >
              <FormattedMessage id="AUTH.GENERAL.SEND" />
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          ) : (
            ''
          )}

          <Link to="/auth">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
            >
              {!formSubmitted ? (
                <FormattedMessage id="AUTH.GENERAL.CANCEL_BUTTON" />
              ) : (
                <FormattedMessage id="PROJECT.DETAILS.BACK" />
              )}
            </button>
          </Link>
        </div>
      </form>
    </div>
  )
}

export default injectIntl(ForgotPassword)
