/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link, Switch, Redirect } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'
import { ContentRoute } from '../../../../_metronic/layout'
import Login from './Login'
import Registration from './Registration'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss'

export default function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div className="login-aside d-flex flex-column flex-row-auto">
            <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
              <Link
                to="/auth/login"
                className="login-logo text-center pt-lg-25 pb-10"
              >
                <img
                  src={toAbsoluteUrl('/media/logos/logo.png')}
                  className="max-h-70px"
                  alt=""
                />
              </Link>

              <h3 className="font-weight-bolder text-center font-size-h4 text-dark-50 line-height-xl">
                każde słowo ma znaczenie <p>every word matters</p>
              </h3>
            </div>
            <div
              className="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-x-center"
              style={{
                minHeight: '550px',
                backgroundSize: '630px',
                backgroundPositionY: 'calc(100% + 5rem)',
                backgroundImage: `url(${toAbsoluteUrl(
                  '/media/svg/illustrations/login-visual-5.svg'
                )}`,
              }}
            ></div>
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="bg-light d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            <div className="d-flex flex-row-fluid flex-center">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <ContentRoute
                  path="/auth/reset-password"
                  component={ResetPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; 2020 Metronic
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <Link to="/terms" className="text-dark-75 text-hover-primary">
                  Privacy
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Legal
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Contact
                </Link>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  )
}
