import React, { useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import {
  LOGOUT,
  passRegExp,
  UPDATE_PASSWORD_ENDPOINT,
} from '../../../constants'
import UserNavigation from './UserNavigation'
import { patch } from '../../../utils/api'
import { useAuth } from '../../../contexts/authContext'
import { notify } from '../../components/notify'
import {
  confirmPasswordValidator,
  minMaxReqValidator,
  passwordValidator,
} from 'utils/validators'
import { useSubheader } from '_metronic/layout'

const ChangePassword = ({ intl }) => {
  const { dispatch } = useAuth()

  const subheader = useSubheader()

  subheader.setTitle(intl.formatMessage({ id: 'PROFILE.TABS.NOTIFICATIONS' }))

  const [loading, setLoading] = useState(false)

  const passwordSchema = Yup.object().shape({
    passwordCurrent: minMaxReqValidator(intl, 8, 50),
    password: passwordValidator(intl),
    passwordConfirm: confirmPasswordValidator(intl),
  })

  const handleUpdate = async (values, { setStatus, setSubmitting }) => {
    setSubmitting(true)
    setLoading(true)

    try {
      await patch(UPDATE_PASSWORD_ENDPOINT, values)
      notify(
        'success',
        intl.formatMessage({
          id: 'PROFILE.CHANGE_PASS.FORM.SUCCESS',
        })
      )
      setTimeout(() => dispatch({ type: LOGOUT }), 3000)
    } catch (err) {
      notify(
        'error',
        intl.formatMessage({
          id: err.id || 'PROFILE.PROFILE.FORM.ERROR',
        })
      )
    }

    setLoading(false)
    setSubmitting(false)
  }

  const formik = useFormik({
    initialValues: {
      passwordCurrent: '',
      password: '',
      passwordConfirm: '',
    },
    validationSchema: passwordSchema,
    onSubmit: handleUpdate,
  })

  return (
    <>
      <div className="card card-custom">
        <UserNavigation />
        <div className="card-body">
          <div className="row">
            <div className="col-xl-2"></div>
            <form onSubmit={formik.handleSubmit} className="col-xl-7 my-2">
              <div className="row">
                <label className="col-3"></label>
                <div className="col-9">
                  <h6 className="text-dark font-weight-bold mb-10">
                    <FormattedMessage id="PROFILE.CHANGE_PASS.TITLE" />
                  </h6>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-3 text-lg-right text-left">
                  <FormattedMessage id="PROFILE.CHANGE_PASS.FIELD.CURRENT_PASS" />
                </label>
                <div className="col-9">
                  <input
                    className={`form-control ${
                      formik.touched.passwordCurrent &&
                      formik.errors.passwordCurrent
                        ? 'is-invalid invalid-input'
                        : ''
                    } form-control-lg form-control-solid`}
                    type="password"
                    name="passwordCurrent"
                    {...formik.getFieldProps('passwordCurrent')}
                  />
                  {formik.touched.passwordCurrent &&
                    formik.errors.passwordCurrent && (
                      <div className="invalid-input-label">
                        {formik.errors.passwordCurrent}
                      </div>
                    )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-3 text-lg-right text-left">
                  <FormattedMessage id="PROFILE.CHANGE_PASS.FIELD.NEW_PASS" />
                </label>
                <div className="col-9">
                  <input
                    className={`form-control ${
                      formik.touched.password && formik.errors.password
                        ? 'is-invalid invalid-input'
                        : ''
                    } form-control-lg form-control-solid`}
                    type="password"
                    name="password"
                    {...formik.getFieldProps('password')}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className="invalid-input-label">
                      {formik.errors.password}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-3 text-lg-right text-left">
                  <FormattedMessage id="PROFILE.CHANGE_PASS.FIELD.CONFIRM_PASS" />
                </label>
                <div className="col-9">
                  <input
                    className={`form-control ${
                      formik.touched.passwordConfirm &&
                      formik.errors.passwordConfirm
                        ? 'is-invalid invalid-input'
                        : ''
                    } form-control-lg form-control-solid`}
                    type="password"
                    name="passwordConfirm"
                    {...formik.getFieldProps('passwordConfirm')}
                  />
                  {formik.touched.passwordConfirm &&
                    formik.errors.passwordConfirm && (
                      <div className="invalid-input-label">
                        {formik.errors.passwordConfirm}
                      </div>
                    )}
                </div>
              </div>

              <div className="form-group row">
                <div className="col-9 offset-3">
                  <button
                    type="submit"
                    className="btn show-notify btn-primary mr-2"
                    disabled={
                      formik.isSubmitting || !(formik.isValid && formik.dirty)
                    }
                  >
                    <span>
                      {' '}
                      <FormattedMessage id="PROFILE.CHANGE_PASS.FORM.SAVE" />
                    </span>
                    {loading && (
                      <span className="ml-3 mr-5 spinner spinner-white"></span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default injectIntl(ChangePassword)
