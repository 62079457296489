import React from 'react'

const settings = {
  client: {
    text: 'Klient',
    classes: 'primary',
  },
  'individual-client': {
    text: 'Klient indywidualny',
    classes: 'danger',
  },
  corrector: {
    text: 'Korektor',
    classes: 'success',
  },
  admin: {
    text: 'Admin',
    classes: 'info',
  },
  'super-admin': {
    text: 'Super Admin',
    classes: 'warning',
  },
}

export const RoleFormatter = ({ role }) => {
  const { text, classes } = settings[role]

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span
        style={{ marginRight: '6.5px' }}
        className={`label label-dot label-${classes}`}
      ></span>
      <span className={`font-weight-bold font-primary text-${classes}`}>
        {text}
      </span>
    </div>
  )
}
