import React from 'react'
import { Modal } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'

const SaveModal = ({
  show,
  formik,
  handleSubmit,
  hideModal,
  isSubmitting,
  intl,
}) => {
  return (
    <Modal size="md" show={show}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="PROJECT.DETAILS.MODAL.EDIT" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage id="PROJECT.DETAILS.CONFIRM" />
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => {
            handleSubmit(formik.values)
          }}
          className={`btn show-notify btn-primary mr-2`}
          disabled={isSubmitting}
        >
          <FormattedMessage id="PROJECT.DETAILS.MODAL.SAVE" />
          {isSubmitting && (
            <span className="ml-3 mr-5 spinner spinner-white"></span>
          )}
        </button>
        <button
          onClick={() => hideModal()}
          className="btn show-notify btn-light mr-2"
        >
          <FormattedMessage id="PROJECT.DETAILS.MODAL.CANCEL" />
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default injectIntl(SaveModal)
