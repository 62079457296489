import React from 'react'
import { CustomizedSelect, CustomizedTextarea } from 'app/components'
import { USERS_ENDPOINT } from 'constants.js'
import _ from 'lodash'
import { notify } from 'app/components/notify'
import { useAuth } from 'contexts/authContext'

const formatName = obj => {
  return `${obj.firstName} ${obj.lastName}`
}

const Details = ({ formik, data, showModal }) => {
  const auth = useAuth()
  const disableForm =
    auth.state.user.role === 'client' || auth.state.user.role === 'corrector'

  const handleSelect = client => {
    formik.setFieldValue('client', client)
  }

  return (
    <div className="tab-content pt-5">
      <div className="tab-pane active">
        <form className="form">
          <div className="row">
            <div className="col-lg-9 col-xl-6 offset-xl-3"></div>
          </div>

          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label text-right">
              Nazwa:
              {/* <FormattedMessage id="PROJECT.DETAILS.NAME />*/}
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                className={`form-control ${
                  formik.touched.name && formik.errors.name
                    ? 'is-invalid invalid-input'
                    : ''
                } form-control-lg form-control-solid`}
                type="text"
                disabled={disableForm}
                {...formik.getFieldProps('name')}
              ></input>
              {formik.touched.name && formik.errors.name && (
                <div className="invalid-input-label">{formik.errors.name}</div>
              )}
            </div>
          </div>

          {!auth.state.user.role.includes('client') &&
            auth.state.user.role !== 'corrector' && (
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-right">
                  Zleceniodawca:
                  {/* <FormattedMessage id="PROJECT.DETAILS.CLIENT />*/}
                </label>
                <div className="col-lg-9 col-xl-6">
                  <CustomizedSelect
                    queryKey="clients-select"
                    endpoint={USERS_ENDPOINT}
                    placeholder={
                      formik.values.client
                        ? formatName(formik.values.client)
                        : 'Brak klienta'
                    }
                    role="client"
                    handleSelect={handleSelect}
                    className={`${
                      formik.touched.client && formik.errors.client
                        ? 'is-invalid invalid-input'
                        : ''
                    } btn-light form-control form-control-solid form-control-lg custom-select-lazy`}
                  />
                  {formik.touched.client && formik.errors.client && (
                    <div className="invalid-input-label">
                      {formik.errors.client}
                    </div>
                  )}
                </div>
              </div>
            )}

          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label text-right">
              Opis:
              {/* <FormattedMessage id="PROJECT.DETAILS.DESC />*/}
            </label>
            <div className="col-lg-9 col-xl-6">
              <CustomizedTextarea
                type="text"
                disabled={disableForm}
                {...formik.getFieldProps('description')}
              ></CustomizedTextarea>
            </div>
          </div>

          {!disableForm && (
            <div className="row">
              <div className="col-xl-9 d-flex justify-content-end">
                <h3 className="font-size-h6 mb-5">
                  <button
                    className="btn btn-primary font-weight-bold"
                    type="button"
                    disabled={!formik.isValid}
                    onClick={showModal}
                  >
                    Zapisz
                  </button>
                </h3>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

export default Details
