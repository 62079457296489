import React from 'react'
import {
  AdminDashboard,
  CorrectorDashboard,
  ClientDashboard,
} from 'app/pages/dashboards'
import { useAuth } from 'contexts/authContext'

const getDashboardContent = role => {
  switch (role) {
    case 'super-admin':
    case 'admin':
      return <AdminDashboard />
    case 'corrector':
      return <CorrectorDashboard />
    case 'client':
    case 'individual-client':
      return <ClientDashboard />
    default:
      return 'No role defined'
  }
}

export function DashboardPage() {
  const {
    state: {
      user: { role },
    },
  } = useAuth()

  return <>{getDashboardContent(role)}</>
}
