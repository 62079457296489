import React from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'
import SearchIcon from '@material-ui/icons/Search'

const ProjectsHeader = () => {
  return (
    <>
      <div className="d-flex align-items-center flex-wrap mr-2">
        <a
          href="#"
          className="btn btn-light-danger font-weight-bolder btn-sm mr-2"
        >
          Reports
        </a>

        <a
          href="#"
          className="btn btn-light-success font-weight-bolder btn-sm mr-2"
        >
          Import
        </a>

        <div className="input-group input-group-sm input-group-solid max-w-175px">
          <input
            className="form-control pl-4"
            type="text"
            placeholder="Search..."
          ></input>
          <div className="input-group-append">
            <span className="input-group-text">
              <SearchIcon />
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectsHeader
