import React, { useEffect, useState } from 'react'
import { notify } from 'app/components/notify'
import { getVideoWithSubtitles, requestVideoWithSubtitles } from 'utils/queries'
import { downloadBlob } from 'utils/helpers'
import { apiAddress, SUBT_VIDEOS_ENDPOINT } from 'constants.js'
import { useQuery, useQueryClient } from 'react-query'
import { get } from 'utils/api'
import Axios from 'axios'

const DownloadVideoWithSubtitles = ({
  burningStatus: { processing, requestSent },
  projectId,
  video,
  projectName,
}) => {
  const queryClient = useQueryClient()

  const generateVideoWithSubtitles = async () => {
    try {
      await requestVideoWithSubtitles(projectId)
      queryClient.invalidateQueries(projectId)
    } catch (e) {
      notify('error', 'Wystąpił błąd podczas próby wygenerowania wideo.')
      // setStatus('REQUEST_NOT_SENT')
    }
  }

  const fetchVideoWithSubtitles = async () => {
    try {
      const video = await getVideoWithSubtitles(projectId)
      downloadBlob(video, projectName.split(' ').join('_'))
      // setStatus('DOWNLOADING')
    } catch (err) {
      notify('success', 'Wystąpił błąd podczas próby pobrania wideo.')
      // setStatus('READY')
    }
  }

  return (
    <div className="d-flex flex-row justify-content-between align-items-center">
      <span>Wideo z napisami</span>
      {!processing && requestSent && (
        <a
          className={`btn btn-sm btn-success font-weight-bolder text-uppercase`}
          href={`${apiAddress}/testRoute/downloadVideoWithBurnedSubtitles/${projectId}`}
          target="_blank"
          download={projectName.split(' ').join('_') + '.mp4'}
          // onClick={async () => {
          //   // download(
          //   //   get(
          //   //     `/testRoute/downloadVideoWithBurnedSubtitles/${projectId}`,
          //   //     {},
          //   //     true
          //   //   )
          //   // )
          //   Axios.get(
          //     `${apiAddress}/testRoute/downloadVideoWithBurnedSubtitles/${projectId}`
          //     // {
          //     //   headers: {
          //     //     Connection: 'keep-alive',
          //     //     Accept: '*',
          //     //     'Sec-Fetch-Dest': 'document',
          //     //     'Sec-Fetch-Mode': 'navigate',
          //     //     'Sec-Fetch-Site': 'none',
          //     //     'Sec-Fetch-User': '?1',
          //     //     'Sec-GPC': 1,
          //     //     'Upgrade-Insecure-Requests': 1,
          //     //   },
          //     // }
          //   )
          // }}
          // onClick={fetchVideoWithSubtitles}
          // onClick={async () => {
          //   try {
          //     const response = await get(
          //       `/testRoute/downloadVideoWithBurnedSubtitles/${projectId}`,
          //       {},
          //       true
          //     )
          //     const url = window.URL.createObjectURL(new Blob([response.data]))
          //     const link = document.createElement('a')
          //     link.href = url
          //     link.setAttribute(
          //       'download',
          //       projectName.split(' ').join('_') + '.mp4'
          //     )
          //     document.body.appendChild(link)
          //     link.click()
          //   } catch (err) {
          //     console.log(err)
          //   }
          // }}
        >
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Pobierz
            <i className="fas fa-download icon-sm ml-2"></i>
          </div>
        </a>
      )}

      <button
        onClick={() => {
          generateVideoWithSubtitles()
          // setStatus('PROCESSING')
          notify(
            'success',
            'Film z napisami jest w trakcie generowania. Długość procesu będzie zależna od długości wideo.'
          )
        }}
        className="btn btm-sm btn-info font-weight-bolder text-uppercase w-full"
        disabled={processing}
      >
        {processing ? (
          <>
            Osadzanie napisów{' '}
            <span className="ml-3 mr-5 spinner spinner-white"></span>
          </>
        ) : (
          'Generuj'
        )}
      </button>
    </div>
  )
}

export default DownloadVideoWithSubtitles
