import React, { useState, useEffect } from 'react'
import { patch } from '../../../../utils/api'
import { useFormik } from 'formik'
import queryString from 'query-string'
import * as Yup from 'yup'
import { Link, Redirect } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import Alert from './Alerts/Alert'
import {
  RESET_PASSWORD_ENDPOINT,
  passRegExp,
  VALIDATE_RESET_TOKEN_ENDPOINT,
} from '../../../../constants'
import { confirmPasswordValidator, passwordValidator } from 'utils/validators'
import { get } from 'utils/api'

/*
    AUTH.INPUT.PASSWORD
    AUTH.INPUT.CONFIRM_PASSWORD
    AUTH.FORGOT.SUCCESS
*/

const ResetPassword = ({ intl, location, history }) => {
  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertType, setAlertType] = useState('')
  const [formSubmitted, setFormSubmitted] = useState(false)

  const resetPassSchema = Yup.object().shape({
    password: passwordValidator(intl),
    confirmPassword: confirmPasswordValidator(intl),
  })

  useEffect(() => {
    ;(async () => {
      await get(
        `${VALIDATE_RESET_TOKEN_ENDPOINT}/${
          queryString.parse(location.search).token
        }`
      ).catch(err => {
        history.push('/')
      })
    })()
  }, [])

  useEffect(() => {
    const queryParams = queryString.parse(location.search)
    const authToken = queryParams.token
    setToken(authToken)
  }, [token])

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const handleResetPassword = async (values, { setStatus, setSubmitting }) => {
    setLoading(true)

    try {
      const response = await patch(
        `${RESET_PASSWORD_ENDPOINT}/${token}`,
        values
      )
      setAlertMessage(
        intl.formatMessage({
          id: 'AUTH.FORGOT.SUCCESS',
        })
      )
      setAlertType('success')
      setFormSubmitted(true)
    } catch (err) {
      setAlertMessage(
        intl.formatMessage({
          id: err.id,
        })
      )

      setAlertType('error')
    }

    setLoading(false)
  }

  const formik = useFormik({
    initialValues: {
      token: token,
      password: '',
      confirmPassword: '',
    },
    validationSchema: resetPassSchema,
    enableReinitialize: true,
    onSubmit: handleResetPassword,
  })

  return (
    <>
      <div className="login-form login-forgot" style={{ display: 'block' }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">
            <FormattedMessage id="AUTH.RESTART.TITLE" />
          </h3>
          <div className="text-muted font-weight-bold">
            <FormattedMessage id="AUTH.RESTART.DESC" />
          </div>
        </div>

        <Alert message={alertMessage} type={alertType} />

        <Link to="/auth/login">
          <button
            style={{
              display: formSubmitted ? 'block' : 'none',
            }}
            className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
          >
            Przejdź do strony logowania
          </button>
        </Link>

        <form
          onSubmit={formik.handleSubmit}
          style={{ display: formSubmitted ? 'none' : 'initial' }}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >
          <div className="form-group fv-plugins-icon-container">
            <div className="d-flex justify-content-between mt-n5">
              <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                <FormattedMessage id="AUTH.INPUT.PASSWORD" />
              </label>
            </div>
            <input
              type="password"
              className={`bg-white form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                'password'
              )}`}
              name="password"
              {...formik.getFieldProps('password')}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>

          <div className="form-group fv-plugins-icon-container">
            <div className="d-flex justify-content-between mt-n5">
              <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                <FormattedMessage id="AUTH.INPUT.CONFIRM_PASSWORD" />
              </label>
            </div>
            <input
              type="password"
              className={`bg-white form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                'confirmPassword'
              )}`}
              name="confirmPassword"
              {...formik.getFieldProps('confirmPassword')}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.confirmPassword}
                </div>
              </div>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap flex-center">
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              disabled={formik.isSubmitting || formSubmitted}
            >
              <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
              {loading && (
                <span className="ml-3 mr-4 spinner spinner-white"></span>
              )}
            </button>
            <Link to="/auth">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                <FormattedMessage id="AUTH.GENERAL.CANCEL_BUTTON" />
              </button>
            </Link>
          </div>
        </form>
      </div>
    </>
  )
}

export default injectIntl(ResetPassword)
