import React from 'react'
import { Link } from 'react-router-dom'

import { IMAGES_ENDPOINT } from 'constants.js'

export const ProjectFormatter = ({ project, photo, id, role }) => (
  <div className="d-flex align-items-center">
    <div className="symbol symbol-40 symbol-sm flex-shrink-0">
      <img
        src={
          photo
            ? `${IMAGES_ENDPOINT}${photo}?${Date.now()}`
            : '/media/project-logos/default.jpg'
        }
        alt="project"
      />
    </div>
    <div className="ml-4">
      <Link
        to={role === 'corrector' ? `/editor/${id}` : `/projects/${id}`}
        className="card-title text-hover-primary font-weight-bolder font-size-h5 text-dark mb-1"
      >
        {project}
      </Link>
    </div>
  </div>
)
