import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { LayoutSplashScreen } from '../../../../_metronic/layout'

import { useAuth } from '../../../../contexts/authContext'
import { LOGOUT } from '../../../../constants'

const Logout = () => {
  const { state: authState, dispatch } = useAuth()
  const { isAuth } = authState

  useEffect(() => {
    dispatch({ type: LOGOUT })
  }, [dispatch])

  return isAuth ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />
}

export default Logout
