import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useQuery } from 'react-query'

import { CustomizedDropzone } from 'app/components'
import { getSettings } from 'utils/queries'
import PageLoader from 'app/components/PageLoader'

const Upload = ({
  uploadSkipped,
  setUploadSkipped,
  setVideoFile,
  setTotalSize,
  videoFile,
  formik,
  role,
}) => {
  const { data, isLoading } = useQuery('settings', () => getSettings())

  if (isLoading) {
    return <PageLoader />
  }

  const showInformClient = role.includes('client')

  return (
    <div className="pb-5">
      <h4 className="mb-10 font-weight-bold text-dark">
        <FormattedMessage id="PROJECT.ADD.ENTER.VIDEO" />
      </h4>
      <div className="form-group fv-plugins-icon-container has-success">
        <label className={`${videoFile ? 'custom-disabled' : ''}`}>
          <FormattedMessage id="PROJECT.ADD.URL" />
        </label>
        <input
          name="videoUrl"
          placeholder="Adres URL"
          className={`form-control form-control-solid form-control-lg ${
            videoFile ? 'custom-disabled' : ''
          }`}
          {...formik.getFieldProps('videoUrl')}
        ></input>

        <div className="invalid-input-label">
          {formik.touched.videoUrl && formik.errors.videoUrl
            ? formik.errors.videoUrl
            : ''}
        </div>
      </div>
      <div className="form-group fv-plugins-icon-container has-success">
        <label>
          <FormattedMessage id="PROJECT.ADD.CHOOSE" />
        </label>
        <CustomizedDropzone
          onFileAdded={file => {
            setVideoFile(file)
          }}
          maxFileSize={data.maxMaterialSize * 1000000}
        />
      </div>
      {!showInformClient && (
        <div className="form-group fv-plugins-icon-container has-success checkbox-list">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={formik.values.informClient}
              name="box"
              onChange={e =>
                formik.setFieldValue(
                  'informClient',
                  !formik.values.informClient
                )
              }
            />
            <span></span>
            <FormattedMessage id="PROJECT.ADD.INFORM_CLIENT" />
          </label>
        </div>
      )}
    </div>
  )
}

export default Upload
