import React from 'react'
import { injectIntl } from 'react-intl'

import { statusesConstants } from 'constants.js'
import { swapStatusToClient } from 'utils/helpers'

export const StatusFormatter = injectIntl(({ data, intl, role }) => {
  const isClient = role.includes('client')

  const status = !isClient
    ? statusesConstants[data.status]
    : statusesConstants[swapStatusToClient(data.status)]

  return (
    <span
      className="label label-lg font-weight-bold label-inline label-light-info marek-style"
      style={{
        backgroundColor: status.bgColor,
        color: status.txtColor,
        width: '100%',
      }}
    >
      {intl.formatMessage({ id: status.intl }) || 'Brak'}
    </span>
  )
})
