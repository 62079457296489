import React from 'react'

import { toAbsoluteUrl } from '_metronic/_helpers/AssetsHelpers'

import { IMAGES_ENDPOINT } from 'constants.js'

export const SelectUserItem = ({ user }) => {
  if (!user) {
    return '-'
  }

  const { firstName, lastName, photo } = user

  return (
    <>
      <div className="d-flex align-items-center field-formatter-user">
        <div className="symbol symbol-40 symbol-sm flex-shrink-0">
          <img
            src={
              photo
                ? `${IMAGES_ENDPOINT}${photo}?${Date.now()}`
                : toAbsoluteUrl('/media/client-logos/default.jpeg')
            }
            alt="user"
            width="40"
            height="40"
          ></img>
        </div>
        <div className="ml-4 d-flex flex-column justify-content-center">
          <div className="text-dark-75 font-weight-bolder font-size-md mb-0 text-hover-primary cursor-pointer">
            {firstName} {lastName}
          </div>
          {!user.active && (
            <a
              className="text-muted font-weight-bold text-hover-primary"
              style={{
                fontSize: '90%',
              }}
              href={`mailto:${user.email}`}
            >
              {user.email}
            </a>
          )}
        </div>
      </div>
    </>
  )
}
