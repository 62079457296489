/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'
import { useSubheader } from '../../../_core/MetronicSubheader'
import ProjectsHeader from '../../../../../app/pages/projects/headers/ProjectsHeader'

const HeaderSubComponent = ({ title, subtitle }) => {
  return (
    <div className="d-flex align-items-center flex-wrap mr-2">
      <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">{title}</h5>
      <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>
      <span
        className="text-muted font-weight-bold mr-4"
        style={{ fontSize: '13px' }}
      >
        {subtitle}
      </span>
    </div>
  )
}

export function HeaderMenu({ layoutProps }) {
  const location = useLocation()
  const subheader = useSubheader()
  const getMenuItemActive = url => {
    return checkIsActive(location, url) ? 'menu-item-active' : ''
  }

  const getHeaderTitle = () => {
    const title = location.pathname.split('/')[1]

    // eslint-disable-next-line default-case
    switch (title) {
      case 'dashboard':
        return 'Dashboard'
      case 'users':
        return 'Użytkownicy'
      case 'clients':
        return 'Klienci'
      case 'correctors':
        return 'Korektorzy'
      case 'projects':
        return 'Projekty'
      case 'platform':
        return 'Platforma'
      case 'user-profile':
      case 'change-password':
        return 'Ustawienia'
      case 'my-tasks':
        return 'Moje zadania'
      case 'my-projects':
        return 'Moje projekty'
    }
  }

  const headerContent = path => {
    const title = getHeaderTitle()

    switch (path) {
      default:
        return (
          <HeaderSubComponent
            title={title}
            subtitle={subheader.title === title ? '' : subheader.title}
          />
        )
    }
  }

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}

      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}
          <div className="d-flex align-items-baseline mr-5">
            <h5 className="text-dark font-weight-bold my-2 mr-5">
              <>
                {headerContent(
                  subheader.breadcrumbs[0]
                    ? subheader.breadcrumbs[0].pathname
                    : ''
                )}
              </>
              {/*<small></small>*/}
            </h5>
          </div>
          {/*<BreadCrumbs items={subheader.breadcrumbs} /> */}
        </div>
      </div>

      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}

        {/*
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            '/dashboard'
          )}`}
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="menu-text">Dashboard</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
          </li>{*/}
        {/*end::1 Level*/}
        {/*Classic submenu*/}
        {/*begin::1 Level*/}
      </ul>
      {/*end::Header Nav*/}
    </div>
  )
}
