import React, { useState } from 'react'
import PanelNavigation from './panel/PanelNavigation'
import PanelContainer from './panel/PanelContainer'

const EditPanel = ({ formik, data, showModal, project, user }) => {
  const [activeTab, setActiveTab] = useState('details')

  return (
    <div className="col-lg-8">
      <div className="card card-custom ">
        <PanelNavigation
          user={user}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <PanelContainer
          project={project}
          formik={formik}
          activeTab={activeTab}
          data={data}
          showModal={showModal}
        />
      </div>
    </div>
  )
}

export default EditPanel
