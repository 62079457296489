import React, { useState, useEffect } from 'react'
import { Dropdown } from 'react-bootstrap'
import { injectIntl } from 'react-intl'
import * as Icon from './icons'
import * as DropdownUtils from './DropdownUtils'

/* eslint-disable */

const fonts = ['Arial', 'Consolas', 'Poppins', 'Roboto', 'Impact']
const fontSizes = ['12px', '16px', '20px', '24px', '28px']

const getTextAlignComponent = textAlign => {
  switch (textAlign) {
    case 'left':
      return <Icon.AlignLeft />
    case 'center':
      return <Icon.AlignCenter />
    case 'right':
      return <Icon.AlignRight />
  }
}

const FormatOptions = injectIntl(({ formatting, onFormattingChange, intl }) => {
  const [fontWeight, setFontWeight] = useState('normal')
  const [fontStyle, setFontStyle] = useState('normal')
  const [textAlign, setTextAlign] = useState('center')
  const [textSelectionType, setTextSelectionType] = useState('none')
  const [centered, setCentered] = useState(false)

  const fontSize = formatting.fontSize.toString().includes('px')
    ? formatting.fontSize
    : formatting.fontSize + 'px'

  useEffect(() => {
    onFormattingChange('bottom', 0)
    onFormattingChange('centered', centered)
  }, [centered])

  useEffect(() => {
    onFormattingChange('textAlign', textAlign)
  }, [textAlign])

  useEffect(() => {
    onFormattingChange('textSelection', {
      ...formatting.textSelection,
      type: textSelectionType,
    })
  }, [textSelectionType])

  return (
    <div className="format-panel">
      <select
        value={formatting.fontFamily}
        onChange={e => {
          onFormattingChange('fontFamily', e.target.value)
        }}
        className="gray-border-input"
        title="Font"
      >
        {fonts.map(f => (
          <option value={f}>{f}</option>
        ))}
      </select>

      <select
        title={intl.formatMessage({
          id: 'FORMATTING.SIZE',
        })}
        value={fontSize}
        onChange={e => {
          onFormattingChange('fontSize', e.target.value)
        }}
        className="gray-border-input"
      >
        {fontSizes.map(fs => (
          <option value={fs}>{fs}</option>
        ))}
      </select>

      <div
        onClick={() => {
          const newFontWeight = fontWeight === 'normal' ? 'bold' : 'normal'
          onFormattingChange('fontWeight', newFontWeight)
          setFontWeight(newFontWeight)
        }}
        className="gray-border-input format-icon"
        title={intl.formatMessage({
          id: 'FORMATTING.BOLD',
        })}
        style={{
          border:
            fontWeight === 'bold' ? '1px solid #95c952' : '0.6px solid #d9d9d9',
        }}
      >
        <Icon.B />
      </div>

      <div
        onClick={() => {
          const newFontStyle = fontStyle === 'normal' ? 'italic' : 'normal'
          onFormattingChange('fontStyle', newFontStyle)
          setFontStyle(newFontStyle)
        }}
        className="gray-border-input format-icon"
        title={intl.formatMessage({
          id: 'FORMATTING.ITALICS',
        })}
        style={{
          border:
            fontStyle === 'italic'
              ? '1px solid #95c952'
              : '0.6px solid #d9d9d9',
        }}
      >
        <Icon.I />
      </div>

      <div
        className="gray-border-input format-icon"
        style={{
          position: 'relative',
        }}
        title={intl.formatMessage({
          id: 'FORMATTING.COLOR',
        })}
      >
        <input
          style={{
            position: 'absolute',
            opacity: '1%',
            width: '100%',
            height: '100%',
          }}
          type="color"
          onChange={e => {
            onFormattingChange('color', e.target.value)
          }}
        />
        <div className="format-text-color">
          <Icon.A />

          <div
            className="color"
            style={{
              background: formatting.color,
            }}
          />
        </div>
      </div>

      <Dropdown
        className="format-text-alignment"
        title={intl.formatMessage({
          id: 'FORMATTING.HORIZONTAL',
        })}
      >
        <Dropdown.Toggle as={DropdownUtils.Toggle}>
          {getTextAlignComponent(textAlign)}
        </Dropdown.Toggle>

        <Dropdown.Menu as={DropdownUtils.Menu}>
          <Dropdown.Item
            onClick={() => {
              setTextAlign('left')
            }}
            as={DropdownUtils.Item}
          >
            <Icon.AlignLeft />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setTextAlign('center')
            }}
            as={DropdownUtils.Item}
          >
            <Icon.AlignCenter />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setTextAlign('right')
            }}
            as={DropdownUtils.Item}
          >
            <Icon.AlignRight />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <div
        className={`gray-border-input format-icon ${
          centered ? 'custom-disabled' : ''
        }`}
        title={intl.formatMessage({
          id: 'FORMATTING.MOVE.UPPER',
        })}
        style={{
          padding: '2px',
        }}
        onClick={() => {
          if (formatting.bottom >= 100) return

          let adjustment = formatting.bottom === 45 ? 20 : 5

          onFormattingChange('bottom', formatting.bottom + adjustment)
        }}
      >
        <Icon.ArrowUp />
      </div>

      <div
        className={`gray-border-input format-icon ${
          centered ? 'custom-disabled' : ''
        }`}
        title={intl.formatMessage({
          id: 'FORMATTING.MOVE.DOWN',
        })}
        style={{
          padding: '2px',
        }}
        onClick={() => {
          if (formatting.bottom <= 0) return
          let adjustment = formatting.bottom === 65 ? 20 : 5
          onFormattingChange('bottom', formatting.bottom - adjustment)
        }}
      >
        <Icon.ArrowDown />
      </div>

      <div
        className="gray-border-input format-icon"
        onClick={() => {
          setCentered(!centered)
        }}
        title={intl.formatMessage({
          id: 'FORMATTING.CENTER',
        })}
        style={{
          border: centered ? '1px solid #95c952' : '0.6px solid #d9d9d9',
        }}
      >
        <Icon.Centered
          style={{
            width: '20px',
            height: '20px',
          }}
        />
      </div>

      <Dropdown className="format-text-selection" title="Zaznaczenie tekstu">
        <Dropdown.Toggle as={DropdownUtils.Toggle}>
          <Icon.Marker />
        </Dropdown.Toggle>

        <Dropdown.Menu as={DropdownUtils.Menu}>
          <Dropdown.Item
            onClick={() => {
              setTextSelectionType('none')
            }}
            as={DropdownUtils.Item}
          >
            Brak
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setTextSelectionType('outline')
            }}
            as={DropdownUtils.Item}
          >
            Kontur
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setTextSelectionType('wrap')
            }}
            as={DropdownUtils.Item}
          >
            Wrap
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setTextSelectionType('box')
            }}
            as={DropdownUtils.Item}
          >
            Box
          </Dropdown.Item>
          <input
            type="color"
            style={{
              width: '80%',
            }}
            onChange={e => {
              onFormattingChange('textSelection', {
                ...formatting.textSelection,
                background: e.target.value,
              })
            }}
          />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
})

export default FormatOptions
