import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useAuth } from 'contexts/authContext'

import FormatOptions from './FormatOptions'
import { injectIntl, FormattedMessage } from 'react-intl'

const FormatActions = injectIntl(
  ({
    formatting,
    onFormattingChange,
    onVerticalAlignChange,
    setStatusModal,
    setDlModal,
    role,
  }) => {
    const [modal, setModal] = useState('')
    const auth = useAuth()
    const user = auth.state.user

    const ShortcutsBody = () => (
      <Modal.Body>
        <p>
          <code>Tab</code> - <FormattedMessage id="SHORTCUTS.TAB" />
        </p>
        <p>
          <code>Ctrl + Z</code> - <FormattedMessage id="SHORTCUTS.REVERT" />
        </p>
        <p>
          <code>Ctrl + Y</code> - <FormattedMessage id="SHORTCUTS.REPEAT" />
        </p>
        <p>
          <code>Ctrl + F</code> -{' '}
          <FormattedMessage id="SHORTCUTS.FIND.REPLACE" />
        </p>
        <p>
          <code>Shift + Enter</code> -{' '}
          <FormattedMessage id="SHORTCUTS.NEW.LINE" />
        </p>
        <p>
          <code>Enter</code> - <FormattedMessage id="SHORTCUTS.NEW.BLOCK" />
        </p>
      </Modal.Body>
    )

    return (
      <>
        <Modal size="md" show={!!modal} onHide={() => setModal('')}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="SHORTCUTS" />
            </Modal.Title>
          </Modal.Header>
          <ShortcutsBody />
        </Modal>
        <div
          className="editor-video-actions"
          style={{
            justifyContent: 'flex-end',
          }}
        >
          <FormatOptions
            formatting={formatting}
            onFormattingChange={onFormattingChange}
            onVerticalAlignChange={onVerticalAlignChange}
          />

          <div className="icons-container">
            {user.role !== 'corrector' ? (
              <>
                <div
                  onClick={() =>
                    setStatusModal(m => ({ ...m, type: 'finished' }))
                  }
                  className={`accept-all`}
                >
                  <i className="flaticon-interface-5 icon-2x" />
                  <FormattedMessage id="GENERAL.ACCEPT" />
                </div>
                {user.role !== 'individual-client' && (
                  <div
                    onClick={() =>
                      setStatusModal(m => ({ ...m, type: 'rejected' }))
                    }
                    className={`accept-all`}
                  >
                    <i className=" flaticon-cancel icon-2x" />
                    <FormattedMessage id="GENERAL.DENY" />
                  </div>
                )}
              </>
            ) : (
              <>
                <div
                  onClick={() => setStatusModal(m => ({ ...m, type: 'ready' }))}
                  className={`accept-all`}
                >
                  <i className="flaticon-interface-5 icon-2x" />
                  <FormattedMessage id="GENERAL.READY" />
                </div>
              </>
            )}
            <div onClick={() => setModal('shortcuts')}>
              <i className="flaticon-exclamation-2 icon-2x" />
              <FormattedMessage id="SHORTCUTS" />
            </div>
            {role !== 'corrector' && (
              <div
                onClick={() => {
                  setDlModal(true)
                }}
              >
                <i className="flaticon-download icon-2x" />
                Pobierz
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
)

export default FormatActions
