import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { injectIntl } from 'react-intl'
import PageLoader from 'app/components/PageLoader'
import {
  Card,
  CardBody,
  CardHeader,
} from '../../../../_metronic/_partials/controls'
import SettingsForm from './SettingsForm'
import { getSettings } from '../../../../utils/queries'

const Settings = ({ intl }) => {
  const [page, setPage] = useState('dictionary')

  const { isLoading, isError, data } = useQuery('settings', getSettings)

  if (isLoading) {
    return <PageLoader />
  }

  if (isError) {
    return <span>Error</span>
  }

  return (
    <Card>
      <CardHeader
        icon={
          <span className="card-icon">
            <i className="flaticon2-gear text-primary"></i>
          </span>
        }
        className=""
        title={intl.formatMessage({
          id: 'SETTINGS.TITLE',
        })}
      />
      <CardBody style={{ paddingLeft: 0, paddingRight: 0 }}>
        <SettingsForm initialValues={data} />
        {/* <Tabs activeKey={page} onSelect={k => setPage(k)}>
          <Tab
            eventKey="dictionary"
            title={intl.formatMessage({
              id: 'SETTINGS.TABS.DICTIONARY_DATA',
            })}
          >
            <SettingsForm initialValues={data} />
          </Tab>
          <Tab
            eventKey="system"
            title={intl.formatMessage({
              id: 'SETTINGS.TABS.SYSTEM',
            })}
          >
            <div className="p-10">
              <FormattedMessage id="SETTINGS.TABS.SYSTEM" />
            </div>
          </Tab>
        </Tabs> */}
      </CardBody>
    </Card>
  )
}

export default injectIntl(Settings)
