import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ProgressBar } from 'react-bootstrap'
import { useQuery } from 'react-query'

import { formatDate } from 'utils/helpers'
import { getUser } from 'utils/queries'

const Summary = ({ formik, progress, showProgress, video, role }) => {
  const { data: client, error, loading } = useQuery(
    'sumClient',
    () => getUser(formik.values.client),
    {
      enabled: role.includes('client'),
    }
  )

  return (
    <>
      <h4 className="mb-10 font-weight-bold text-dark">
        <FormattedMessage id="PROJECT.ADD.SUMMARY" />
      </h4>
      <div className="row pb-5">
        <div
          className={`col-md-${
            video || formik.values.videoUrl ? 6 : 12
          } col-sm-12 pb-5`}
        >
          <h6 className="font-weight-bolder mb-3">Informacje o projekcie</h6>
          <div className="text-dark-50 line-height-lg custom-summary">
            <div>
              <span className="text-muted">
                <FormattedMessage id="PROJECT.ADD.NAME" />
              </span>{' '}
              {formik.values.name}
            </div>

            <div>
              <span className="text-muted">
                <FormattedMessage id="PROJECT.ADD.DESC" />
              </span>{' '}
              {formik.values.description}
            </div>

            <div>
              <span className="text-muted">
                <FormattedMessage id="PROJECT.ADD.DEADLINE" />
              </span>{' '}
              {formatDate(formik.values.deadline)}
            </div>

            {!role.includes('client') && (
              <div>
                <span className="text-muted">
                  <FormattedMessage id="PROJECT.ADD.CLIENT" />
                </span>{' '}
                {client ? client.firstName + ' ' + client.lastName : ''}
              </div>
            )}
          </div>
        </div>

        {formik.values.videoUrl && (
          <div className="col-md-6 col-sm-12 pb-5">
            <h6 className="font-weight-bolder mb-3">
              <FormattedMessage id="PROJECT.ADD.FILE_INFO" />
            </h6>
            <div className="text-dark-50 line-height-lg custom-summary">
              <div>
                <span className="text-muted">
                  <FormattedMessage id="PROJECT.ADD.FILE_URL" />
                </span>{' '}
                {formik.values.videoUrl}
              </div>
            </div>
          </div>
        )}

        {video && (
          <div className="col-md-6 col-sm-12 pb-5">
            <h6 className="font-weight-bolder mb-3">
              <FormattedMessage id="PROJECT.ADD.FILE_INFO" />
            </h6>
            <div className="text-dark-50 line-height-lg custom-summary">
              <div>
                <span className="text-muted">
                  <FormattedMessage id="PROJECT.ADD.FILE_NAME" />
                </span>{' '}
                {video.name}
              </div>

              <div>
                <span className="text-muted">
                  <FormattedMessage id="PROJECT.ADD.FILE_SIZE" />
                </span>{' '}
                {(video.size / 1000000).toFixed(2)} MB
              </div>
            </div>
          </div>
        )}

        {showProgress && progress !== 100 ? (
          <div
            style={{
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              textAlign: 'center',
              margin: '1em 0',
              padding: '1em 0',
            }}
          >
            <label>Trwa przesyłanie wideo... {progress}%</label>
            <ProgressBar animated now={progress} />
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export default Summary
