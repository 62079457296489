import React, { useState, useEffect, useContext, createContext } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { get } from '../../../utils/api'
import { injectIntl } from 'react-intl'
import { sizePerPageList, customTotal, pageButtonRenderer } from './settings'
import { useQuery } from 'react-query'
import CustomOverlay from './CustomOverlay'
import SearchBar from './filters/SearchBar'
import { Fade } from 'react-bootstrap'

const RemotePagination = React.memo(
  ({
    data,
    page,
    sizePerPage,
    sizePerPageList,
    onTableChange,
    totalSize,
    keyField,
    columns,
    alwaysShowAllBtns,
    paginationTotalRenderer,
    filterRendered,
    loading,
    search,
    handleTableChange,
    displaySearch,
    rowStyle,
    wasRendered,
  }) => {
    return (
      <div>
        <PaginationProvider
          pagination={paginationFactory({
            custom: true,
            page,
            paginationSize: 5,
            pageStartindex: 1,
            sizePerPage,
            totalSize,
            sizePerPageList,
            alwaysShowAllBtns,
            paginationTotalRenderer,
            filterRendered,
            pageButtonRenderer,
            loading,
            handleTableChange,
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div>
                <Fade in={wasRendered}>
                  <div>
                    <div className="mb-5" style={{ display: 'flex' }}>
                      {displaySearch ? (
                        <SearchBar
                          onChange={e => {
                            handleTableChange('search', {
                              searchInput: e.target.value,
                            })
                          }}
                          input={search}
                        />
                      ) : (
                        ''
                      )}
                      {filterRendered}
                    </div>

                    <BootstrapTable
                      classes={`table table-head-custom table-vertical-center overflow-hidden datatable-table datatable ${
                        loading ? 'low-opacity' : ''
                      }`}
                      wrapperClasses="table-responsive"
                      bordered={false}
                      bootstrap4
                      remote
                      keyField={keyField}
                      data={data}
                      columns={columns}
                      onTableChange={onTableChange}
                      filter={filterFactory()}
                      rowStyle={rowStyle}
                      noDataIndication={() => {
                        if (!loading)
                          return (
                            <div
                              style={{
                                margin: '0 auto',
                                opacity: '70%',
                                padding: '1em ',
                                textAlign: 'center',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  padding: '1em 2em',
                                }}
                              >
                                {' '}
                                Brak wyników
                              </div>
                            </div>
                          )
                      }}
                      {...paginationTableProps}
                    />
                    {data.length > 0 && (
                      <div className="custom-table-footer mt-2">
                        <div className="pagination-standalone-list">
                          <PaginationListStandalone {...paginationProps} />
                        </div>

                        <div className="custom-tab-right-pagination">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                          <PaginationTotalStandalone {...paginationProps} />
                        </div>
                      </div>
                    )}
                  </div>
                </Fade>
              </div>

              <CustomOverlay loading={loading} />
            </>
          )}
        </PaginationProvider>
      </div>
    )
  }
)

const CustomizedTable = ({
  dependencyCallback,
  dependencyParams,
  dependencyFields = [],
  defaultSortField,
  keyField,
  endpoint,
  columns,
  filterRendered,
  intl,
  queryIdentificator,
  actions,
  rowStyle,
  displaySearch = false,
  mockData = false,
}) => {
  // Pagination
  const [total, setTotal] = useState(0)
  const [curPage, setCurPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState('')

  // Sorting
  const [curSortField, setCurSortField] = useState(defaultSortField)
  const [curSortOrder, setCurSortOrder] = useState('asc')

  const [wasRendered, setWasRendered] = useState(false)

  const {
    isLoading,
    isError,
    error,
    entities,
    isFetching,
    isPreviousData,
  } = useQuery(
    [
      queryIdentificator,
      curPage,
      curSortField,
      curSortOrder,
      limit,
      search,
      ...dependencyFields,
    ],
    () => {
      updateTableData()
    },
    {
      keepPreviousData: true,
    }
  )

  const updateTableData = async () => {
    const queryConfig = {
      page: curPage,
      limit: limit,
      sort: curSortOrder === 'asc' ? curSortField : `-${curSortField}`,
      search: search,
      ...dependencyParams,
    }
    setLoading(true)

    const totalParams = {
      ...dependencyParams,
    }

    if (search) totalParams['search'] = search

    const total = await get(`${endpoint}/count`, totalParams)
    setTotal(total.data.results)

    return await get(endpoint, queryConfig).then(entities => {
      setLoading(false)
      setWasRendered(true)
      setData(entities.data.data)
    })
  }

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, data, filters, searchInput }
  ) => {
    if (type === 'pagination') {
      setLimit(sizePerPage)
      setCurPage(page)
    } else if (type === 'sort') {
      setCurSortField(sortField)
      setCurSortOrder(sortOrder)
    } else if (type === 'filter') {
      if (Array.isArray(dependencyCallback)) {
        for (const cb of dependencyCallback) {
          cb(filters)
        }
      } else {
        dependencyCallback(filters)
      }
    } else if (type === 'search') {
      setSearch(searchInput)
      if (curPage === 0) setCurPage(1)
    }
  }

  const validatePage = (mTotal, mPage, mLimit) => {
    if ((mPage - 1) * mLimit > mTotal) {
      const lastValidPage = Math.ceil(mTotal / mLimit)
      setCurPage(lastValidPage)
    }
  }

  useEffect(() => {
    validatePage(total, curPage, limit)
  }, [curPage, limit, total])

  return (
    <>
      <RemotePagination
        data={mockData ? mockData : data}
        page={curPage}
        sizePerPage={limit}
        sizePerPageList={sizePerPageList}
        totalSize={total}
        onTableChange={handleTableChange}
        keyField={keyField}
        columns={columns}
        paginationTotalRenderer={customTotal}
        alwaysShowAllBtns
        filterRendered={filterRendered}
        loading={loading}
        search={search}
        handleTableChange={handleTableChange}
        displaySearch={displaySearch}
        rowStyle={rowStyle}
        wasRendered={wasRendered}
      />
    </>
  )
}

export default injectIntl(CustomizedTable)
