import React from 'react'
import { differenceInCalendarDays } from 'date-fns'

import { headerSortingClasses, sortCaret } from '_metronic/_helpers'

import { MY_PROJECTS_ENDPOINT, PROJECTS_ENDPOINT } from 'constants.js'
import { DateFormatter } from 'app/modules/Table/formatters/common/DateFormatter'
import { ProfileFormatter } from 'app/modules/Table/formatters/users/ProfileFormatter'
import { ProjectFormatter } from 'app/modules/Table/formatters/projects/ProjectsFormatter'
import { StatusFormatter } from 'app/modules/Table/formatters/projects/StatusFormatter'
import { ProgressFormatter } from '../formatters/projects/ProgressFormatter'
import { ActionsFormatter } from '../formatters/projects/ActionsFormatter'
import { CompanyFormatter } from '../formatters/users'
import { HMSFormatter } from '../formatters/common/HMSFormatter'

export const rowStyle = (row, rowIndex) => {
  const style = {
    minHeight: '67px',
  }

  const remainingDays = differenceInCalendarDays(
    new Date(row.deadline),
    new Date()
  )

  if (!row.finished && remainingDays <= 1) {
    style.backgroundColor = '#FFE2E5'
  }

  return style
}

export const options = type => ({
  defaultSortField: type === 'all' ? '-createdAt' : 'deadline',
  keyField: '_id',
  endpoint: type === 'all' ? PROJECTS_ENDPOINT : MY_PROJECTS_ENDPOINT,
  rowStyle: rowStyle,
})

export const columns = (type = 'all', role) => {
  const cols = [
    {
      dataField: 'name',
      text: 'Projekt',
      sort: true,
      sortCaret,
      headerSortingClasses,
      formatter: (value, row) => (
        <ProjectFormatter
          project={value}
          photo={row.photo}
          id={row._id}
          role={role}
        />
      ),
      // headerStyle: (colum, colIndex) => {
      //   return {
      //     width: '20%',
      //   }
      // },
    },
    {
      dataField: 'client.company',
      text: 'Klient',
      sort: false,
      formatter: (value, row) => <CompanyFormatter company={value} />,
    },
    {
      dataField: 'corrector',
      text: 'Korektor',
      sort: false,
      formatter: (value, row) => <ProfileFormatter user={value} />,
    },
    {
      dataField: 'statuses',
      text: 'Status',
      sort: false,
      formatter: (value, row) => (
        <StatusFormatter data={value[value.length - 1]} role={role} />
      ),
      headerStyle: (colum, colIndex) => {
        return {
          width: '120px',
        }
      },
    },
    // {
    //   dataField: 'createdAt',
    //   text: 'Dodano',
    //   sort: true,
    //   sortCaret,
    //   headerSortingClasses,
    //   formatter: (value, row) => <DateFormatter date={value} />,
    //   headerStyle: (colum, colIndex) => {
    //     return {
    //       width: '120px',
    //     }
    //   },
    // },
    {
      dataField: 'deadline',
      text: 'Deadline',
      sort: true,
      sortCaret,
      headerSortingClasses,
      formatter: (value, row) => <DateFormatter date={value} />,
      headerStyle: (colum, colIndex) => {
        return {
          width: '120px',
        }
      },
    },
    {
      dataField: 'materialDuration',
      text: 'Długość materiału',
      formatter: (value, row) => <HMSFormatter seconds={value} />,
      headerStyle: (colum, colIndex) => {
        return {
          width: '10%',
          textAlign: 'right',
        }
      },
    },
    {
      dataField: 'progress',
      text: 'Progres',
      sort: false,
      formatter: (value, row) => <ProgressFormatter value={value} />,
    },
    {
      text: 'Akcje',
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: 'right',
          paddingRight: 'calc(0.75rem + 6.5px)',
        }
      },
      formatter: (value, row) => (
        <ActionsFormatter
          id={row._id}
          transcription={row.transcription?.length > 0}
        />
      ),
      headerStyle: (colum, colIndex) => {
        return {
          width: '120px',
          textAlign: 'right',
        }
      },
    },
  ]

  if (!role.includes('admin')) {
    const clientIndex = cols.findIndex(el => el.dataField === 'client.company')
    cols.splice(clientIndex, 1)

    const correctorIndex = cols.findIndex(el => el.dataField === 'corrector')
    cols.splice(correctorIndex, 1)

    // const progressIndex = cols.findIndex(el => el.dataField === 'progress')
    // cols.splice(progressIndex, 0, materialDurationColumn)
  }

  if (role.includes('client')) {
    const progressIndex = cols.findIndex(el => el.dataField === 'progress')
    cols.splice(progressIndex, 1)
  }

  return cols
}
