import React from 'react'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'

const CustomizedTextarea = props => {
  return (
    <TextareaAutosize
      className="form-control form-control-solid form-control-lg"
      rowsMin={4}
      {...props}
    ></TextareaAutosize>
  )
}

export default CustomizedTextarea
