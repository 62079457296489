import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import {
  ACTIVATE_ENDPOINT,
  VALIDATE_ACTIVATE_TOKEN_ENDPOINT,
} from '../../../../constants'
import { get, patch } from '../../../../utils/api'
import Alert from './Alerts/Alert'
import {
  confirmPasswordValidator,
  minMaxValidator,
  onlyLettersValidator,
  minMaxReqValidator,
  passwordValidator,
  phoneValidator,
} from 'utils/validators'

const Registration = ({ intl, location, history }) => {
  const [regToken, setRegToken] = useState('')
  const [loading, setLoading] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertType, setAlertType] = useState('')
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [role, setRole] = useState('')

  React.useEffect(() => {
    ;(async () => {
      const res = await get(
        `${VALIDATE_ACTIVATE_TOKEN_ENDPOINT}/${
          queryString.parse(location.search).token
        }`
      ).catch(err => {
        history.push('/')
      })
      setRole(res?.data?.role)
    })()
  }, [])

  useEffect(() => {
    const queryParams = queryString.parse(location.search)
    const authToken = queryParams.token
    setRegToken(authToken)
  }, [regToken])

  const RegistrationSchema = Yup.object().shape({
    firstName: onlyLettersValidator(intl, 2, 100),
    lastName: minMaxReqValidator(intl, 2, 100),
    company: minMaxValidator(intl, 2, 100),
    phone: phoneValidator(intl),
    password: passwordValidator(intl),
    confirmPassword: confirmPasswordValidator(intl),
  })

  const handleRegister = async (values, { setStatus, setSubmitting }) => {
    setLoading(true)

    try {
      const response = await patch(`${ACTIVATE_ENDPOINT}/${regToken}`, values)
      setAlertMessage('Rejestracja przebiegła pomyślnie')
      setAlertType('success')
      setFormSubmitted(true)
    } catch (err) {
      setAlertMessage(intl.FormattedMessage({ id: err.id }))
      setAlertType('error')
    }

    setLoading(false)
  }

  const formik = useFormik({
    initialValues: {
      token: regToken,
      firstName: '',
      lastName: '',
      company: '',
      phone: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: RegistrationSchema,
    enableReinitialize: true,
    onSubmit: handleRegister,
  })

  const isFirstNameInvalid = () =>
    formik.touched.firstName && formik.errors.firstName
  const isLastNameInvalid = () =>
    formik.touched.lastName && formik.errors.lastName
  const isPhoneInvalid = () => formik.touched.phone && formik.errors.phone
  const isPassInvalid = () => formik.touched.password && formik.errors.password
  const isConfirmPassInvalid = () =>
    formik.touched.confirmPassword && formik.errors.confirmPassword
  const isCompanyInvalid = () => formik.touched.company && formik.errors.company

  return (
    <div className="login-content flex-row-fluid d-flex flex-column p-10">
      <div className="d-flex flex-row-fluid flex-center">
        <div className="login-form">
          <form
            className="form fv-plugins-bootstrap fv-plugins-framework"
            onKeyDown={e => {
              if ((e.charCode || e.keyCode) === 13) {
                e.preventDefault()
              }
            }}
            onSubmit={formik.handleSubmit}
          >
            <div className="pb-5 pb-lg-15">
              <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
                Rejestracja
              </h3>
            </div>
            <Alert message={alertMessage} type={alertType} />

            <Link to="/auth/login">
              <button
                style={{
                  display: formSubmitted ? 'block' : 'none',
                }}
                className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
              >
                Przejdź do strony logowania
              </button>
            </Link>

            <div
              className="formreg"
              style={{ display: formSubmitted ? 'none' : 'initial' }}
            >
              <div className="form-group">
                <p>Uzupełnij formularz, aby aktywować konto</p>
              </div>
              {/* Start: First name input */}
              <div className="form-group fv-plugins-icon-container">
                <div className="d-flex justify-content-between mt-n5">
                  <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                    Imię
                  </label>
                </div>
                <input
                  className={`form-control h-auto py-7 px-6 rounded-lg border-0 ${
                    isFirstNameInvalid() ? 'is-invalid invalid-input' : ''
                  }`}
                  id="firstName"
                  type="text"
                  {...formik.getFieldProps('firstName')}
                />
                {/* Walidacja: First name */}
                {isFirstNameInvalid() ? (
                  <div className="invalid-input-label">
                    {formik.errors.firstName}
                  </div>
                ) : (
                  ''
                )}
              </div>
              {/* End: First name input */}
              {/* Start: Last name input */}
              <div className="form-group fv-plugins-icon-container">
                <div className="d-flex justify-content-between mt-n5">
                  <label
                    className={`font-size-h6 font-weight-bolder text-dark pt-5`}
                  >
                    Nazwisko
                  </label>
                </div>
                <input
                  className={`form-control h-auto py-7 px-6 rounded-lg border-0 ${
                    isLastNameInvalid() ? 'is-invalid invalid-input' : ''
                  }`}
                  id="lastName"
                  type="text"
                  {...formik.getFieldProps('lastName')}
                />

                {/* Walidacja: Last name */}
                {isLastNameInvalid() ? (
                  <div className="invalid-input-label">
                    {formik.errors.lastName}
                  </div>
                ) : (
                  ''
                )}
              </div>
              {/* End: Last name input */}
              {/* Start: Last name input */}
              {role === 'client' && (
                <>
                  <div className="form-group fv-plugins-icon-container mb-0">
                    <div className="d-flex justify-content-between mt-n5">
                      <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                        Organizacja
                      </label>
                    </div>
                    <input
                      className={`form-control h-auto py-7 px-6 rounded-lg border-0 ${
                        isCompanyInvalid() ? 'is-invalid invalid-input' : ''
                      }`}
                      id="company"
                      type="text"
                      {...formik.getFieldProps('company')}
                    />
                  </div>
                  {formik.errors.company && formik.touched.company ? (
                    <div className="invalid-input-label">
                      {formik.errors.company}
                    </div>
                  ) : (
                    ''
                  )}
                </>
              )}

              {/* End: Last name input */}
              {/* Start: Telephone input */}
              <div className="form-group fv-plugins-icon-container mt-6">
                <div className="d-flex justify-content-between mt-n5">
                  <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                    Nr telefonu
                  </label>
                </div>
                <input
                  className={`form-control h-auto py-7 px-6 rounded-lg border-0 ${
                    isPhoneInvalid() ? 'is-invalid invalid-input' : ''
                  }`}
                  id="phone"
                  type="tel"
                  {...formik.getFieldProps('phone')}
                />

                {/* Walidacja: Phone number */}
                {isPhoneInvalid() ? (
                  <div className="invalid-input-label">
                    {formik.errors.phone}
                  </div>
                ) : (
                  ''
                )}
              </div>
              {/* End: Telephone input */}
              {/* Start: Pass input */}
              <div className="form-group fv-plugins-icon-container">
                <div className="d-flex justify-content-between mt-n5">
                  <label
                    className={`font-size-h6 font-weight-bolder text-dark pt-5`}
                  >
                    Hasło
                  </label>
                </div>
                <input
                  className={`form-control h-auto py-7 px-6 rounded-lg border-0 ${
                    isPassInvalid() ? 'is-invalid invalid-input' : ''
                  }`}
                  id="password"
                  type="password"
                  {...formik.getFieldProps('password')}
                />

                {/* Walidacja: Password */}
                {isPassInvalid() ? (
                  <div className="invalid-input-label">
                    {formik.errors.password}
                  </div>
                ) : (
                  ''
                )}
              </div>
              {/* End: Pass input */}
              {/* Start: Confirm Password input */}
              <div className="form-group fv-plugins-icon-container">
                <div className="d-flex justify-content-between mt-n5">
                  <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                    Powtórz hasło
                  </label>
                </div>
                <input
                  className={`form-control h-auto py-7 px-6 rounded-lg border-0 ${
                    isConfirmPassInvalid() ? 'is-invalid invalid-input' : ''
                  }`}
                  id="confirmPassword"
                  type="password"
                  {...formik.getFieldProps('confirmPassword')}
                />

                {/* Walidacja: Confirm Password */}
                {isConfirmPassInvalid() ? (
                  <div className="invalid-input-label">
                    {formik.errors.confirmPassword}
                  </div>
                ) : (
                  ''
                )}
              </div>
              {/* End: Confirm Password input */}
              <div className="pb-lg-0 pb-5">
                <button
                  className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                  type="submit"
                  disabled={
                    formik.isSubmitting || !(formik.isValid && formik.dirty)
                  }
                >
                  <span>Aktywuj konto</span>
                  {loading && (
                    <span className="ml-3 mr-4 spinner spinner-white"></span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Registration)
