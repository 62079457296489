import { patch, post, del, get } from './api'
import {
  PROJECTS_ENDPOINT,
  TASKS_ENDPOINT,
  USERS_ENDPOINT,
  STATUSES_ENDPOINT,
  SETTINGS_ENDPOINT,
  UPDATE_ME_ENDPOINT,
} from 'constants.js'

//USERS

export const updateMe = values => patch(UPDATE_ME_ENDPOINT, values)

export const updateUser = (id, values) => {
  return patch(`${USERS_ENDPOINT}/${id}`, values)
}

export const blockUser = id =>
  patch(`${USERS_ENDPOINT}/${id}/block`).then(res => res.data.data)

export const unblockUser = id =>
  patch(`${USERS_ENDPOINT}/${id}/unblock`).then(res => res.data.data)

export const archiveUser = id =>
  patch(`${USERS_ENDPOINT}/${id}/delete`).then(res => res.data)

//PROJECTS

export const updateProject = (id, values) =>
  patch(`${PROJECTS_ENDPOINT}/${id}`, values)

export const updateProjectFormatting = (id, formatting) =>
  patch(`${PROJECTS_ENDPOINT}/${id}/update-formatting`, { formatting }).then(
    res => res.data
  )

export const updateProjectStatus = (id, status) =>
  patch(`${PROJECTS_ENDPOINT}/${id}/update-status`, { status }).then(
    res => res.data
  )

export const updateProjectTranscription = (id, transcription) =>
  patch(`${PROJECTS_ENDPOINT}/${id}/update-transcription`, {
    transcription,
  }).then(res => res.data)

export const updateProjectBookmark = (id, bookmark) =>
  patch(`${PROJECTS_ENDPOINT}/${id}/update-bookmark`, {
    bookmark,
  }).then(res => res.data)

export const updateProjectDeadline = (id, deadline) =>
  patch(`${PROJECTS_ENDPOINT}/${id}/update-deadline`, {
    deadline,
  }).then(res => res.data)

export const transcribeVideo = (id, lang) =>
  get(`${PROJECTS_ENDPOINT}/${id}/transcribe`, {
    lang,
  })

export const archiveProject = id =>
  patch(`${PROJECTS_ENDPOINT}/${id}/delete`).then(res => res.data)

export const uploadYoutube = (id, url) =>
  post(`${PROJECTS_ENDPOINT}/${id}/upload-youtube`, { url }).then(
    res => res.data
  )

export const addProjectTask = (projectId, values) =>
  post(`${PROJECTS_ENDPOINT}/${projectId}/task`, values).then(
    res => res.data.data
  )

export const deleteProjectTask = projectId =>
  del(`${PROJECTS_ENDPOINT}/${projectId}/task`)

//TASKS

export const addTask = (id, values) =>
  post(`${PROJECTS_ENDPOINT}/${id}${TASKS_ENDPOINT}`, values).then(
    res => res.data.data
  )

export const updateTask = (id, values, projectId) => {
  patch(`${PROJECTS_ENDPOINT}/${projectId}${TASKS_ENDPOINT}/${id}`, values)
}

export const updateTaskStatus = (projectId, taskId, statusId) => {
  patch(
    `${PROJECTS_ENDPOINT}/${projectId}${TASKS_ENDPOINT}/${taskId}/status/${statusId}`
  )
}

export const deleteTask = (projectId, taskId) => {
  return del(`${PROJECTS_ENDPOINT}/${projectId}${TASKS_ENDPOINT}/${taskId}`)
}

//SETTINGS

export const updateSettings = values => patch(SETTINGS_ENDPOINT, values)

//STATUSES

export const addStatus = values =>
  post(STATUSES_ENDPOINT, values).then(res => res.data.data)

export const updateStatus = values =>
  patch(`${STATUSES_ENDPOINT}/${values.id}`, values).then(res => res.data.data)

export const deleteStatus = id => del(`${STATUSES_ENDPOINT}/${id}`)
