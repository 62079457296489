import React from 'react'
import { Modal } from 'react-bootstrap'

const DeleteTaskModal = ({ show, onClose, onConfirm }) => {
  return (
    <>
      <Modal size="md" show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Usuń zadanie</Modal.Title>
        </Modal.Header>
        <Modal.Body>Czy na pewno chcesz usunąć zadanie?</Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={`btn show-notify btn-danger`}
            onClick={onConfirm}
          >
            Usuń
          </button>
          <button
            type="button"
            className="btn btn-secondary font-weight-bold mr-2"
            onClick={onClose}
          >
            Anuluj
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DeleteTaskModal
