import React, { useState } from 'react'
import { injectIntl } from 'react-intl'

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '_metronic/_partials/controls'

import CustomizedTable from 'app/modules/Table/CustomizedTable'
import {
  columns,
  options,
  rowStyle,
} from 'app/modules/Table/configs/usersConfig'
import UsersFilter from 'app/modules/Table/filters/UsersFilter'
import useLocalStorage from 'utils/useLocalStorage'

const Users = ({ type = 'all' }) => {
  const [filterVal, setFilterVal] = useLocalStorage('users-filter-status')
  const [roleVal, setRoleVal] = useLocalStorage('users-filter-role')

  // -> Start:Table section
  const queryTableParams = {
    active: filterVal,
  }

  const dependencyFields = [filterVal]

  if (type === 'all') {
    queryTableParams.role = roleVal
    dependencyFields.push(roleVal)
  } else if (type === 'client') {
    queryTableParams.role = 'client'
  } else if (type === 'corrector') {
    queryTableParams.role = 'corrector'
  }

  // -> End:Table section

  return (
    <Card style={{ minHeight: '290px' }}>
      <CardHeader
        icon={
          <span className="card-icon">
            <i className="flaticon2-layers text-primary"></i>
          </span>
        }
        className=""
        title="Lista użytkowników"
      >
        <CardHeaderToolbar></CardHeaderToolbar>
      </CardHeader>

      <CardBody>
        <CustomizedTable
          queryName={type + '-users-table'}
          dependencyParams={queryTableParams}
          dependencyFields={dependencyFields}
          columns={columns(type)}
          filterRendered={
            <UsersFilter
              role={type === 'all'}
              useStatus={() => [filterVal, setFilterVal]}
              useRole={() => [roleVal, setRoleVal]}
            />
          }
          queryIdentificator="users-table"
          displaySearch
          {...options}
        />
      </CardBody>
    </Card>
  )
}

export default Users
