import React from 'react'

export const CompanyFormatter = ({ company }) => {
  return (
    <>
      <span>
        <div className="font-weight-bold text-muted">
          {company ? company : '-'}
        </div>
      </span>
    </>
  )
}
