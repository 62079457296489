import React from 'react'

export const sizePerPageList = [
  {
    text: '5',
    value: 5,
  },
  {
    text: '10',
    value: 10,
  },
  {
    text: '20',
    value: 20,
  },
  {
    text: '30',
    value: 30,
  },
  {
    text: '50',
    value: 50,
  },
  { text: '100', value: 100 },
]

export const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
    Wyświetlono {from} - {to} z {size}
  </span>
)

export const pageButtonRenderer = ({
  page,
  active,
  disabled,
  title,
  onPageChange,
}) => {
  const handleClick = e => {
    e.preventDefault()
    onPageChange(page)
  }

  const getBtnContent = title => {
    switch (title) {
      case 'first page':
        return (
          <i
            style={{ marginBottom: '1.5px' }}
            className="ki ki-bold-double-arrow-back icon-xs"
          ></i>
        )
      case 'previous page':
        return (
          <i
            style={{ marginBottom: '1.5px' }}
            className="ki ki-bold-arrow-back icon-xs"
          ></i>
        )
      case 'next page':
        return (
          <i
            style={{ marginBottom: '1.5px' }}
            className="ki ki-bold-arrow-next icon-xs"
          ></i>
        )
      case 'last page':
        return (
          <i
            style={{ marginBottom: '1.5px' }}
            className="ki ki-bold-double-arrow-next icon-xs"
          ></i>
        )
      default:
        return page
    }
  }

  return (
    <li
      onClick={handleClick}
      key={title}
      className={`btn btn-icon btn-sm ${
        ['first page', 'previous page', 'next page', 'last page'].includes(
          title
        )
          ? 'light-btn-custom'
          : ''
      }  btn-hover-primary mr-2 my-1 ${active ? 'active' : ''}`}
    >
      <a href="#">{getBtnContent(title)}</a>
    </li>
  )
}
