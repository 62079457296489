import tinycolor from 'tinycolor2'

import { api } from 'constants.js'

export const formatDate = date => {
  if (date)
    return date
      .slice(0, 10)
      .replaceAll('-', '/')
      .split('/')
      .reverse()
      .join('/')

  return false
}

export const getTextColor = bgColor =>
  tinycolor(bgColor).isLight() ? '#3F4254' : '#FFFFFF'
// ? tinycolor(bgColor)
//     .darken(20)
//     .toString()
// : tinycolor(bgColor)
//     .lighten(20)
//     .toString()

export const mapPopulatedStatuses = (statuses, intl) =>
  statuses.map(status => ({
    id: status?.status?._id,
    createdAt: status?.createdAt,
    name: status?.status?.slug
      ? intl.formatMessage({ id: `STATUS.${status?.status?.slug}` })
      : status?.status?.name,
    bgColor: status?.status?.bgColor,
    txtColor: status?.status?.txtColor,
    default: status?.status?.default,
  }))

export const getThumb = url => {
  const results = url.match('[\\?&]v=([^&#]*)')
  const video = results === null ? url : results[1]

  return `http${
    api.includes('https') ? 's' : ''
  }://img.youtube.com/vi/${video}/0.jpg`
}

export const downloadBlob = (blob, name) => {
  const blobUrl = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = blobUrl
  a.download = name
  a.click()
}

export const swapStatusToClient = status => {
  if (['ready', 'rejected'].includes(status)) {
    return 'in-progress'
  }

  return status
}
