import React from 'react'

import CustomizedTable from 'app/modules/Table/CustomizedTable'
import { columns, options } from 'app/modules/Table/configs/projectsConfig'

const MyProjects = ({ role }) => (
  <CustomizedTable
    columns={columns('dashboard', role)}
    queryIdentificator="dashboard-projects-table"
    dependencyParams={{ finished: false }}
    {...options('dashboard')}
  />
)

export default MyProjects
