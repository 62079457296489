import React from 'react'
import { toAbsoluteUrl } from '_metronic/_helpers'
import { useAuth } from 'contexts/authContext'
import { useQuery } from 'react-query'
import { NavLink } from 'react-router-dom'
import { IMAGES_ENDPOINT } from 'constants.js'
import MyProjects from './MyProjects'
import { getProfile, getProjectStats } from 'utils/queries'
import PageLoader from 'app/components/PageLoader'

const ClientDashboard = () => {
  const {
    state: { user },
  } = useAuth()

  const { isLoading, data } = useQuery('projectsStats', getProjectStats)

  if (isLoading) return <PageLoader />

  return (
    <>
      <div className="card card-custom gutter-b">
        <div className="card-body">
          <div className="d-flex mb-9">
            <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
              <div className="symbol symbol-50 symbol-lg-120">
                <img
                  src={
                    user.photo
                      ? `${IMAGES_ENDPOINT}${user.photo}?${Date.now()}`
                      : toAbsoluteUrl('/media/client-logos/default.jpeg')
                  }
                  alt=""
                />
              </div>
            </div>

            <div className="flex-grow-1">
              <div className="d-flex justify-content-between flex-wrap mt-1">
                <div className="d-flex mr-3">
                  <span className="text-dark font-size-h5 font-weight-bold mr-3">
                    {user.firstName + ' ' + user.lastName}
                  </span>
                  <span>
                    <i className="flaticon2-correct text-success font-size-h5"></i>
                  </span>
                </div>

                <NavLink to="/user-profile">
                  <div className="my-lg-0 my-3">
                    <a className="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3">
                      Edycja profilu
                    </a>
                  </div>
                </NavLink>
              </div>

              <div className="d-flex flex-wrap justify-content-between mt-1">
                <div className="d-flex flex-column flex-grow-1 pr-8">
                  <div className="d-flex flex-wrap mb-4">
                    <a className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                      <i className="flaticon2-new-email mr-2 font-size-lg"></i>
                      <a
                        href={`mailto:${user.email}`}
                        style={{
                          color: 'inherit',
                        }}
                      >
                        {user.email}
                      </a>
                    </a>

                    <span className="text-dark-50 font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                      <i className="flaticon2-calendar-3 mr-2 font-size-lg"></i>
                      Klient
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="separator separator-solid"></div>

          <div className="d-flex align-items-center flex-wrap mt-8">
            <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
              <span className="mr-4">
                <i className="flaticon-layers display-4 text-muted font-weight-bold"></i>
              </span>
              <div className="d-flex flex-column icon-color">
                <span className="font-weight-bolder font-size-sm">Nowe</span>
                <span className="font-weight-bolder font-size-h5">
                  {data?.data?.newProjects}
                </span>
              </div>
            </div>

            <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
              <span className="mr-4">
                <i className="flaticon-list display-4 text-muted font-weight-bold"></i>
              </span>
              <div className="d-flex flex-column icon-color">
                <span className="font-weight-bolder font-size-sm">
                  W trakcie
                </span>
                <span className="font-weight-bolder font-size-h5">
                  {data?.data?.currentProjects}
                </span>
              </div>
            </div>

            <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
              <span className="mr-4">
                <i className="flaticon-notes display-4 text-muted font-weight-bold"></i>
              </span>
              <div className="d-flex flex-column icon-color">
                <span className="font-weight-bolder font-size-sm">
                  Ukończone
                </span>
                <span className="font-weight-bolder font-size-h5">
                  {data?.data?.completedProjects}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div
            className="card card-custom card-stretch gutter-b"
            style={{
              padding: '2rem 2.25rem',
            }}
          >
            <h3 className="card-title font-weight-bolder">Moje projekty</h3>

            <MyProjects type="dashboard" role="client" />
          </div>
        </div>
      </div>
    </>
  )
}

export default ClientDashboard
