export const actionTypes = {
  BLOCK_ADD: 'BLOCK_ADD',
  BLOCK_DELETE: 'BLOCK_DELETE',
  BLOCK_JOIN: 'BLOCK_JOIN',
  BLOCK_SPLIT: 'BLOCK_SPLIT',
  BLOCK_TIME_CHANGE: 'BLOCK_TIME_CHANGE',
}

export class ActionsHistory {
  constructor() {
    this.history = []
    this.index = -1
  }

  pushAction(action) {
    if (this.index < this.history.length - 1) {
      this.history.splice(this.index + 1, this.history.length - 1, action)
    } else {
      this.history.push(action)
    }
    this.index = this.history.length - 1
  }

  incrementIndex() {
    this.index += 1
  }

  decrementIndex() {
    this.index -= 1
  }

  currentAction() {
    return this.history[this.index]
  }

  nextAction() {
    return this.history[this.index + 1]
  }

  preventUndo() {
    return this.history === [] || this.index === -1
  }

  preventRedo() {
    return this.history === [] || this.index === this.history.length - 1
  }
}

export const handleUndo = (actionsHistory, setTranscription) => {
  if (actionsHistory.preventUndo()) {
    return
  }

  const currentAction = actionsHistory.currentAction()

  switch (currentAction.type) {
    case actionTypes.BLOCK_DELETE:
      setTranscription(transcription => {
        const t = [...transcription]

        t.splice(currentAction.index, 0, currentAction.payload)

        return t
      })
      break
    case actionTypes.BLOCK_ADD:
      setTranscription(transcription => {
        const t = [...transcription]

        t.splice(currentAction.index, 1)

        return t
      })
      break
    case actionTypes.BLOCK_JOIN:
      setTranscription(transcription => {
        const t = [...transcription]
        const [_, blockBefore, blockAfter] = currentAction.payload

        t.splice(currentAction.index, 1, blockBefore, blockAfter)

        return t
      })
      break
    case actionTypes.BLOCK_TIME_CHANGE:
      setTranscription(transcription => {
        const t = [...transcription]
        const [oldBlock] = currentAction.payload

        t.splice(currentAction.index, 1, oldBlock)

        return t
      })
      break
    case actionTypes.BLOCK_SPLIT:
      setTranscription(transcription => {
        const t = [...transcription]
        const [oldBlock] = currentAction.payload

        t.splice(currentAction.index, 2, oldBlock)

        return t
      })
      break
  }

  actionsHistory.decrementIndex()
}

export const handleRedo = (actionsHistory, setTranscription) => {
  if (actionsHistory.preventRedo()) {
    return
  }

  const currentAction = actionsHistory.nextAction()

  switch (currentAction.type) {
    case actionTypes.BLOCK_DELETE:
      setTranscription(transcription => {
        const t = [...transcription]

        t.splice(currentAction.index, 1)

        return t
      })
      break
    case actionTypes.BLOCK_ADD:
      setTranscription(transcription => {
        const t = [...transcription]

        t.splice(currentAction.index, 0, currentAction.payload)

        return t
      })
      break
    case actionTypes.BLOCK_JOIN:
      setTranscription(transcription => {
        const t = [...transcription]
        const [newBlock] = currentAction.payload

        t.splice(currentAction.index, 2, newBlock)

        return t
      })
      break
    case actionTypes.BLOCK_TIME_CHANGE:
      setTranscription(transcription => {
        const t = [...transcription]
        const [_, newBlock] = currentAction.payload

        t.splice(currentAction.index, 1, newBlock)

        return t
      })
      break
    case actionTypes.BLOCK_SPLIT:
      setTranscription(transcription => {
        const t = [...transcription]
        const [_, firstBlock, secondBlock] = currentAction.payload

        t.splice(currentAction.index, 1, firstBlock, secondBlock)

        return t
      })
      break
  }

  actionsHistory.incrementIndex()
}
