import React from 'react'

export const StatusFormatter = ({ status }) => (
  <span
    className={`label label-lg font-weight-bold label-inline ${
      status ? 'label-light-success' : 'label-light-danger'
    } marek-style`}
  >
    {status ? 'Aktywny' : 'Nieaktywny'}
  </span>
)
